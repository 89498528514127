/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import Navbar from "../../../layouts/Navigation";
import {
  Container,
  Grid,
  Typography,
  ButtonBase,
  Select,
  MenuItem,
  FormControl,
  Avatar,
  Divider,
} from "@material-ui/core";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { fetchRecord } from "../../../services/Axios";
import { useNavigate } from "react-router-dom";
import { MEMBER } from "../../../constants/ApiEndPoint";
import { HOME_ROUTES } from "../../../constants/site-routes";
import AppColor from "../../../utils/color";
import SearchIcon from "@material-ui/icons/Search";
import FilterModal from "../../../components/FilterModal";
import FavouriteItem from "../../../components/FavouriteItem";
import { BiSortDown } from "react-icons/bi";
import VerifiedPlayer from "../../../components/VerifiedPlayer";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  block: {
    padding: "1rem",
    // margin: "10px 10px 10px 0px",
    backgroundColor: "white",
    border: `1px solid white`,
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
  },
  img: {
    width: "100px",
    height: "100px",
    borderRadius: 4,
  },
  blockTextContainer: {
    margin: "0rem 1rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50%",
    },
  },
  heading: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
  memberBlock: {
    marginBottom: 20,
    width: "100%",
    padding: "1rem",
    // margin: "10px 10px 10px 0px",
    backgroundColor: "white",
    border: `1px solid white`,
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
  },
  memberTitle: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  memberLocation: {
    fontSize: "14px",
  },
  agencyText: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  memberLabel: {
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "#00000090",
    color: "#ffffff",
    padding: 2,
    fontSize: 12,
    fontWeight: "300",
    borderTopRightRadius: 4,
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    border: "0px solid #ccc",
    borderRadius: 25,
    padding: 5,
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    "& input": {
      flex: 1,
      border: "none",
      outline: "none",
      padding: 5,
      backgroundColor: "#91919100",
      fontSize: 16,
      color: "#000000",
    },
    "& input::placeholder": {
      color: "#808080",
    },
    "& button": {
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      padding: 5,
    },
  },
  formControl: {
    height: 50,
    minWidth: "100%",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    borderRadius: 25,
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
    },
    "& fieldset": {
      display: "none",
    },
  },
  filterBlock: {
    display: "flex",
    justifyContent: "flex-end",
  },
  filterIcon: {
    backgroundColor: "#91919159",
    borderWidth: 0,
    cursor: "pointer",
  },
  playerListAndVerifiedIcon: {
    alignItems: "center",
  },
  playerDetailsBox: {
    position: "relative",
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "5px",
    padding: "10px 15px",
    borderRadius: "10px",
    boxShadow:
      "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;",
  },
  playerDetailsHeading: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  playerDetailsText: {
    color: "#a3a1a1",
  },
}));

function objectToQueryString(obj) {
  const queryParams = Object.keys(obj)
    .map((key) => {
      console.log(key, obj[key]);
      if (Array.isArray(obj[key])) {
        let query = "";
        for (let index = 0; index < obj[key].length; index++) {
          if (index === obj[key].length - 1) {
            query += `${key}[]=${obj[key][index]}`;
          } else {
            query += `${key}[]=${obj[key][index]}&`;
          }
        }
        if (query.length > 0) {
          return query;
        }
        return `${key}[]=`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join("&");
  return queryParams;
}

const FavouriteList = () => {
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Successfully obtained the user's current position
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          // Handle error (e.g., user denied location access)
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error("Geolocation is not supported by your browser");
    }
  }, []);

  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState(null);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [distance, setDistance] = useState("Any");
  const [page, setPage] = useState(1);
  const [extraFilters, setExtraFilters] = useState({});
  const [openSort, setOpenSort] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const fetchMembers = async (query) => {
    setLoading(true);
    const queryString = objectToQueryString(query);
    const response = await fetchRecord(MEMBER.FAVOURITE, "", "", queryString);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      if (members && query.page > 1) {
        setMembers([...members, ...response.data]);
      } else {
        setMembers(response.data);
      }
    }
  };

  const handelFilter = async (data) => {
    setShowSort(false);
    // setMembers(null);
    setExtraFilters(data);
    const filters = {
      ...data,
      ...{
        name,
        distance,
        per_page: 20,
        page: 1,
        latitude,
        longitude,
        sport: "",
      },
    };
    const query = {};
    const useFilter = data.useFilter ?? false;

    if (!useFilter && distance === "Any") {
      query.name = filters.name;
      query.page = filters.page;
      query.sport = filters.sport;
    } else if (!useFilter && distance !== "Any") {
      query.name = filters.name;
      query.page = filters.page;
      query.sport = filters.sport;
      query.distance = filters.distance;
      query.latitude = filters.latitude;
      query.longitude = filters.longitude;
    } else if (useFilter && distance !== "Any") {
      if (filters.member_type === 4 || filters.member_type === 5) {
        setShowSort(true);
        query.name = filters.name;
        query.page = filters.page;
        query.sport = filters.sport;
        query.distance = filters.distance;
        query.latitude = filters.latitude;
        query.longitude = filters.longitude;
        query.member_type = filters.member_type;
        query.lower_price = filters.lower_price;
        query.higher_price = filters.higher_price;
        query.positions = filters.positions;
        query.passports = filters.passports;
        query.want_contracts = filters.want_contracts;
        query.lower_height = filters.lower_height;
        query.higher_height = filters.higher_height;
        query.lower_weight = filters.lower_weight;
        query.higher_weight = filters.higher_weight;
        query.lower_age = filters.lower_age;
        query.higher_age = filters.higher_age;
        query.sort = filters.sort;
        if (filters.gender !== "0") {
          query.gender = filters.gender;
        }
      } else if (filters.member_type === 3) {
        query.name = filters.name;
        query.page = filters.page;
        query.sport = filters.sport;
        query.distance = filters.distance;
        query.latitude = filters.latitude;
        query.longitude = filters.longitude;
        query.member_type = filters.member_type;
        query.want_contracts = filters.want_contracts;
        query.sort = filters.sort;
      } else {
        query.name = filters.name;
        query.page = filters.page;
        query.sport = filters.sport;
        query.distance = filters.distance;
        query.latitude = filters.latitude;
        query.longitude = filters.longitude;
        query.member_type = filters.member_type;
        query.sort = filters.sort;
      }
    } else if (useFilter && distance === "Any") {
      if (filters.member_type === 4 || filters.member_type === 5) {
        setShowSort(true);
        query.name = filters.name;
        query.page = filters.page;
        query.sport = filters.sport;
        query.member_type = filters.member_type;
        query.lower_price = filters.lower_price;
        query.higher_price = filters.higher_price;
        query.positions = filters.positions;
        query.passports = filters.passports;
        query.want_contracts = filters.want_contracts;
        query.lower_height = filters.lower_height;
        query.higher_height = filters.higher_height;
        query.lower_weight = filters.lower_weight;
        query.higher_weight = filters.higher_weight;
        query.lower_age = filters.lower_age;
        query.higher_age = filters.higher_age;
        query.sort = filters.sort;
        if (filters.gender !== "0") {
          query.gender = filters.gender;
        }
      } else if (filters.member_type === 3) {
        query.name = filters.name;
        query.page = filters.page;
        query.sport = filters.sport;
        query.member_type = filters.member_type;
        query.want_contracts = filters.want_contracts;
        query.sort = filters.sort;
      } else {
        query.name = filters.name;
        query.page = filters.page;
        query.sport = filters.sport;
        query.member_type = filters.member_type;
        query.sort = filters.sort;
      }
    }

    fetchMembers(query);
  };

  useEffect(() => {
    handelFilter(extraFilters);
  }, [distance, page]);

  const handelClick = (player) => {
    if (player.type === 1) {
      navigate(HOME_ROUTES.AGENT + "/" + player.id);
    } else if (player.type === 2) {
      navigate(HOME_ROUTES.MEMBER + "/" + player.user_player?.id);
    } else if (player.type === 3) {
      navigate(HOME_ROUTES.COACH + "/" + player.id);
    } else if (player.type === 4) {
      navigate(HOME_ROUTES.TEAM + "/" + player.id);
    } else {
      navigate(HOME_ROUTES.MEMBER + "/" + player.user_player?.id);
    }
  };

  const lookingForName = (contract) => {
    if (contract === "1") return "Amateur";
    if (contract === "2") return "Overseas";
    if (contract === "3") return "Professional Contracts";
    return "";
  };

  const handleDistanceChange = (event) => {
    setPage(1);
    setDistance(event.target.value);
  };

  const openModal = () => {
    setOpen(true);
  };

  const openSortModal = () => {
    setOpen(true);
    setOpenSort(true);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 1 &&
      !loading
    ) {
      setPage(page + 1);
      // fetchMembers({
      //   ...extraFilters,
      //   ...{ name, distance, per_page: 20, page: page + 1 },
      // });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  const lookingForImage = (contract) => {
    if (contract === "1") return "/icons/amature.png";
    if (contract === "2") return "/icons/overseas.png";
    if (contract === "3") return "/icons/professional.png";
    return "";
  };

  return (
    <>
      <Page
        className={classes.root}
        title="Favourites"
        backgroundColor="#ffffff"
      >
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container} style={{ marginTop: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Noventa812, sans-serif",
                }}
              >
                Following
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <div className={classes.filterBlock}>
                <div onClick={() => openModal()} style={{ cursor: "pointer" }}>
                  <img src="/icons/drawer.png" width={50} alt=""/>
                </div>
                {showSort && (
                  <div style={{ marginLeft: 10 }}>
                    <BiSortDown
                      onClick={() => openSortModal()}
                      className={classes.filterIcon}
                      style={{ fontSize: "1.5em" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: 14,
                        marginTop: 5,
                      }}
                    >
                      Sort
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>

          <Typography
            gutterBottom
            style={{
              fontFamily: "Bold",
              fontSize: 24,
              color: "black",
              marginTop: 7,
            }}
          >
            Members
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <div className={classes.searchBox}>
                <input
                  type="text"
                  value={name}
                  placeholder="Search..."
                  onChange={(event) => setName(event.target.value)}
                  style={{
                    fontFamily: "Regular",
                    fontSize: 14,
                  }}
                />
                <button
                  onClick={() => {
                    setPage(1);
                    handelFilter(extraFilters);
                  }}
                >
                  <SearchIcon
                    style={{
                      color: AppColor.newDarkBlue,
                      fontSize: 16,
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  />
                </button>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  displayEmpty
                  value={distance}
                  onChange={(event) => handleDistanceChange(event)}
                  label="Age"
                  style={{ marginTop: -6 }}
                >
                  <MenuItem value="Any">
                    <em>
                      <Typography style={{ fontFamily: "Medium" }}>
                        Any (Km)
                      </Typography>
                    </em>
                  </MenuItem>
                  <MenuItem value={10}>
                    <Typography style={{ fontFamily: "Medium" }}>10</Typography>
                  </MenuItem>
                  <MenuItem value={20}>
                    <Typography style={{ fontFamily: "Medium" }}>20</Typography>
                  </MenuItem>
                  <MenuItem value={30}>
                    <Typography style={{ fontFamily: "Medium" }}>30</Typography>
                  </MenuItem>
                  <MenuItem value={40}>
                    <Typography style={{ fontFamily: "Medium" }}>40</Typography>
                  </MenuItem>
                  <MenuItem value={50}>
                    <Typography style={{ fontFamily: "Medium" }}>50</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{ marginTop: 30 }}>
            {members &&
              members.map((player, index) => {
                return (
                  <Grid key={index} container className={classes.memberBlock}>
                    <Grid
                      container
                      item
                      lg={11}
                      sm={11}
                      onClick={() => handelClick(player)}
                      style={{ cursor: "pointer" }}
                    >
                      <Grid item>
                        <ButtonBase className={classes.image}>
                          <Avatar
                            src={
                              player.user_player?.media?.length > 0
                                ? player.user_player?.media[0].media_url
                                : player.avatar_url
                            }
                            className={classes.img}
                          />
                          <Typography className={classes.memberLabel}>
                            {player.type === 1 && (
                              <img
                                src="/icons/img_agent.png"
                                width={10}
                                style={{ paddingLeft: 4, paddingRight: 4 }}
                                alt=""
                              />
                            )}

                            {player.type === 2 && (
                              <img
                                src="/icons/img_player.png"
                                width={10}
                                style={{ paddingLeft: 4, paddingRight: 4 }}
                                alt=""
                              />
                            )}

                            {player.type === 3 && (
                              <img
                                src="/icons/coach.png"
                                width={10}
                                style={{
                                  paddingLeft: 4,
                                  paddingRight: 4,
                                  marginTop: 2,
                                }}
                                alt=""
                              />
                            )}

                            {player.type === 4 && (
                              <img
                                src="/icons/img_team.png"
                                width={10}
                                style={{ paddingLeft: 4, paddingRight: 4 }}
                                alt=""
                              />
                            )}

                            {player.type === 1 && "Agent"}
                            {player.type === 2 && "Player"}
                            {player.type === 3 && "Coach"}
                            {player.type === 4 && "Team"}
                            {!player.type && "No Type"}
                          </Typography>
                        </ButtonBase>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm
                        container
                        className={classes.blockTextContainer}
                      >
                        <Grid item xs container direction="column">
                          <Grid item xs>
                            <Grid
                              container
                              className={classes.playerListAndVerifiedIcon}
                            >
                              <Typography className={classes.memberTitle}>
                                {player.full_name || ""}
                              </Typography>
                              <VerifiedPlayer player={player} />
                            </Grid>
                            <Typography className={classes.memberLocation}>
                              {player.is_agent_player === 1 ? (
                                <>
                                  <span>
                                    <img
                                      src="/icons/agent_icon.png"
                                      width={14}
                                      alt=""
                                    />
                                  </span>{" "}
                                  <span
                                    style={{ color: AppColor.newTextColor }}
                                  >
                                    | {player.user_player?.location}
                                  </span>{" "}
                                </>
                              ) : (
                                player.location
                              )}
                            </Typography>
                            {player.type === 1 && (
                              <Typography className={classes.agencyText}>
                                {player.agent?.agency.name}
                              </Typography>
                            )}
                            {player.type === 3 && (
                              <Typography className={classes.agencyText}>
                                {player.coach?.current_team}
                              </Typography>
                            )}
                            <div style={{ marginTop: 15 }}>
                              {player.type === 2 &&
                                player.user_player?.want_contracts &&
                                player.user_player?.want_contracts.map(
                                  (contract, index) => {
                                    return (
                                      index < 2 && (
                                        <label
                                          key={index}
                                          style={{
                                            padding: 5,
                                            marginRight: 5,
                                            borderRadius: 20,
                                            fontSize: 10,
                                            color: AppColor.newLightBlue,
                                            fontFamily: "Medium",
                                          }}
                                        >
                                          <span>
                                            {
                                              <img
                                                src={`${lookingForImage(
                                                  contract.contract
                                                )}`}
                                                width={10}
                                                style={{ marginTop: 3 }}
                                                alt=""
                                              />
                                            }
                                            {"  "}
                                            {lookingForName(contract.contract)}
                                          </span>
                                        </label>
                                      )
                                    );
                                  }
                                )}
                            </div>
                            <div style={{ marginTop: 5 }}>
                              {player.type === 2 &&
                                player.user_player?.positions &&
                                player.user_player.positions.map(
                                  (position, index) => {
                                    return (
                                      index < 2 && (
                                        <label
                                          key={index}
                                          style={{
                                            padding: 5,
                                            marginRight: 5,
                                            backgroundColor: "#5BEDF630",
                                            borderRadius: 20,
                                            fontSize: 10,
                                            color: AppColor.newTextColor,
                                            fontFamily: "Medium",
                                          }}
                                        >
                                          {position.position_name}
                                        </label>
                                      )
                                    );
                                  }
                                )}

                              {player.type === 3 &&
                                player.coach?.contracts &&
                                player.coach?.contracts.map(
                                  (contract, index) => {
                                    return (
                                      index < 2 && (
                                        <label
                                          key={index}
                                          style={{
                                            padding: 5,
                                            marginRight: 5,
                                            borderRadius: 20,
                                            fontSize: 10,
                                            color: AppColor.newLightBlue,
                                            fontFamily: "Medium",
                                          }}
                                        >
                                          <span>
                                            {
                                              <img
                                                src={`${lookingForImage(
                                                  contract.contract
                                                )}`}
                                                width={10}
                                                style={{ marginTop: 3 }}
                                                alt=""
                                              />
                                            }
                                            {"  "}
                                            {lookingForName(contract.contract)}
                                          </span>
                                        </label>
                                      )
                                    );
                                  }
                                )}
                            </div>

                            {player.type === 2 ? (
                              <Grid
                                container
                                className={classes.playerDetailsBox}
                              >
                                <Grid className={classes.playerDetailsDivider}>
                                  <Typography
                                    className={classes.playerDetailsHeading}
                                  >
                                    Age
                                  </Typography>
                                  <Typography
                                    className={classes.playerDetailsText}
                                  >
                                    {player?.user_player?.age}
                                  </Typography>
                                </Grid>

                                <Divider
                                  style={{
                                    color: "red",
                                    backgroundColor: AppColor.appBlueSecondary,
                                    height: 35,
                                    width: "2px",
                                  }}
                                />

                                <Grid className={classes.playerDetailsDivider}>
                                  <Typography
                                    className={classes.playerDetailsHeading}
                                  >
                                    Height
                                  </Typography>
                                  <Typography
                                    className={classes.playerDetailsText}
                                  >
                                    {player?.user_player?.height}cm
                                  </Typography>
                                </Grid>

                                <Divider
                                  style={{
                                    color: "red",
                                    backgroundColor: AppColor.appBlueSecondary,
                                    height: 35,
                                    width: "2px",
                                  }}
                                />

                                <Grid>
                                  <Typography
                                    className={classes.playerDetailsHeading}
                                  >
                                    Weight
                                  </Typography>
                                  <Typography
                                    className={classes.playerDetailsText}
                                  >
                                    {player?.user_player?.weight}kg
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item lg={1} sm={1}>
                      <span style={{ float: "right", cursor: "pointer" }}>
                        <FavouriteItem player={player} />
                      </span>
                    </Grid>
                    {player.user_player != null && (
                      <div>{player.user_player.player_id}</div>
                    )}
                  </Grid>
                );
              })}
          </Grid>

          {/* <Grid container spacing={1}>
            {members &&
              members.map((player, index) => {
                return (
                  <Grid key={index} container className={classes.memberBlock}>
                    <Grid
                      container
                      item
                      lg={11}
                      sm={11}
                      onClick={() => handelClick(player)}
                      style={{ cursor: "pointer" }}
                    >
                      <Grid item>
                        <ButtonBase className={classes.image}>
                          <Avatar
                            src={
                              player.user_player?.media?.length > 0
                                ? player.user_player?.media[0].media_url
                                : player.avatar_url
                            }
                            className={classes.img}
                          />
                          <Typography className={classes.memberLabel}>
                            {player.type === 1 && "Agent"}
                            {player.type === 2 && "Player"}
                            {player.type === 3 && "Coach"}
                            {player.type === 4 && "Team"}
                            {!player.type && "No Type"}
                          </Typography>
                        </ButtonBase>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm
                        container
                        className={classes.blockTextContainer}
                      >
                        <Grid item xs container direction="column">
                          <Grid item xs>
                            <Typography className={classes.memberTitle}>
                              {player.full_name || ""}
                            </Typography>
                            <Typography className={classes.memberLocation}>
                              {player.is_agent_player === 1 ? (
                                <>
                                  <span style={{ color: AppColor.appBlue }}>
                                    A
                                  </span>{" "}
                                  <span style={{ color: AppColor.lightGray }}>
                                    |
                                  </span>{" "}
                                  {player.user_player?.location}
                                </>
                              ) : (
                                player.location
                              )}
                            </Typography>
                            {player.type === 1 && (
                              <Typography className={classes.agencyText}>
                                {player.agent?.agency.name}
                              </Typography>
                            )}
                            {player.type === 3 && (
                              <Typography className={classes.agencyText}>
                                {player.coach?.current_team}
                              </Typography>
                            )}
                            <div style={{ marginTop: 25 }}>
                              {player.type === 2 &&
                                player.user_player?.positions &&
                                player.user_player.positions.map(
                                  (position, index) => {
                                    return (
                                      index < 2 && (
                                        <label
                                          key={index}
                                          style={{
                                            padding: 5,
                                            marginRight: 5,
                                            backgroundColor: "#d2d6de90",
                                            borderRadius: 4,
                                            fontSize: 10,
                                            color: AppColor.textColor,
                                            fontWeight: "500",
                                            fontFamily:
                                              "Montserrat, sans-serif",
                                          }}
                                        >
                                          {position.position_name}
                                        </label>
                                      )
                                    );
                                  }
                                )}

                              {player.type === 3 &&
                                player.coach?.contracts &&
                                player.coach?.contracts.map(
                                  (contract, index) => {
                                    return (
                                      index < 2 && (
                                        <label
                                          key={index}
                                          style={{
                                            padding: 5,
                                            marginRight: 5,
                                            backgroundColor: "#d2d6de90",
                                            borderRadius: 4,
                                            fontSize: 10,
                                            color: AppColor.textColor,
                                            fontWeight: "500",
                                            fontFamily:
                                              "Montserrat, sans-serif",
                                          }}
                                        >
                                          {lookingForName(contract.contract)}
                                        </label>
                                      )
                                    );
                                  }
                                )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item lg={1} sm={1}>
                      <span style={{ float: "right", cursor: "pointer" }}>
                        <FavouriteItem player={player} />
                      </span>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid> */}
        </Container>
        <FilterModal
          open={open}
          openSort={openSort}
          handleClose={() => setOpen(false)}
          handleCloseSort={() => {
            setOpen(false);
            setOpenSort(false);
          }}
          sport=""
          handelFilter={(data) => {
            setPage(1);
            handelFilter(data);
          }}
        />
      </Page>
    </>
  );
};

export default FavouriteList;
