import { Grid, Typography, Button, Box, Paper, useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

function AtheleteInfo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const title = {
    color: "#84ECF5",
    fontSize: isMobile ? 32 : 59,
    fontWeight: "Medium",
    letterSpacing: "-1px",
    lineHeight: 1.1,
    paddingRight: "16%",
    fontFamily: "Medium",
  };

  const description = {
    color: "white",
    fontSize: isMobile ? 16 : 18,
    marginTop: 30,
    letterSpacing: "0px",
    lineHeight: 1.5,
    fontFamily: "Regular",
    paddingRight: isMobile ? "5%" : "16%",
  };

  const signupButton = {
    color: "white",
    backgroundColor: "black",
    boxShadow: "rgb(91, 238, 246) 0px 0px 8px 1px",
    borderRadius: 28,
    width: isMobile ? "100%" : 162,
    height: 56,
    marginTop: "10%",
  };

  const signupText = {
    marginLeft: 15,
    marginRight: 15,
    fontSize: 16,
    fontWeight: "bold",
    color: "#84ECF5",
  };
  
  const box = {
    display: "flex",
    alignItems: "center",
    maxWidth: isMobile ? "100%" : "95%",
    margin: "0 auto",
    background: " radial-gradient(circle at top left, #1D1D1D, #000000)",
    borderRadius: isMobile ? 0 : 8,
  };

  const root = {
    marginTop: "10%",
    background: "black",
  };

  const holderGrid = {
    paddingLeft: isMobile ? 0 : 80,
    paddingTop: 50,
  };

  const transparent = {
    backgroundColor: "transparent",
  };

  const textContainer = {
    ...transparent,
    paddingTop: "10%",
    paddingBottom: "10%",
    paddingLeft: isMobile ? "10%" : 0,
    paddingRight: isMobile ? "10%" : 0,
    textAlign: isMobile ? "left" : "center",
  };

  return (
    <div style={root}>
      <Box style={box}>
        <Grid container spacing={2} columns={12} style={holderGrid}>
          <Grid item md={6}>
            <Paper style={{ ...transparent, textAlign: "center" }}>
              <img
                src={isMobile ? "/images/img_p_2.png" : "/images/img3.png"}
                style={{
                  width: "80%",
                }}
                alt=""
              />
            </Paper>
          </Grid>
          <Grid item md={6}>
            <Paper style={textContainer} elevation={0} justifyContent="center">
              <Typography style={title}>
                {"Every Athlete Is unique."}
              </Typography>
              <Typography style={description}>
                That's why we provide athletes with customisable profiles where
                they can showcase their detailed bio-metrics, skills,
                achievements and aspirations as well as post their best moments
                through the Video Player Highlights section.
              </Typography>
              <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }} to={isMobile ? "/mobile" : "/iam"}>
                <Button style={signupButton}>  
                  <Typography style={signupText}>{isMobile ? "Download now" : "Sign up now"}</Typography>
                </Button>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default AtheleteInfo;
