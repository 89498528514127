import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import AppColor from "../utils/color";

const LookingFor = ({ contracts }) => {
  const [openAchievement, setOpenAchievement] = useState(false);

  const lookingForName = (contract) => {
    if (contract === "1") return "Amateur";
    if (contract === "2") return "Overseas";
    if (contract === "3") return "Professional Contracts";
    return "";
  };

  ///css
  const lookingFor = {
    padding: 5,
    marginRight: 5,
    backgroundColor: "#5BEDF630",
    borderRadius: 20,
    fontSize: 14,
    color: AppColor.newTextColor,
    fontFamily: "Medium",
  };

  return (
    <Card
      style={{ marginTop: 0, boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
    >
      <CardHeader
        title={
          <Typography style={{ fontFamily: "Bold", fontSize: 20 }}>
            Looking For
          </Typography>
        }
        action={
          <IconButton
            onClick={() => setOpenAchievement(!openAchievement)}
            aria-label="expand"
            size="small"
          >
            {openAchievement ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        }
      ></CardHeader>
      <div>
        <Collapse in={openAchievement} timeout="auto" unmountOnExit>
          <CardContent>
            {contracts.length > 0 ? (
              contracts.map((contract, index) => {
                return (
                  <label key={index} style={lookingFor}>
                    {lookingForName(contract.contract)}
                  </label>
                );
              })
            ) : (
              <Typography>No Any Looking For</Typography>
            )}
          </CardContent>
        </Collapse>
      </div>
    </Card>
  );
};

export default LookingFor;
