import React, { useState, useRef, useEffect } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Grid,
  Radio,
  FormGroup,
  FormControlLabel,
  Slider,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { AUTH_ROUTES } from "../../../constants/site-routes";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";
import CustomSecondaryButton from "../../../components/Common/CustomSecondaryButton";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import { registerPlayerStep2 } from "../../../stores/actions/Authorize";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { LoadScript } from "@react-google-maps/api";
import Configs from "../../../configs/Configs";
import { CATEGORIES, COUNTRY, PLAYERS } from "../../../constants/ApiEndPoint";
import { fetchRecord } from "../../../services/Axios";
import { setSportId } from "../../../services/Storage";
import AppColor from "../../../utils/color";
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  bottomBox: {
    backgroundColor: theme.palette.btnColors.white,
    borderRadius: "20px 20px 0px 0px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      height: "100%",
    },
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      width: "50%",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px auto",
      width: "50%",
    },
  },
  textField: {
    width: "100%",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    fontFamily: "Medium",
    borderRadius: 60,
    "& input::placeholder": {
      fontSize: "16",
      fontFamily: "Medium",
    },

    "& .MuiOutlinedInput-input": {
      fontFamily: "Medium",
      fontSize: 16,
    },
  },

  textFieldHightlight: {
    width: "100%",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    fontFamily: "Medium",
    marginTop: 30,
    borderRadius: 60,
    "& input::placeholder": {
      fontSize: "16",
      fontFamily: "Medium",
    },
  },

  radioButton: {
    marginBottom: 16,
    color: "rgb(122 114 114)",
    fontSize: 14,
    fontFamily: "Medium",
  },
  slider: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  error: {
    color: "#f44336",
  },

  title: {
    fontWeight: "bold",
    fontSize: "40px",
    fontFamily: "Noventa812, sans-serif",
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },

  playerInfo: {
    fontFamily: "SemiBold",
    fontSize: 26,
    color: "black",
  },

  fillFormInfo: {
    color: AppColor.newTextColor,
    fontSize: 14,
    fontFamily: "Regular",
    letterHeight: 1.8,
  },

  uploadfile: {
    color: AppColor.newTextColor,
    fontSize: 16,
    marginTop: 55,
    fontFamily: "SemiBold",
  },

  imageIconButton: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: "2px",
    backgroundColor: "gray",
    borderRadius: "0 20px 0 0px",
  },

  iconBtn: {
    color: "#76ebf4",
    padding: "0px",
    borderRadius: "10px",
    border: "#76ebf4 solid 1px",
    height: 80,
    width: 80,
    backgroundColor: "#EAF9F9",
    marginTop: 10,
  },

  optionalText: {
    color: AppColor.newTextColor,
    fontSize: 14,
    fontFamily: "Regular",
    marginBottom: 10,
  },

  place: {
    padding: "18.5px 0px 15.5px 7.5px",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    fontFamily: "Medium",
    borderRadius: 60,
    width: "98%",
    paddingLeft: 15,
    fontSize: 16,
    borderColor: "transparent",
  },

  loading: {
    fontSize: 13,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 8,
  },

  inputLabel: {
    marginLeft: 6,
  },
}));

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function reformatDate(date) {
  // Split the input date string by '/'
  const parts = date.split("/");

  // Extract day, month, and year from the parts
  const day = String(parseInt(parts[0], 10)).padStart(2, "0");
  const month = String(parseInt(parts[1], 10)).padStart(2, "0");
  const year = String(parseInt(parts[2], 10)).padStart(2, "0");

  // Create a new date string in "m/d/yyyy" format
  const outputDate = `${year}-${month}-${day}`;

  return outputDate;
}

const RegisterStep2 = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authorize);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const imageRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 100000,
      label: "100K",
    },
  ];
  const [videoLink, setVideoLink] = useState(null);

  const [formData, setFormData] = useState({
    image: null,
    location: "",
    latitude: "",
    longitude: "",
    passports: [],
    sport: "",
    weight: 0,
    height: 0,
    gender: "1",
    price_low: 0,
    price_high: 100000,
    dob: user?.date_of_birth ? reformatDate(user?.date_of_birth) : "",
    date_of_birth: user?.date_of_birth,
    name: user?.full_name,
  });

  const [errors, setErrors] = useState({
    dob: false,
    location: false,
    sport: false,
    weight: false,
    height: false,
    gender: false,
    passports: false,
  });

  const [helperTexts, setHelperTexts] = useState({
    dob: "",
    location: "",
    sport: "",
    weight: "",
    height: "",
    gender: "",
    passports: "",
    video_links: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "weight" || name === "height") {
      setFormData({ ...formData, [name]: parseFloat(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlePriceChange = (event, newValues) => {
    setPriceRange(newValues);
    setFormData({
      ...formData,
      price_low: newValues[0],
      price_high: newValues[1],
    });
  };

  async function handleSaveAndContinue() {
    const { is_valid, validationErrors, validationHelperTexts } =
      validateForm(formData);
    if (!is_valid) {
      setHelperTexts(validationHelperTexts);
      setErrors(validationErrors);
    } else {
      const date = new Date(formData.dob);
      formData.date_of_birth = formatDate(date);
      formData.video_links = [videoLink];
      formData.name = user?.full_name;

      setLoading(true);
      let response = await dispatch(registerPlayerStep2(formData));
      setLoading(false);
      if (response.status === 200) {
        setSportId(formData.sport);
        navigate(AUTH_ROUTES.REGISTER_STEP_3);
      }
    }
  }

  const validateForm = (data) => {
    let is_valid = true;
    let validationHelperTexts = {
      dob: "",
      location: "",
      sport: "",
      weight: "",
      height: "",
      gender: "",
      passports: "",
    };

    let validationErrors = {
      dob: false,
      location: false,
      sport: false,
      weight: false,
      height: false,
      gender: false,
      passports: false,
    };

    if (!data.dob.trim()) {
      validationHelperTexts.dob = "Date of birth is required";
      validationErrors.dob = true;
      is_valid = false;
    }

    if (!data.location.trim()) {
      validationHelperTexts.location = "Location is required";
      validationErrors.location = true;
      is_valid = false;
    }

    if (!data.weight) {
      validationHelperTexts.weight = "Weight is required";
      validationErrors.weight = true;
      is_valid = false;
    }

    if (!data.height) {
      validationHelperTexts.height = "Height is required";
      validationErrors.height = true;
      is_valid = false;
    }

    if (!data.gender) {
      validationHelperTexts.gender = "Gender is required";
      validationErrors.gender = true;
      is_valid = false;
    }

    if (!data.sport.trim()) {
      validationHelperTexts.sport = "Sport is required";
      validationErrors.sport = true;
      is_valid = false;
    }

    if (data.passports.length === 0) {
      validationHelperTexts.passports = "Please select at least one passport";
      validationErrors.passports = true;
      is_valid = false;
    }

    return { validationHelperTexts, validationErrors, is_valid };
  };

  const handelGoBack = async () => {
    navigate(AUTH_ROUTES.RegisterStep2);
  };

  const handleOpen = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setFormData({ ...formData, image: file });
      };
      reader.readAsDataURL(file);
    }
  };

  const clearImage = () => {
    setSelectedImage(null);
    setFormData({ ...formData, image: null });
  };

  const libraries = ["places"];
  const googleMapsApiKey = Configs.GOOGLE_API_KEY;
  const [address, setAddress] = useState("");

  const handleSelect = async (selectedAddress) => {
    try {
      setAddress(selectedAddress);
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setFormData({
        ...formData,
        location: selectedAddress,
        latitude: latLng.lat.toString(),
        longitude: latLng.lng.toString(),
      });
    } catch (error) {
      setAddress("");
      console.log(error);
    }
  };

  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const fetchSports = async () => {
    const response = await fetchRecord(CATEGORIES.LIST);
    if (response && response.data && response.status === 200) {
      let cats = [];
      response.data.forEach((cat) => {
        cats.push({ text: cat.name, id: cat.name });
      });
      setCategories(cats);
    }
  };

  const fetchCountries = async () => {
    const response = await fetchRecord(COUNTRY.LIST);
    if (response && response.data && response.status === 200) {
      let cats = [];
      response.data.forEach((cat) => {
        cats.push({ text: cat.name, id: cat.name });
      });
      setCountries(cats);
    }
  };

  const fetchProfile = async () => {
    setLoading(true);
    const response = await fetchRecord(PLAYERS.PROFILE + "/" + user.id);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      const profile = response.data;
      const passports = [];
      profile.passports.forEach((passport) => {
        passports.push(passport.passport_name);
      });

      setFormData({
        image: null,
        dob: profile?.date_of_birth ? reformatDate(profile.date_of_birth) : "",
        date_of_birth: profile.date_of_birth,
        location: profile?.user_player?.location ?? "",
        latitude: profile?.user_player?.latitude ?? "",
        longitude: profile?.user_player?.longitude ?? "",
        passports: passports,
        sport: profile?.user_player?.sport ?? "",
        weight: profile?.weight,
        height: profile?.height,
        gender: profile?.gender ? profile?.gender.toString() : "1",
        price_low: profile?.price_low ?? 0,
        price_high: profile?.price_high ?? 100000,
        name: profile?.full_name,
      });

      setSelectedImage(profile?.user_player?.avatar_url);
      setPriceRange([profile?.price_low ?? 0, profile?.price_high ?? 100000]);
    }
  };

  const handlePassportChange = (event) => {
    const passports = [...event.target.selectedOptions].map(option => option.value);
    setFormData({ ...formData, passports: passports });
  };

  const handleSportChange = (event) => {
    setFormData({ ...formData, sport: event.target.value });
  };

  useEffect(() => {
    fetchSports();
    fetchCountries();
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Page
        className={classes.root}
        title="Player Profile"
        backgroundColor="#ffffff"
      >
        {loading && <SimpleBackdrop />}
        <Box className={classes.bottomBox}>
          <Box className={classes.fromBlock}>
            <Box className={classes.subTopBox}>
              <Typography className={classes.title}>ABOUT YOU</Typography>
            </Box>
            <Box
              className={classes.textFieldBlock}
              style={{ paddingTop: "50px" }}
            >
              <Typography
                className={classes.playerInfo}
                variant="h2"
                gutterBottom
              >
                Player Information
              </Typography>
              <Typography className={classes.fillFormInfo}>
                Please fill in the information below to be added on your profile
              </Typography>

              <Typography className={classes.uploadfile}>
                Upload Profile Picture
              </Typography>

              <div style={{ width: 100, marginTop: 10 }}>
                {selectedImage ? (
                  <div style={{ position: "relative" }}>
                    <img
                      src={selectedImage}
                      alt="Selected"
                      width="100px"
                      height="100px"
                      style={{ borderRadius: "20px" }}
                    />
                    <IconButton
                      className={classes.imageIconButton}
                      onClick={clearImage}
                    >
                      <Close />
                    </IconButton>
                  </div>
                ) : (
                  <IconButton
                    color="primary"
                    onClick={handleOpen}
                    className={classes.iconBtn}
                  >
                    <img
                      alt="upload"
                      src="/images/profile.png"
                      style={{ height: 30, width: 30 }}
                    />
                  </IconButton>
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="imageInput"
                  ref={imageRef}
                />
              </div>

              <TextField
                variant="outlined"
                placeholder="Add highlight link URL"
                className={classes.textFieldHightlight}
                value={videoLink}
                onChange={(e) => setVideoLink(e.target.value)}
              />
              <Typography className={classes.optionalText}>
                Optional: You can add this later to you profile
              </Typography>

              {user && user.type === 1 && (
                <TextField
                  variant="outlined"
                  placeholder="Enter Name"
                  className={classes.textField}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={errors.name}
                  helperText={helperTexts.name}
                />
              )}

              <TextField
                variant="outlined"
                placeholder="Date of Birth (DD/MM/YYYY)"
                className={classes.textField}
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                error={errors.dob}
                helperText={helperTexts.dob}
                style={{ marginTop: 15 }}
              />

              <div style={{ marginTop: 20 }}>
                <LoadScript
                  googleMapsApiKey={googleMapsApiKey}
                  libraries={libraries}
                >
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: formData.location ? formData.location : "Enter your location",
                          })}
                          className={classes.place}
                        />
                        <div>
                          {loading ? (
                            <div>
                              <Typography className={classes.loading}>
                                Loading...
                              </Typography>
                            </div>
                          ) : null}
                          {suggestions.map((suggestion, index) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                            };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                                key={index}
                                style={{
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                <Typography>
                                  {suggestion.description}
                                </Typography>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <Typography className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">
                    {errors.location ? helperTexts.location : ""}
                  </Typography>
                </LoadScript>
              </div>

              <Grid container spacing={1} style={{ marginTop: 15 }}>
                <Grid container item xs={6}>
                  <TextField
                    variant="outlined"
                    placeholder="Weight"
                    className={classes.textField}
                    type="number"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                    error={errors.weight}
                    helperText={helperTexts.weight}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">KG</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    variant="outlined"
                    placeholder="Height"
                    className={classes.textField}
                    type="number"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                    error={errors.height}
                    helperText={helperTexts.height}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">CM</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <FormControl
                style={{
                  width: "100%",
                  marginBottom: 20,
                  backgroundColor: AppColor.newTextFieldBackgroundColor,
                  fontFamily: "Medium",
                  borderRadius: 30,
                  marginTop: 15,
                }}
              >
                <Select2
                  value={ formData.sport }
                  data={categories}
                  options={
                    {
                      placeholder: 'Select Sport',
                    }
                  }
                  onSelect={handleSportChange}
                />
                <FormHelperText>
                  <Typography className={classes.error}>
                    {errors.sport ? helperTexts.sport : ""}
                  </Typography>
                </FormHelperText>
              </FormControl>

              <FormControl
                style={{
                  width: "100%",
                  marginBottom: 20,
                  borderRadius: 30,
                  "& .MuiInputBase-root": {
                    borderBottom: "none", // Remove the underline
                  },
                  backgroundColor: "#E6E7E8",
                  fontFamily: "Medium",
                  // paddingBottom: 10,
                }}
              >
                <Select2
                  multiple
                  value={ formData.passports }
                  data={countries}
                  options={
                    {
                      placeholder: 'Select Passports',
                    }
                  }
                  onSelect={handlePassportChange}
                />
                {
                  errors.passports && (
                    <FormHelperText>
                      <Typography className={classes.error}>
                        {errors.passports ? helperTexts.passports : ""}
                      </Typography>
                    </FormHelperText>
                  )
                }
                
              </FormControl>

              <Grid container spacing={1}>
                <Grid container item xs={4}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{ fontSize: 16, fontFamily: "Medium" }}
                  >
                    Gender
                  </Typography>
                </Grid>
                <Grid container item xs={8} justifyContent="flex-end">
                  <FormGroup row>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Male"
                      labelPlacement="start"
                      onChange={handleChange}
                      checked={formData.gender === "1"}
                      className={classes.radioButton}
                      name="gender"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Female"
                      labelPlacement="start"
                      onChange={handleChange}
                      checked={formData.gender === "2"}
                      className={classes.radioButton}
                      name="gender"
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs style={{ marginLeft: 15, marginRight: 15 }}>
                  <Slider
                    value={priceRange}
                    onChange={handlePriceChange}
                    aria-labelledby="range-slider"
                    min={0}
                    max={100000}
                    marks={marks}
                    step={5}
                    valueLabelDisplay="on"
                    className={classes.slider}
                  />
                </Grid>
              </Grid>

              <CustomPrimaryButton
                text="Save and Continue"
                handleClick={handleSaveAndContinue}
              />

              <div style={{ marginTop: 20 }}>
                <CustomSecondaryButton
                  text="Go Back"
                  handleClick={handelGoBack}
                />
              </div>

              {/* <FormControl
                style={{
                  width: "100%",
                  backgroundColor: AppColor.newTextFieldBackgroundColor,
                  fontFamily: "Medium",
                  borderRadius: "25px",
                  marginTop: 15,
                }}
              >
                <InputLabel
                  id="multiple-select-label"
                  style={{
                    marginLeft: 6,
                  }}
                  className={classes.inputLabel}
                >
                  Select Sport
                </InputLabel>

                <Select
                  value={formData.sport}
                  onChange={handleSportChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disableUnderline
                >
                  {categories.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      <Typography style={{ marginLeft: 6, marginBottom: 8 }}>
                        {option.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  <Typography className={classes.error}>
                    {errors.sport ? helperTexts.sport : ""}
                  </Typography>
                </FormHelperText>
              </FormControl> */}

              {/* <div style={{ width: 100, marginTop: 10 }}>
                {selectedImage ? (
                  <div style={{ position: "relative" }}>
                    <img
                      src={selectedImage}
                      alt="Selected"
                      width="100px"
                      height="100px"
                      style={{ borderRadius: "20px" }}
                    />
                    <IconButton
                      className={className.imageIconButton}
                      onClick={clearImage}
                    >
                      <Close />
                    </IconButton>
                  </div>
                ) : (
                  <IconButton
                    color="primary"
                    onClick={handleOpen}
                    className={classes.iconBtn}
                  >
                    <img
                      alt="upload"
                      src="/images/profile.png"
                      style={{ height: 30, width: 30 }}
                    />
                  </IconButton>
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="imageInput"
                  ref={imageRef}
                />
              </div>

              <TextField
                variant="outlined"
                placeholder="Add highlight link URL"
                className={classes.textFieldHightlight}
                value={videoLink}
                onChange={(e) => setVideoLink(e.target.value)}
              />
              <Typography className={classes.optionalText}>
                Optional: You can add this later to you profile
              </Typography>

              {user && user.type === 1 && (
                <TextField
                  variant="outlined"
                  placeholder="Enter Name"
                  className={classes.textField}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={errors.name}
                  helperText={helperTexts.name}
                />
              )}

              <TextField
                variant="outlined"
                placeholder="Date of Birth (DD/MM/YYYY)"
                className={classes.textField}
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                error={errors.dob}
                helperText={helperTexts.dob}
              />

              <div style={{ marginBottom: "30px" }}>
                <LoadScript
                  googleMapsApiKey={googleMapsApiKey}
                  libraries={libraries}
                >
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter your location",
                          })}
                          className={classes.place}
                        />
                        <div>
                          {loading ? (
                            <div>
                              <Typography className={classes.loading}>
                                Loading...
                              </Typography>
                            </div>
                          ) : null}
                          {suggestions.map((suggestion, index) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                            };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                                key={index}
                                style={{
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                <Typography>
                                  {suggestion.description}
                                </Typography>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <Typography className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled">
                    {errors.location ? helperTexts.location : ""}
                  </Typography>
                </LoadScript>
              </div>

              <Grid container spacing={1}>
                <Grid container item xs={6}>
                  <TextField
                    variant="outlined"
                    placeholder="Weight"
                    className={classes.textField}
                    type="number"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                    error={errors.weight}
                    helperText={helperTexts.weight}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">KG</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container item xs={6}>
                  <TextField
                    variant="outlined"
                    placeholder="Height"
                    className={classes.textField}
                    type="number"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                    error={errors.height}
                    helperText={helperTexts.height}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">CM</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <FormControl
                style={{
                  width: "100%",
                  marginBottom: 20,
                  backgroundColor: "#E6E7E8",
                  fontFamily: "Bai Jamjuree, sans-serif",
                  borderRadius: "4px",
                }}
              >
                <InputLabel
                  id="multiple-select-label"
                  style={{ marginLeft: 6 }}
                >
                  Select Sport
                </InputLabel>

                <Select
                  value={formData.sport}
                  onChange={handleSportChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disableUnderline
                >
                  {categories.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      <Typography style={{ marginLeft: 6, marginBottom: 8 }}>
                        {option.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  <Typography className={classes.error}>
                    {errors.sport ? helperTexts.sport : ""}
                  </Typography>
                </FormHelperText>
              </FormControl>

              <FormControl
                style={{
                  width: "100%",
                  marginBottom: 20,
                  borderRadius: "4px",
                  "& .MuiInputBase-root": {
                    borderBottom: "none", // Remove the underline
                  },
                  backgroundColor: "#E6E7E8",
                  fontFamily: "Bai Jamjuree, sans-serif",
                }}
              >
                <InputLabel
                  id="multiple-select-label"
                  style={{ marginLeft: 6, marginBottom: 14 }}
                >
                  Select Passports
                </InputLabel>
                <Select
                  disableUnderline
                  labelId="multiple-select-label"
                  id="multiple-select"
                  multiple
                  value={formData.passports}
                  onChange={handlePassportChange}
                  label="Select Passports"
                  renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} style={{ margin: 2 }} />
                      ))}
                    </div>
                  )}
                >
                  {countries.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  <Typography className={classes.error}>
                    {errors.passports ? helperTexts.passports : ""}
                  </Typography>
                </FormHelperText>
              </FormControl>

              <Grid container spacing={1}>
                <Grid container item xs={4}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{ fontSize: 18 }}
                  >
                    Gender
                  </Typography>
                </Grid>
                <Grid container item xs={8} justifyContent="flex-end">
                  <FormGroup row>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Male"
                      labelPlacement="start"
                      onChange={handleChange}
                      checked={formData.gender === "1"}
                      className={classes.radioButton}
                      name="gender"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="Female"
                      labelPlacement="start"
                      onChange={handleChange}
                      checked={formData.gender === "2"}
                      className={classes.radioButton}
                      name="gender"
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Typography variant="h2" gutterBottom style={{ fontSize: 18 }}>
                Price Range
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs style={{ marginLeft: 15, marginRight: 15 }}>
                  <Slider
                    value={priceRange}
                    onChange={handlePriceChange}
                    aria-labelledby="range-slider"
                    min={0}
                    max={100000}
                    marks={marks}
                    step={5}
                    valueLabelDisplay="on"
                    className={classes.slider}
                  />
                </Grid>
              </Grid>

              <CustomPrimaryButton
                text="Save and Continue"
                handleClick={handleSaveAndContinue}
              />

              <div style={{ marginTop: 50 }}>
                <CustomSecondaryButton
                  text="Go Back"
                  handleClick={handelGoBack}
                />
              </div> */}
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default RegisterStep2;
