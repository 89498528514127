import { Button, Typography } from "@material-ui/core";
import AppColor from "../../utils/color";

function CustomButton({ text, handleClick }) {
  return (
    <Button
      variant="contained"
      onClick={handleClick}
      style={{
        textTransform: "none",
        height: 54,
        fontFamily: "Medium",
        border: `1px ${AppColor.newDarkBlue} solid`,
        background: "white",
        width: "100%",
      }}
      disableElevation
    >
      <Typography
        style={{
          fontFamily: "SemiBold",
          fontSize: 15,
          color: AppColor.newDarkBlue,
        }}
      >
        {text}
      </Typography>
    </Button>
  );
}

export default CustomButton;
