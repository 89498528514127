import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  IconButton,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import AppColor from "../utils/color";

const Achievements = ({ achievements }) => {
  const [openAchievement, setOpenAchievement] = useState(false);

  ///css
  const titleCSS = {
    fontFamily: "Medium",
    fontSize: 14,
    color: AppColor.newTextColor,
  };

  return (
    <Card
      style={{ marginTop: 20, boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
      elevation={0}
    >
      <CardHeader
        title={
          <Typography style={{ fontFamily: "Bold", fontSize: 20 }}>
            Achievements
          </Typography>
        }
        action={
          <IconButton
            onClick={() => setOpenAchievement(!openAchievement)}
            aria-label="expand"
            size="small"
          >
            {openAchievement ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        }
      ></CardHeader>
      <div>
        <Collapse in={openAchievement} timeout="auto" unmountOnExit>
          <CardContent>
            <Container
              sx={{
                height: 100,
                lineHeight: 2,
              }}
            >
              {achievements && typeof achievements === "string" && (
                <Typography style={titleCSS}>{achievements}</Typography>
              )}

              {achievements &&
                typeof achievements !== "string" &&
                achievements.length > 0 &&
                achievements.map((achievement, index) => {
                  return (
                    <Typography key={index}>
                      {achievement.rep_honours_name}
                    </Typography>
                  );
                })}

              {(!achievements ||
                (typeof achievements !== "string" &&
                  achievements.length === 0)) && (
                <Typography>No Achievements</Typography>
              )}
            </Container>
          </CardContent>
        </Collapse>
      </div>
    </Card>
  );
};

export default Achievements;
