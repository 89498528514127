const Configs = {
  API_URI: process.env.REACT_APP_API_URL,
  API_KEY: process.env.REACT_APP_API_KEY,
  APP_URL: process.env.REACT_APP_URL,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGE_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  FIREBASE_API_ID: process.env.REACT_APP_FIREBASE_API_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  GOOGLE_SSO_API_KEY: process.env.REACT_APP_GOOGLE_SSO_API_KEY,
  FACEBOOK_SSO_API_KEY: process.env.REACT_APP_FACEBOOK_SSO_API_KEY,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  FCM_KEY: process.env.REACT_APP_FCM_KEY,
};

export default Configs;
  