import { Grid, Typography, Box, Paper, useTheme, useMediaQuery } from "@material-ui/core";

function Discover({testimonialsRef}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const title = {
    color: "#212121",
    fontSize: isMobile ? 32 : 56,
    fontFamily: "Medium",
    letterSpacing: "-1px",
    lineHeight: 1.1,
  };

  const box = {
    display: "flex",
    alignItems: "center",
    maxWidth: "80%",
    margin: "0 auto",
  };

  const grid = {
    marginTop: "5%",
    paddingBottom: "10%",
  };

  return (
    <div style={{ marginTop: "5%", background: "white" }} ref={testimonialsRef}>
      <div>
        <Box sx={box} elevation={0}>
          <Grid container spacing={5} columns={12} style={grid}>
            <Grid item md={12}>
              <Paper
                style={{ background: " transparent", textAlign: "center" }}
                elevation={0}
              >
                <Typography style={title}>
                  Discover What <br />
                  Our Users Have To Say.
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={12} style={{ marginTop: "3%" }}>
              <Paper elevation={0} style={{ textAlign: "center" }}>
                <img src={isMobile ? "/images/img_p_6.png" : "/images/img7.png"} style={{ width: isMobile ? "100%" : "90%" }} alt=""/>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
export default Discover;
