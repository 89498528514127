import React from 'react';
import { CardMedia } from '@material-ui/core';

function Logo() {
    return (
        <CardMedia
            component="img"
            alt="Logo"
            height="130"
            width="300"
            image="/images/logo.png"
            style={{width: '295px', objectFit: 'contain'}}
        />
    );
};

export default Logo;
