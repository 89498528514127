import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  Typography,
  Grid,
  ButtonBase,
  Avatar,
  Badge,
} from "@material-ui/core";
import { Divider, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppColor from "../../utils/color";
import "./index.css";
import { Link as RouterLink } from "react-router-dom";
import { RiHome2Line } from "react-icons/ri";
import {
  BiSolidUser,
  BiSolidStar,
  BiSolidMessageDetail,
  BiSolidShield,
  BiSolidBell,
} from "react-icons/bi";
import {
  AiFillFileText,
  AiFillHome,
  AiOutlinePoweroff,
  AiFillSetting,
  AiFillInfoCircle,
  AiFillCustomerService,
} from "react-icons/ai";
import { AUTH_ROUTES, HOME_ROUTES } from "../../constants/site-routes";
import CardActions from "@material-ui/core/CardActions";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../stores/actions/Authorize";
import { clearAccessToken } from "../../services/Storage";
import {} from "react-redux";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import { useLocation } from "react-router-dom";
import { FaUserFriends } from "react-icons/fa";
import DiscoverIcon from "../../components/Icons/DiscoverIcon";

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authorize);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      return;
    }
  }, [user]);

  const firstBlock = () => {
    if (user) {
      if (user.type === 1 || user.type === 5) {
        return [
          {
            name: "Home",
            icon: <AiFillHome className={classes.menuIcon} />,
            href: HOME_ROUTES.INDEX,
          },
          {
            name: "Discover",
            icon: <DiscoverIcon className={classes.menuIcon} />,
            href: HOME_ROUTES.DISCOVER,
          },
          {
            name: "Following",
            icon: <BiSolidStar className={classes.menuIcon} />,
            href: HOME_ROUTES.FAVOURITES,
          },
          {
            name: "Notifications",
            icon: <BiSolidBell className={classes.menuIcon} />,
            href: HOME_ROUTES.NOTIFICATIONS,
            badge: true,
          },
          {
            name: "Profile",
            icon: <BiSolidUser className={classes.menuIcon} />,
            href: HOME_ROUTES.PROFILE,
          },
          {
            name: "My Players",
            icon: <FaUserFriends className={classes.menuIcon} />,
            href: user.type === 1 ? HOME_ROUTES.AGENT + "/" + user?.agent?.id + "/players" : HOME_ROUTES.SCHOOL + "/" + user?.school?.id + "/players",
          },
          {
            name: "Message",
            icon: <BiSolidMessageDetail className={classes.menuIcon} />,
            href: HOME_ROUTES.MESSAGES,
          },
        ];
      } else {
        return [
          {
            name: "Home",
            icon: <AiFillHome className={classes.menuIcon} />,
            href: HOME_ROUTES.INDEX,
          },
          {
            name: "Discover",
            icon: <DiscoverIcon className={classes.menuIcon} />,
            href: HOME_ROUTES.DISCOVER,
          },
          {
            name: "Following",
            icon: <BiSolidStar className={classes.menuIcon} />,
            href: HOME_ROUTES.FAVOURITES,
          },
          {
            name: "Notifications",
            icon: <BiSolidBell className={classes.menuIcon} />,
            href: HOME_ROUTES.NOTIFICATIONS,
            badge: true,
          },
          {
            name: "Profile",
            icon: <BiSolidUser className={classes.menuIcon} />,
            href: HOME_ROUTES.PROFILE,
          },
          {
            name: "Message",
            icon: <BiSolidMessageDetail className={classes.menuIcon} />,
            href: HOME_ROUTES.MESSAGES,
          },
        ];
      }
    } else {
      return [
        {
          name: "Home",
          icon: <RiHome2Line className={classes.menuIcon} />,
          href: HOME_ROUTES.INDEX,
        },
      ];
    }
  };

  const secondBlock = () => {
    return [
      {
        name: "Contact Us",
        icon: <AiFillCustomerService className={classes.menuIcon} />,
        href: HOME_ROUTES.CONTACT_US,
      },
      {
        name: "About Us",
        icon: (
          <AiFillInfoCircle
            className={classes.menuIcon}
            style={{ transform: "transform: rotate(180deg)" }}
          />
        ),
        href: HOME_ROUTES.ABOUT_US,
      },

      {
        name: "Terms & Conditions",
        icon: <AiFillFileText className={classes.menuIcon} />,
        href: HOME_ROUTES.TERMS_AND_CONDITIONS,
      },
      {
        name: "Privacy & Policy",
        icon: <BiSolidShield className={classes.menuIcon} />,
        href: HOME_ROUTES.PRIVACY_AND_POLICY,
      },
    ];
  };

  const thirdBlock = () => {
    return [
      {
        name: "Settings",
        icon: <AiFillSetting className={classes.menuIcon} />,
        href: HOME_ROUTES.SETTINGS,
      },
    ];
  };

  const handleLogout = async () => {
    setLoading(true);
    await dispatch(logout());
    setLoading(false);
    clearAccessToken();
    window.location.href = AUTH_ROUTES.HOME;
  };

  return (
    <>
      {loading && <SimpleBackdrop />}
      <Drawer
        variant="permanent"
        anchor="left"
        className="drawer"
        PaperProps={{ style: { backgroundColor: "#89d5df" } }}
      >
        <List className={classes.list}>
          {user && (
            <Grid container style={{ padding: 25 }}>
              <Grid
                container
                item
                lg={12}
                sm={12}
                style={{ cursor: "pointer" }}
              >
                <Grid item style={{ marginRight: 5 }}>
                  <ButtonBase className={classes.image}>
                    <Avatar src={user.avatar_url} style={{ borderRadius: 0 }} />
                  </ButtonBase>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm
                  container
                  className={classes.blockTextContainer}
                >
                  <Grid item xs container direction="column">
                    <Grid item xs>
                      <Typography
                        style={{ fontFamily: "SemiBold", fontSize: 12 }}
                      >
                        {user.full_name || ""}
                      </Typography>
                      <Typography
                        style={{ fontFamily: "SemiBold", fontSize: 12 }}
                      >
                        {user.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {firstBlock().map((menu, index) => (
            <Link
              component={RouterLink}
              to={menu.href ? menu.href : "#"}
              underline="none"
              key={index}
            >
              <ListItem
                className={classes.menu}
                button
                key={menu.name}
                style={
                  menu.href === location.pathname
                    ? { backgroundColor: "#ffffff" }
                    : {}
                }
              >
                <CardActions disableSpacing className={classes.cardActions}>
                  {menu.badge ? (
                    <Badge
                      badgeContent={user.notifications_count}
                      color="primary"
                    >
                      <span>{menu.icon}</span>
                    </Badge>
                  ) : (
                    <span>{menu.icon}</span>
                  )}
                  <span>
                    <Typography className={classes.itemName}>
                      {menu.name}
                    </Typography>
                  </span>
                </CardActions>
              </ListItem>
            </Link>
          ))}
          <Divider className={classes.divider} />
          {secondBlock().map((menu, index) => (
            <Link
              component={RouterLink}
              to={menu.href ? menu.href : "#"}
              underline="none"
              key={index}
            >
              <ListItem
                className={classes.menu}
                button
                key={menu.name}
                style={
                  menu.href === location.pathname
                    ? { backgroundColor: "#ffffff" }
                    : {}
                }
              >
                <CardActions disableSpacing className={classes.cardActions}>
                  <span>{menu.icon}</span>
                  <span>
                    <Typography className={classes.itemName}>
                      {menu.name}
                    </Typography>
                  </span>
                </CardActions>
              </ListItem>
            </Link>
          ))}
          {user && <Divider className={classes.divider} />}
          {user &&
            thirdBlock().map((menu, index) => (
              <Link
                component={RouterLink}
                to={menu.href ? menu.href : "#"}
                underline="none"
                  key={index}
              >
                <ListItem
                  className={classes.menu}
                  button
                  key={menu.name}
                  style={
                    menu.href === location.pathname
                      ? { backgroundColor: "#ffffff" }
                      : {}
                  }
                >
                  <CardActions disableSpacing className={classes.cardActions}>
                    <span>{menu.icon}</span>
                    <span>
                      <Typography className={classes.itemName}>
                        {menu.name}
                      </Typography>
                    </span>
                  </CardActions>
                </ListItem>
              </Link>
            ))}
          {user && (
            <ListItem
              className={classes.menu}
              button
              key="Logout"
              onClick={handleLogout}
            >
              <CardActions disableSpacing className={classes.cardActions}>
                <span>
                  <AiOutlinePoweroff className={classes.menuIcon} />
                </span>
                <span>
                  <Typography className={classes.itemName}>Logout</Typography>
                </span>
              </CardActions>
            </ListItem>
          )}
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    marginTop: 10,
    marginBottom: 4,
    backgroundColor: "#B8B8B8",
    height: 0.4,
  },
  list: {
    marginLeft: 2,
    marginRight: 2,
  },
  menuIcon: {
    color: AppColor.black,
    fontSize: 20,
    "&:hover": {
      color: AppColor.appTextColor,
    },
  },
  itemName: {
    fontSize: 15,
    fontWeight: 400,
    marginLeft: 10,
    color: AppColor.black,
    fontFamily: "Medium",
  },
  menu: {
    height: 40,
    marginTop: 10,
    borderRadius: 30,
    "&:hover": {
      opacity: 1,
      textDecoration: "none",
      backgroundColor: AppColor.appTextColor,
      borderRadius: 30,
    },
  },
}));
