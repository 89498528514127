import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import AppColor from "../utils/color";

const PlayerInfromation = ({ player }) => {
  const [openAchievement, setOpenAchievement] = useState(false);

  const previousClubs = (clubs) => {
    if (clubs.length > 0) {
      let clubNames = [];
      // eslint-disable-next-line array-callback-return
      clubs.map((club) => {
        clubNames.push(club.previous_club);
      });
      return clubNames.join(", ");
    }
    return "";
  };

  const repHonour = (honours) => {
    if (honours.length > 0) {
      let honourNames = [];
      // eslint-disable-next-line array-callback-return
      honours.map((honour) => {
        honourNames.push(honour.rep_honours_name);
      });
      return honourNames.join(", ");
    }
    return "";
  };

  const passports = (passports) => {
    if (passports.length > 0) {
      let passportNames = [];
      // eslint-disable-next-line array-callback-return
      passports.map((passport) => {
        passportNames.push(passport.passport_name);
      });
      return passportNames.join(", ");
    }
    return "";
  };

  const priceRange = (price_low, price_high) => {
    return `${price_low} - ${price_high}`;
  };

  const subTitleCSS = {
    textAlign: "start",
    fontFamily: "Medium",
    fontSize: 18,
  };

  const titleCSS = {
    fontFamily: "Medium",
    fontSize: 14,
    color: AppColor.newTextColor,
  };

  return (
    <Card
      style={{ marginTop: -10, boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
      elevation={0}
    >
      <CardHeader
        title={
          <Typography style={{ fontFamily: "Bold", fontSize: 20 }}>
            Player Information
          </Typography>
        }
        action={
          <IconButton
            onClick={() => setOpenAchievement(!openAchievement)}
            aria-label="expand"
            size="small"
          >
            {openAchievement ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        }
      ></CardHeader>
      <div>
        <Collapse in={openAchievement} timeout="auto" unmountOnExit>
          <CardContent>
            <Container
              sx={{
                height: 100,
                lineHeight: 2,
              }}
            >
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography style={titleCSS}>Player Name</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={subTitleCSS}>{player.name}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography style={titleCSS}>Location</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={subTitleCSS}>{player.location}</Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography style={titleCSS}>Current Club</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={subTitleCSS}>
                    {player.current_club}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography style={titleCSS}>Previous Club(s)</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={subTitleCSS}>
                    {previousClubs(player.player_previous_club)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography style={titleCSS}>Rep Honor</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={subTitleCSS}>
                    {repHonour(player.player_rep_honour)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography style={titleCSS}>Passports</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={subTitleCSS}>
                    {passports(player.passports)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography style={titleCSS}>Price Range</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={subTitleCSS}>
                    {priceRange(player.price_low, player.price_high)}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </CardContent>
        </Collapse>
      </div>
    </Card>
  );
};

export default PlayerInfromation;
