import { Grid, Typography, Box, Paper, useTheme, useMediaQuery } from "@material-ui/core";

function AtheleteView({ profilesRef }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const title = {
    color: "#84ECF5",
    fontSize: 24,
    marginLeft: 20,
    marginTop: 20,
    fontFamily: "SemiBold",
  };

  const desc = {
    color: "white",
    fontSize: 18,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 15,
    paddingRight: 5,
    fontFamily: "Medium",
    lineHeight: 1.5,
    textAlign: "left",
  };

  const grid = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "8%",
  };

  const image = { width: 98, marginLeft: 20, marginTop: 20 };

  const paper = {
    background: "#101010",
    border: "0.8px #76EAF3 solid",
    borderRadius: 15,
  };

  return (
    <div
      style={{
        // background: "black",
        background:
          "radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(0,0,0,1) 35%)",
      }}
      ref={profilesRef}
    >
      <Grid container style={grid} direction={"column"} spacing={0}>
        <Typography
          style={{
            color: "#76EAF3",
            fontSize: isMobile ? 32 : 56,
            lineHeight: 1.3,
            fontFamily: "Medium",
            textAlign: "center",
          }}
        >
          Choose Your Profile,{<br />}Start Connecting.
        </Typography>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          maxWidth: "80%",
          margin: "0 auto",
        }}
      >
        <Grid container spacing={4} columns={12} style={{ marginTop: "2%" }}>
          <Grid item md={4}>
            <Paper style={paper}>
              <img src="/img/img_player.png" style={image} alt=""/>
              <Typography style={title}>Players</Typography>
              <Typography
                style={{
                  color: "white",
                  fontSize: 18,
                  marginLeft: 20,
                  marginTop: 20,
                  paddingBottom: 15,
                  paddingRight: 26,
                  fontFamily: "Medium",
                  lineHeight: 1.5,
                  textAlign: "left",
                }}
              >
                Our platform allows athletes to present themselves in the best
                possible light, making it easier for scouts, agents, and coaches
                to identify potential matches.
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={4}>
            <Paper style={paper}>
              <img src="/img/img_team.png" style={image} alt=""/>
              <Typography style={title}>Teams & Coaches</Typography>
              <Typography style={desc}>
                Talentlockr provides a collaborative environment where teams and
                coaches can connect with athletes, share insights, and
                collaborate on opportunities.
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={4}>
            <Paper style={paper}>
              <img src="/img/img_agent.png" style={image} alt=""/>
              <Typography style={title}>Agents & Scouts</Typography>
              <Typography style={desc}>
                The app offers detailed analytics, performance metrics, and
                video highlights, allowing agents and scouts to make informed
                decisions and discover the next big stars in sports
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default AtheleteView;
