import {
  Badge,
  Container,
  Grid,
  List,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page from "../../components/Page";
import { app } from "../../services/Firebase";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDatabase, onValue, ref } from "firebase/database";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import Navbar from "../../layouts/Navigation";
import ConversationItem from "../../components/ConversationItem";
import { BiSolidUserAccount } from "react-icons/bi";
import AppColor from "../../utils/color";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTES } from "../../constants/site-routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  chatSection: {
    marginTop: 30,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
}));

export default function Messages() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const db = getDatabase(app);
  const user = useSelector((state) => state.authorize);
  const [conversations, setConversations] = useState(null);
  const navigate = useNavigate();
  const [requestCount, setRequestCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    const conversationQuery = ref(db, `conversations/${user.id}`);
    return onValue(
      conversationQuery,
      (snapshot) => {
        setLoading(false);
        var dataArray = [];
        // snapshot.forEach(function(childSnapshot) {
        //   var childKey = childSnapshot.key;
        //   var childData = childSnapshot.val();
        //   dataArray.push({ key: childKey, data: childData });
        // });
        // dataArray.reverse();

        snapshot.forEach(function (childSnapshot) {
          var childData = childSnapshot.val();
          if (
            childData.conversationStatus === 0 &&
            user.id + 1 === childData.receiverStatus
          ) {
            setRequestCount((prev) => prev + 1);
          }
          dataArray.push(childData);
        });
        setConversations(
          [...dataArray].sort((a, b) => b["created_at"] - a["created_at"])
        );
      },
      {
        onlyOnce: true,
      }
    );
  }, [db, user.id]);

  const handleMessageRequestClick = () => {
    navigate(HOME_ROUTES.MESSAGE_REQUESTS);
  };

  return (
    <Page className={classes.root} title="Messages" backgroundColor="#ffffff">
      {loading && <SimpleBackdrop />}
      <Navbar />
      <Container className={classes.container}>
        <div
          style={{
            float: "right",
            fontSize: 20,
            backgroundColor:
              requestCount < 1
                ? AppColor.newTextFieldBackgroundColor
                : AppColor.defaultBgColor,
            padding: 5,
            borderRadius: 20,
            cursor: "pointer",
          }}
        >
          <Badge
            badgeContent={requestCount}
            color="error"
            overlap="rectangular"
          >
            <BiSolidUserAccount onClick={handleMessageRequestClick} />
          </Badge>
        </div>

        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            fontFamily: "Noventa812, sans-serif",
          }}
        >
          Messages
        </Typography>

        <Typography
          style={{
            fontFamily: "SemiBold",
            fontSize: 14,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          Inbox
        </Typography>

        <Grid
          container
          component={Paper}
          className={classes.chatSection}
          elevation={0}
        >
          <Grid item xs={12}>
            <List className={classes.messageArea}>
              {conversations &&
                conversations.map((conversation, index) => (
                  <div key={index}>
                    <ConversationItem
                      conversation={conversation}
                      receiver_id={user.id}
                    />
                  </div>
                ))}

              {conversations && conversations.length < 1 && (
                <Typography
                  style={{
                    fontFamily: "Medium",
                    fontSize: 12,
                    textAlign: "center",
                    width: "100%",
                    marginTop: "20%",
                    marginBottom: "20%",
                  }}
                >
                  {" "}
                  No Available Chats
                </Typography>
              )}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
