import React, { useState } from "react";
import Page from "../../../components/Page";
import Navbar from "../../../layouts/Navigation";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Modal,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { makeStyles } from "@material-ui/styles";
import { postRecord } from "../../../services/Axios";
import { POST } from "../../../constants/ApiEndPoint";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTES } from "../../../constants/site-routes";
import { useSelector } from "react-redux";
import AppColor from "../../../utils/color";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 300,
  bgcolor: "white",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
  borderRadius: 8,
  textAlign: "center",
  padding: "16px",
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderRadius: "30px",
    width: "100%",
  },
  avatar: {
    width: "40px",
    height: "40px",
    marginRight: "10px",
    border: "2px solid #00bcd4",
  },
  textarea: {
    width: "100%",
    fontFamily: "Medium",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    borderRadius: 10,
    border: "0px solid #7CD1DC",
    resize: "none",
    paddingTop: 10,
  },
  buttonStyles: {
    marginTop: "16px",
    width: "100%",
    margin: "5px",
    textTransform: "none",
  },
}));
const DiscoverCreate = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authorize);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const [characters, setCharacters] = useState(0);
  const [type_id, setTypeId] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openProfileCompleteModal, setOpenProfileCompleteModal] = useState(false);
  const types = [
    {
      name: "Opportunities",
      id: 1,
    },
    {
      name: "Achievements",
      id: 2,
    },
    {
      name: "Events",
      id: 3,
    },
  ];

  const handlePostSubmit = async () => {
    if (content.length > 0 && type_id > 0) {
      if (user.is_profile_completed === "0") {
        setOpenProfileCompleteModal(true);
      } else {
        setLoading(true);
        const response = await postRecord(POST.CREATE, {
          content: content,
          type_id: type_id,
        });
        if (response && response.data) {
          setLoading(false);
          setOpenModal(true);
        }
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setContent("");
    navigate(HOME_ROUTES.DISCOVER);
  }

  const handleChange = (e) => {
    if (e.target.value.length > 350) {
      return;
    }
    setContent(e.target.value);
    setCharacters(e.target.value.length);
  };

  const handleTypeSelect = (id) => {
    setTypeId(id);
  };

  const handleCloseProfileCompleteModal = () => {
    setOpenProfileCompleteModal(false);
  }

  return (
    <>
      <Page className={classes.root} title="Discover" backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container} style={{ marginTop: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Noventa812, sans-serif",
                }}
              >
                Create Post
              </Typography>
            </Grid>
          </Grid>

          <Card style={{ padding: "10px", marginTop: "10px" }}>
            <div className={classes.inputContainer}>
              <Avatar
                alt="User Avatar"
                src={user.avatar_url}
                className={classes.avatar}
              />
              <Typography>{user.full_name}</Typography>
            </div>

            <TextareaAutosize
              minRows={5}
              variant="outlined"
              placeholder="Write something here..."
              value={content}
              className={classes.textarea}
              onChange={handleChange}
            />
            <Typography
              style={{ fontFamily: "Medium", textAlign: "left", color: "gray" }}
            >
              {characters}/350
            </Typography>

            <Typography
              style={{
                fontFamily: "Medium",
              }}
            >
              Please choose your post category
            </Typography>

            {types.map((type) => (
              <Card
                style={{
                  padding: "10px",
                  marginTop: "10px",
                  backgroundColor: type_id === type.id ? "#76ebf4" : "white",
                  cursor: "pointer",
                }}
                onClick={() => handleTypeSelect(type.id)}
              >
                <Grid>
                  <Typography
                    style={{
                      fontFamily: "Medium",
                    }}
                  >
                    {type.name}
                  </Typography>
                </Grid>
              </Card>
            ))}

            <div style={{ marginTop: 15 }}></div>
            <CustomPrimaryButton text="Post" handleClick={handlePostSubmit} />
          </Card>
        </Container>

        <Modal
          open={openProfileCompleteModal}
          onClose={handleCloseProfileCompleteModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={modalStyles}>
            <Typography
              id="modal-title"
              variant="h4"
              component="h2"
              style={{ fontSize: 14, fontWeight: "500" }}
            >
              Please complete your profile at first.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyles}
              disableElevation
              onClick={handleCloseProfileCompleteModal}
            >
              Ok
            </Button>
          </Box>
        </Modal>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={modalStyles}>
            <Typography
              id="modal-title"
              variant="h4"
              component="h2"
              style={{ fontSize: 14, fontWeight: "500" }}
            >
              Your post have been submitted for review and will be visisible once it has been approved.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyles}
              disableElevation
              onClick={handleCloseModal}
            >
              Ok
            </Button>
          </Box>
        </Modal>
      </Page>
    </>
  );
};

export default DiscoverCreate;
