import React from "react";
import { Card, Typography, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTES } from "../constants/site-routes";
import AppColor from "../utils/color";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    overflow: "hidden",
    marginTop: 15,
    marginBottom: 15,
    cursor: "pointer",
  },

  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    zIndex: 1,
  },
  content: {
    position: "relative",
    zIndex: 2,
    height: 140,
  },
  overlayText: {
    textTransform: "uppercase",
    fontFamily: "Bold",
    color: "white",
    textAlign: "center",
  },
  cardImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  },

  cardImageGray: {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Ensure the image covers the entire space
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    filter: "grayscale(100%)",
  },
}));

const ImageBox = ({
  category,
  user,
  showLoginModal,
  showProfileNotCompleteModel,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (name, is_comming_soon) => {
    if (user && !is_comming_soon && user.is_profile_completed !== "0") {
      navigate(HOME_ROUTES.MEMBERS + "/" + encodeURIComponent(name));
    } else if (user && user.is_profile_completed === "0" && !is_comming_soon) {
      showProfileNotCompleteModel();
    } else {
      showLoginModal();
    }
  };

  return (
    <Card
      className={classes.card}
      onClick={() => handleClick(category.name, category.is_comming_soon)}
    >
      <img
        src={category.image}
        alt="background"
        className={
          category.is_comming_soon ? classes.cardImageGray : classes.cardImage
        }
      />
      <div className={classes.overlay}>
        <Typography className={classes.overlayText}>{category.name}</Typography>
        <hr
          style={
            category.is_comming_soon
              ? {
                  backgroundColor: AppColor.grayBackground,
                  width: 30,
                  height: 2,
                  borderRadius: 2,
                  borderColor: AppColor.grayBackground,
                }
              : {
                  backgroundColor: AppColor.defaultBgColor,
                  width: 30,
                  height: 2,
                  borderRadius: 2,
                  borderColor: AppColor.defaultBgColor,
                }
          }
        />
      </div>
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h5" component="h2"></Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
        ></Typography>
      </CardContent>
    </Card>
  );
};

export default ImageBox;
