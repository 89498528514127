import React, { useState, useRef, useEffect } from 'react';
import Page from '../../../../components/Page';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core';
import { HOME_ROUTES } from '../../../../constants/site-routes';
import CustomPrimaryButton from '../../../../components/Common/CustomPrimaryButton';
import Logo from '../../../../components/Common/Logo';
import CustomSecondaryButton from '../../../../components/Common/CustomSecondaryButton';
import { updatePlayerStep1 } from '../../../../stores/actions/Authorize';
import SimpleBackdrop from '../../../../components/Common/SimpleBackdrop';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import { fetchRecord } from '../../../../services/Axios';
import { PLAYERS } from '../../../../constants/ApiEndPoint';
import Notify from '../../../../components/Common/Notify';
import { useNavigate } from 'react-router-dom';
import AppColor from '../../../../utils/color';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '50px',
  },
  subHeading: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  topBox: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
    [theme.breakpoints.up('md')]: {
      height: '200px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '30vh',
    },
  },
  bottomBox: {
    backgroundColor: theme.palette.btnColors.white,
    borderRadius: '20px 20px 0px 0px',
    paddingBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      height: '70vh',
    },
  },
  fromBlock: {
    margin: '10px auto',
    width: '40%',
  },
  subTopBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '40px',
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '90px',
    },
  },
  textField: {
    width: '100%',
    marginBottom: '50px',
  },
  highlightIcon: {
    marginLeft: -50,
  },
}));

const ProfileStep1 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const imageRef = useRef(null);
  const highlightRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedHighlight, setSelectedHighlight] = useState(null);
  const [firstVideo, setFirstVideo] = useState('');
  const [secondVideo, setSecondVideo] = useState('');
  const [image, setImage] = useState('');
  const [highlight, setHighlight] = useState('');
  const user = useSelector((state) => state.authorize);
  const [formData, setFormData] = useState({});

  async function handleSaveAndContinue() {
    let data = { ...formData, video_links: [firstVideo, secondVideo] };

    if (image) {
      data = { ...data, photo_1: image };
    }

    if (highlight) {
      data = { ...data, photo_2: highlight };
    }

    setLoading(true);
    let response = await dispatch(updatePlayerStep1(data));
    setLoading(false);
    if (response.status === 200) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setInterval(() => {
        window.location.href = HOME_ROUTES.PROFILE_STEP_2;
      }, 2000);
    }
  }

  const handleBack = async () => {
    navigate(HOME_ROUTES.PROFILE);
  };

  const handleOpen = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
      setImage(file);
    }
  };

  const clearImage = () => {
    setSelectedImage(null);
  };

  const handleOpenHighlight = () => {
    if (highlightRef.current) {
      highlightRef.current.click();
    }
  };

  const handleHighlightChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedHighlight(e.target.result);
      };
      reader.readAsDataURL(file);
      setHighlight(file);
    }
  };

  const clearHighlight = () => {
    setSelectedHighlight(null);
  };

  const fetchPlayerProfile = async () => {
    setLoading(true);
    const response = await fetchRecord(PLAYERS.PROFILE + '/' + user.id);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      const media = response.data.media;
      const images = [];
      const videos = [];
      media.forEach((item) => {
        if (item.is_photo) {
          images.push(item.media_url);
        } else {
          videos.push(item.media_url);
        }
      });

      if (images.length > 0) {
        setSelectedImage(images[0]);
      }
      if (images.length > 1) {
        setSelectedHighlight(images[1]);
      }
      if (videos.length > 0) {
        setFirstVideo(videos[0]);
      }
      if (videos.length > 1) {
        setSecondVideo(videos[1]);
      }

      const passports = [];
      response.data.passports.forEach((item) => {
        passports.push(item.passport_name);
      });
      setFormData({
        date_of_birth: response.data.date_of_birth,
        location: response.data.location,
        latitude: response.data.latitude,
        longitude: response.data.longitude,
        passports: passports,
        sport: response.data.sport,
        weight: response.data.weight,
        height: response.data.height,
        gender: response.data.gender,
        price_low: response.data.price_low,
        price_high: response.data.price_high,
        name: response.data.name,
      });
    }
  };

  useEffect(() => {
    fetchPlayerProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Page className={classes.root} title="Portfolio Pictures & Videos">
        {loading && <SimpleBackdrop />}
        <Notify />
        <Box className={classes.topBox}>
          <Box className={classes.subTopBox}>
            <Logo />
          </Box>
        </Box>
        <Box className={classes.bottomBox}>
          <Box className={classes.fromBlock}>
            <Box
              className={classes.textFieldBlock}
              style={{ paddingTop: '50px' }}
            >
              <Typography
                style={{ fontSize: 20, fontWeight: '500' }}
                variant="h2"
                gutterBottom
              >
                Add Portfolio Pictures & Videos
              </Typography>
              <Typography style={{ color: AppColor.textColor, fontSize: 13 }}>
                Please add a minimun of 1 photo and 1 highlight clip to showcase
                your talent. Highlights are crucial part to being recurited.
              </Typography>
              <Grid container spacing={0} style={{ marginTop: 20 }}>
                <Grid container item xs={3}>
                  {selectedImage ? (
                    <div style={{ position: 'relative' }}>
                      <img
                        src={selectedImage}
                        alt="Selected"
                        width="80px"
                        height="80px"
                        style={{ borderRadius: '20px' }}
                      />
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          padding: '2px',
                          backgroundColor: 'gray',
                          borderRadius: '0 20px 0 0px',
                        }}
                        onClick={clearImage}
                      >
                        <Close />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={handleOpen}
                      style={{
                        color: '#76ebf4',
                        padding: '0px',
                        borderRadius: '10px',
                        border: '#76ebf4 solid 1px',
                        height: 80,
                        width: 80,
                        backgroundColor: '#e3fbfc',
                      }}
                    >
                      <img
                        alt="photocamera"
                        src="/images/profile.png"
                        style={{ height: 30, width: 30 }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid container item xs={3}>
                  {selectedHighlight ? (
                    <div
                      className={classes.highlightIcon}
                      style={{ position: 'relative' }}
                    >
                      <img
                        src={selectedHighlight}
                        alt="Selected"
                        width="80px"
                        height="80px"
                        style={{ borderRadius: '20px' }}
                      />
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          padding: '2px',
                          backgroundColor: 'gray',
                          borderRadius: '0 20px 0 0px',
                        }}
                        onClick={clearHighlight}
                      >
                        <Close />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={handleOpenHighlight}
                      className={classes.highlightIcon}
                      style={{
                        color: '#76ebf4',
                        padding: '0px',
                        borderRadius: '10px',
                        border: '#76ebf4 solid 1px',
                        height: 80,
                        width: 80,
                        backgroundColor: '#e3fbfc',
                      }}
                    >
                      <img
                        alt="photocamera"
                        src="/images/profile.png"
                        style={{ height: 30, width: 30 }}
                      />
                    </IconButton>
                  )}
                </Grid>

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="imageInput"
                  ref={imageRef}
                />

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleHighlightChange}
                  style={{ display: 'none' }}
                  id="highlightInput"
                  ref={highlightRef}
                />
              </Grid>
              <Typography
                style={{
                  color: 'rgb(122 114 114)',
                  fontSize: 14,
                  marginTop: 35,
                }}
              >
                Add URL of your videos
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Enter URL of Video"
                className={classes.textField}
                value={firstVideo}
                onChange={(e) => setFirstVideo(e.target.value)}
              />
              <TextField
                variant="outlined"
                placeholder="Enter URL of Video"
                className={classes.textField}
                value={secondVideo}
                onChange={(e) => setSecondVideo(e.target.value)}
              />
              <CustomPrimaryButton
                text="Save and Continue"
                handleClick={handleSaveAndContinue}
              />

              <div style={{ marginTop: 50 }}>
                <CustomSecondaryButton
                  text="Go Back"
                  handleClick={handleBack}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default ProfileStep1;
