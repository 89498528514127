import { Grid, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

function Mobile() {
  const styles = {
    main: {
      backgroundImage:
        "linear-gradient(to left bottom, #050708, #0b1214, #0f191b, #102021, #122727)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    overlayImage: {
      position: "absolute",
      top: "-20%",
      left: "18%",
      width: "70%",
      height: "auto",
      display: "block",
      opacity: 1,
    },
    backgroundImage: {
      width: "70%",
      height: "auto",
      display: "block",
      marginTop: "-15%",
      marginLeft: "19%",
    },
    downloadText: {
      fontFamily: "SemiBold",
      fontSize: 40,
      lineHeight: "auto",
      color: "white",
      marginTop: "5%",
    },
    desc: {
      fontFamily: "Medium",
      fontSize: 18,
      lineHeight: "1.6",
      color: "white",
      paddingLeft: "15%",
      paddingRight: "15%",
    },
    centerAlign: {
      background: "transparent",
      textAlign: "center",
    },

    transparent: {
      background: "transparent",
    },

    imageContainer: {
      background: "transparent",
      paddingBottom: "20%",
    },
  };

  const transparent = {
    background: "transparent",
  };

  const fullWidth = {
    width: "100%",
  };

  return (
    <div style={styles.main}>
      <div style={styles.container}>
        <div>
          <Grid container spacing={0} style={{ position: "relative" }}>
            <Grid item xs={12} style={styles.fullWidth}>
              <Paper style={styles.imageContainer} elevation={0}>
                <img
                  src="/images/logo_w.png"
                  alt=""
                  style={styles.overlayImage}
                />
                <img
                  src="/images/ec1.png"
                  alt=""
                  style={styles.backgroundImage}
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={0} justify="center">
            <Grid item style={{ ...fullWidth, marginTop: "10%" }} xs={12}>
              <Paper
                style={{ ...transparent, textAlign: "center" }}
                elevation={0}
              >
                <img src="/images/circular_download.png" width={85} alt=""/>
                <Typography style={styles.downloadText}>
                  Download App
                </Typography>
                <Typography style={styles.desc}>
                  For better experience please download our mobile app.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ width: "100%", marginTop: 30 }}>
              <Paper style={styles.centerAlign} elevation={0}>
                <Link to="https://play.google.com/store/apps/details?id=io.everestapps.talentlockrapp&pli=1">
                  <img src="/images/1.png" width={190} alt=""/>
                </Link>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ ...fullWidth, marginTop: 15 }}>
              <Paper style={styles.centerAlign} elevation={0}>
                <Link to="https://apps.apple.com/us/app/talent-lockr/id6444275012">
                  <img src="/images/2.png" width={190} alt="" />
                </Link>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Mobile;