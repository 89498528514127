import Axios from 'axios';
import Configs from '../configs/Configs';
import { GENERAL } from '../constants/ApiResponseFormat';
import { getAccessToken } from './Storage';

const API = Axios.create({
  baseURL: Configs.API_URI,
  timeout: 60000,
  headers: { 
    'x-api-key': Configs.API_KEY,
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache, no-store',
  }
});

API.interceptors.request.use( config => {
    config.headers['x-access-token'] = getAccessToken();
    return config;
  },
  e => Promise.reject(e)
);

export const exceptionHandler = e => {
  let responseContent = {};
  if (e.response) {
    responseContent = {
      status: e.response.status,
      message: e.response.data.message,
      data: e.response.data.payload
    };
  } else if (e.request) {
    responseContent = {
      status: 503,
      message: 'Service Unavailable',
      data: ''
    };
  } else {
    responseContent = {
      status: 500,
      message: e.message,
      data: ''
    };
  }
  return responseContent;
};

const fetchRecord = async ( relativeUri, pathParam1 = '', pathParam2 = '', queryParams = '') => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };
  try {
    if (pathParam1 !== '') {
      relativeUri = relativeUri + '/' + pathParam1;
    }
    if (pathParam2 !== '') {
      relativeUri = relativeUri + '/' + pathParam2;
    }
    if (queryParams) {
      relativeUri = relativeUri + '?' + queryParams;
    }
    let response = await API.get(relativeUri)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.payload,
          paginator: response.data?.paginator
        };

        return responseContent;
      })
      .catch(e => {
        return exceptionHandler(e);
      });
      return response;
  } catch (e) {
    return GENERAL;
  }
};

const postRecord = async (relativeUri, data) => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };

  try {
    let response = await API.post(relativeUri, data)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.payload
        };

        return responseContent;
      })
      .catch(e => {
        return exceptionHandler(e);
      });
    return response;
  } catch (e) {
    return exceptionHandler(e);
  }
};

const postRecordWithFile = async (relativeUri, data) => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };

  try {
    let response = await API.post(relativeUri, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.payload
        };

        return responseContent;
      })
      .catch(e => {
        return exceptionHandler(e);
      });
    return response;
  } catch (e) {
    return exceptionHandler(e);
  }
};

const patchRecord = async ( relativeUri, data, pathParam1 = '', pathParam2 = '', queryParams) => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };

  if (pathParam1 !== '') {
    relativeUri = relativeUri + '/' + pathParam1;
  }
  if (pathParam2 !== '') {
    relativeUri = relativeUri + '/' + pathParam2;
  }
  if (queryParams) {
    relativeUri = relativeUri + '?' + queryParams;
  }

  try {
    let response = await API.patch(relativeUri, data)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.payload
        };

        return responseContent;
      })
      .catch(e => {
        return exceptionHandler(e);
      });
    return response;
  } catch (e) {
    return exceptionHandler(e);
  }
};

const putRecord = async ( relativeUri, pathParam1 = '', pathParam2 = '', data) => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };

  try {
    if (pathParam1 !== '') {
      relativeUri = relativeUri + '/' + pathParam1;
    }
    if (pathParam2 !== '') {
      relativeUri = relativeUri + '/' + pathParam2;
    }
    let response = await API.put(relativeUri, data)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.payload
        };

        return responseContent;
      })
      .catch(e => {
        return exceptionHandler(e);
      });
    return response;
  } catch (e) {
    return exceptionHandler(e);
  }
};

const putRecordWithFile = async (relativeUri, data) => {
  let responseContent = {
    status: '',
    message: '',
    data: '',
  };

  try {
    let response = await API.put(relativeUri, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.payload,
        };

        return responseContent;
      })
      .catch((e) => {
        return exceptionHandler(e);
      });
    return response;
  } catch (e) {
    return exceptionHandler(e);
  }
};

const deleteRecord = async ( relativeUri, data, pathParam1 = '', pathParam2 = '') => {
  let responseContent = {
    status: '',
    message: '',
    data: ''
  };
  if (pathParam1 !== '') {
    relativeUri = relativeUri + '/' + pathParam1;
  }
  if (pathParam2 !== '') {
    relativeUri = relativeUri + '/' + pathParam2;
  }

  try {
    let response = await API.delete(relativeUri, data)
      .then(response => {
        responseContent = {
          status: response.status,
          message: response.data.message,
          data: response.data.payload
        };

        return responseContent;
      })
      .catch(e => {
        return exceptionHandler(e);
      });
    return response;
  } catch (e) {
    return exceptionHandler(e);
  }
};

export {
  API,
  fetchRecord,
  postRecord,
  postRecordWithFile,
  deleteRecord,
  patchRecord,
  putRecord,
  putRecordWithFile,
};
