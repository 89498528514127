import React, { useState } from 'react';
import { makeStyles, Card, CardContent, Typography, Avatar, Grid, IconButton } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { formatDistanceToNow } from 'date-fns';
import { postRecord } from '../../../../services/Axios';
import { POST } from '../../../../constants/ApiEndPoint';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTES } from '../../../../constants/site-routes';
import VerifiedPlayer from "../../../../components/VerifiedPlayer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 16,
    border: '1px solid #f0f0f0',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  username: {
    fontWeight: 600,
    cursor: 'pointer',
  },
  time: {
    color: theme.palette.grey[500],
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-around',
    marginTop: theme.spacing(1),
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
  },
  iconText: {
    marginLeft: theme.spacing(0.5),
  },
  achievementIcon: {
    color: theme.palette.primary.main,
  },
  emojiIcon: {
    color: '#FFC107',
  },
  mailIconContainer: {
    backgroundColor: '#00bcd4',
    padding: 3,
    borderRadius: '50%',
    display: 'inline-flex',
  },
  mailIcon: {
    color: '#ffffff',
  },
}));

const PostCard = ({post}) => {
  const classes = useStyles();
  const [hasLiked, setHasLiked] = useState(post.has_like ? true: false);
  const [likeCount, setLikeCount] = useState(post.like_count);
  const [hasClapped, setHasClapped] = useState(post.has_clap ? true: false);
  const [clapCount, setClapCount] = useState(post.clap_count);
  const navigate = useNavigate();

  const typeText = (type_id) => {
    switch (type_id) {
      case 1:
        return 'Opportunities';
      case 2:
        return 'Achievements';
      case 3:
        return 'Events';
      default:
        return 'General';
    }
  }

  const postedBy = (user) => {
    switch (user?.type) {
      case 1:
        return user.agent?.agency?.name;
      case 5:
        return user.school?.name;
      default:
        return user.full_name
    }
  }

  const handleContact = () => {
    navigate(HOME_ROUTES.MESSAGES + "/" + post.user?.id);
  };

  const formattedDate = formatDistanceToNow(new Date(post.created_at), { addSuffix: true });

  const likePost = () => {
    const like = hasLiked ? 0 : 1;
    setHasLiked(!hasLiked);
    setLikeCount(like ? likeCount + 1 : likeCount - 1);
    postRecord(POST.LIKE, { id: post.id, reaction_type_id: 1 });
  }

  const clapPost = () => {
    const clap = hasClapped ? 0 : 1;
    setHasClapped(!hasClapped);
    setClapCount(clap ? clapCount + 1 : clapCount - 1);
    postRecord(POST.LIKE, { id: post.id, reaction_type_id: 2 });
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.header}>
          <Avatar
            onClick={handleContact}
            alt="User Avatar"
            src={
              post.user?.user_player?.media?.length > 0
                ? post.user?.user_player?.media[0].media_url
                : post.user?.avatar_url
            }
            className={classes.avatar}
          />
          <div>
            <Typography onClick={handleContact} className={classes.username}>{postedBy(post.user)} { (post.user?.is_subscribe || post.user?.is_super_pro) && (<VerifiedPlayer player={post.user} />) }</Typography>
            <Typography variant="body2" className={classes.time}>
              {formattedDate} <span className={classes.achievementIcon}>{typeText(post.type_id)}</span>
            </Typography>
          </div>
        </div>

        <Typography variant="body1" className={classes.content}>
          {post.content}
        </Typography>

        <Grid container spacing={2} className={classes.icons}>
          <Grid item>
            <IconButton className={classes.iconButton}>
              {hasLiked ? (
                <ThumbUpIcon className={classes.achievementIcon} onClick={likePost} />
              ) : (
                <ThumbUpOutlinedIcon className={classes.achievementIcon} onClick={likePost} />
              )}
              <Typography className={classes.iconText}>{likeCount}</Typography>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton className={classes.iconButton}>
              <img src="/img/clap-icon.png" alt="clap" className={classes.emojiIcon} onClick={clapPost} width={24} height={24}/>
              <Typography className={classes.iconText}>{clapCount}</Typography>
            </IconButton>
          </Grid>
          <Grid item>
            <div className={classes.mailIconContainer}>
              <MailOutlineIcon className={classes.mailIcon} onClick={handleContact}/>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PostCard;
