/* eslint-disable import/no-anonymous-default-export */
import { SET_NOTIFY,CLEAR_NOTIFY } from "../../actions/Notify";
import {GENERAL} from "../../../constants/ApiResponseFormat";

let _body = GENERAL;
_body.message = 'Something went wrong.'
export default (state = null, { body, type }) => {
  Object.freeze(state);
  switch (type) {
    case SET_NOTIFY:
      return body ? body : _body;
    case CLEAR_NOTIFY:
      return null;
    default:
      return state;
  }
};
