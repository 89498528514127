/* eslint-disable no-undef */
import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

function Page({ title, children, backgroundColor = "white" }) {
  return (
    <div>
      <Helmet
        bodyAttributes={{
          style: `background-color : ${backgroundColor}; font-family: Bai Jamjuree, sans-serif`,
        }}
      >
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
