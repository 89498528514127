import { Box, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import CustomSecondaryButton from '../../components/Common/CustomSecondaryButton';
import { AUTH_ROUTES } from '../../constants/site-routes';
import Page from '../../components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function Page404() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(AUTH_ROUTES.HOME);
  };
  return (
    <Page className={classes.root} title="404 Page Not Found">
      <Container>
        <Box
          sx={{
            maxWidth: 480,
            margin: 'auto',
            textAlign: 'center',
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>
          <Typography>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography>

          <Box
            component="img"
            src="/images/illustration_404.svg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <CustomSecondaryButton
            text="Go to Home"
            to="/"
            handleClick={handleClick}
            variant="contained"
          />
        </Box>
      </Container>
    </Page>
  );
}
