import React, { useRef } from "react";
import "./index.css";
import "./fonts/BaiJamjuree-Regular.ttf";
import ApppBar from "./AppBar";
import DownloadScreen from "./DownloadScreen";
import ChooseProfile from "./ChooseProfile";
import AtheleteView from "./AtheleteView";
import ConnectCustomize from "./ConnectCustomize";
import PerfectMatch from "./PerfectMatch";
import Discover from "./Discover";
import BottomView from "./BottomView";
import AtheleteInfo from "./AtheleInfo";
import Page from "../../components/Page";
import FooterView from "./FooterView";

const GetStarted = () => {
  const profilesRef = useRef(null);
  const featuresRef = useRef(null);
  const testimonialsRef = useRef(null);

  const scrollToProfiles = () => {
    const yOffset = -100; // Adjust this value as needed
    const y = profilesRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const scrollToFeatures = () => {
    const yOffset = -100; // Adjust this value as needed
    const y = featuresRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const scrollToTestimonials = () => {
    const yOffset = -100; // Adjust this value as needed
    const y = testimonialsRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };
  return (
    <>
      <Page backgroundColor="black" title="Get Started" >
        <ApppBar scrollToProfiles={scrollToProfiles} scrollToFeatures={scrollToFeatures} scrollToTestimonials={scrollToTestimonials} />
        <DownloadScreen />
        <ChooseProfile />
        <AtheleteView  profilesRef={profilesRef}/>
        <AtheleteInfo />
        <ConnectCustomize featuresRef={featuresRef}/>
        <PerfectMatch />
        <Discover testimonialsRef={testimonialsRef}/>
        <BottomView />
        <FooterView />
      </Page>
    </>
  );
};

export default GetStarted;
