import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  Select,
  Slider,
  Typography,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CustomPrimaryButton from "./Common/CustomPrimaryButton";
import CustomSecondaryButton from "./Common/CustomSecondaryButton";
import AppColor from "../utils/color";
import { fetchRecord } from "../services/Axios";
import { COUNTRY, POSITION } from "../constants/ApiEndPoint";

const useStyles = makeStyles((theme) => ({
  radioButton: {
    marginBottom: "30px",
    color: "rgb(122 114 114)",
    fontSize: 14,
  },

  memberLabel: {
    marginTop: 10,
    marginLeft: 10,
    fontSize: 14,
    fontFamily: "Medium",
    color: AppColor.newTextColor,
  },

  labelPlacementStart: {
    marginLeft: 0,
  },

  title: { fontFamily: "Bold", fontSize: 20 },

  memberTitleGrid: {
    textAlign: "left",
    background: "transparent",
  },

  checkedIconGrid: {
    textAlign: "right",
    background: "transparent",
  },

  price: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: -30,
  },

  slider: {
    "& .MuiSlider-markLabel": { fontFamily: "Medium", fontSize: "12px" },
  },
}));

const FilterModal = ({
  open,
  openSort,
  handleClose,
  handleCloseSort,
  sport,
  handelFilter,
}) => {
  const modalStyles = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -10%)",
    width: "90%",
    maxWidth: 550,
    bgcolor: "white",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
    borderRadius: 8,
    padding: "16px",
  };
  const classes = useStyles();
  const [openMembers, setOpenMembers] = useState(true);
  const [openPrice, setOpenPrice] = useState(false);
  const [openPosition, setOpenPosition] = useState(false);
  const [openLookingFor, setOpenLookingFor] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openWeight, setOpenWeight] = useState(false);
  const [openHeight, setOpenHeight] = useState(false);
  const [openGender, setOpenGender] = useState(false);
  const [openAge, setOpenAge] = useState(false);
  const [amateur, setAmature] = useState(false);
  const [overseas, setOverseas] = useState(false);
  const [professional, setProfessional] = useState(false);
  const [countries, setCountries] = useState([]);

  const [member_type, setMemberType] = useState(null);
  const [lower_price, setLowerPrice] = useState(0);
  const [higher_price, setHigherPrice] = useState(100000);
  const priceMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 100000,
      label: "100K",
    },
  ];
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const handlePriceChange = (event, newValues) => {
    event.preventDefault();
    setPriceRange(newValues);
    setLowerPrice(newValues[0]);
    setHigherPrice(newValues[1]);
  };

  const [positionLists, setPositionLists] = useState([]);
  const [positions, setPositions] = useState([]);
  const [passports, setPassports] = useState([]);
  const [want_contracts, setWantContracts] = useState([]);
  const [lower_height, setLowerHeight] = useState(0);
  const [higher_height, setHigherHeight] = useState(250);
  const heightMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 250,
      label: "250",
    },
  ];
  const [heightRange, setHeightRange] = useState([0, 100000]);
  const handleHeightChange = (event, newValues) => {
    event.preventDefault();
    setHeightRange(newValues);
    setLowerHeight(newValues[0]);
    setHigherHeight(newValues[1]);
  };

  const [lower_weight, setLowerWeight] = useState(0);
  const [higher_weight, setHigherWeight] = useState(250);
  const weightMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 250,
      label: "250",
    },
  ];
  const [weightRange, setWeightRange] = useState([0, 100000]);
  const handleWeightChange = (event, newValues) => {
    event.preventDefault();
    setWeightRange(newValues);
    setLowerWeight(newValues[0]);
    setHigherWeight(newValues[1]);
  };

  const [gender, setGender] = useState("0");

  const [lower_age, setLowerAge] = useState(0);
  const [higher_age, setHigherAge] = useState(50);
  const ageMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 50,
      label: "50",
    },
  ];
  const [ageRange, setAgeRange] = useState([0, 100000]);
  const handleAgeChange = (event, newValues) => {
    event.preventDefault();
    setAgeRange(newValues);
    setLowerAge(newValues[0]);
    setHigherAge(newValues[1]);
  };
  const [sort, setSort] = useState(1);

  const handleChange = (id) => {
    setMemberType(parseInt(id));
  };

  const handleContractChange = async (value) => {
    const index = want_contracts.indexOf(value);
    if (index === -1) {
      want_contracts.push(value);
      setWantContracts(want_contracts);
    } else {
      want_contracts.splice(index, 1);
      setWantContracts(want_contracts);
    }
    setAmature(false);
    setOverseas(false);
    setProfessional(false);
    if (want_contracts.indexOf(1) > -1) {
      setAmature(true);
    }
    if (want_contracts.indexOf(2) > -1) {
      setOverseas(true);
    }
    if (want_contracts.indexOf(3) > -1) {
      setProfessional(true);
    }
  };

  const handlePositionChange = (event) => {
    setPositions(event.target.value);
  };

  const clearFilters = () => {
    setMemberType(null);
    setLowerPrice(0);
    setHigherPrice(100000);
    setPositions([]);
    setPassports([]);
    setWantContracts([]);
    setLowerHeight(0);
    setHigherHeight(250);
    setLowerWeight(0);
    setHigherWeight(250);
    setLowerAge(0);
    setHigherAge(50);
    handelFilter({});
    handleClose();
    handleCloseSort();
  };

  const applyFilters = () => {
    handleClose();
    handleCloseSort();
    if (member_type === 4 || member_type === 5) {
      handelFilter({
        useFilter: true,
        member_type,
        lower_price,
        higher_price,
        positions,
        passports,
        want_contracts,
        lower_height,
        higher_height,
        lower_weight,
        higher_weight,
        lower_age,
        higher_age,
        sort,
        gender,
      });
    } else if (member_type === 3) {
      handelFilter({
        useFilter: true,
        member_type,
        want_contracts,
        sort,
      });
    } else if (member_type) {
      setSort(1);
      handelFilter({ useFilter: true, member_type, sort: 1 });
    } else {
      handelFilter({ useFilter: false });
    }
  };

  const fetchProfile = async () => {
    const res = await fetchRecord(POSITION.LIST, "", "", "sport=" + sport);
    if (res && res.data && res.status === 200) {
      const positions = [];
      res.data.forEach((position) => {
        positions.push({ id: position.id, name: position.name });
      });
      setPositionLists(positions);
    }
  };

  const fetchCountries = async () => {
    const response = await fetchRecord(COUNTRY.LIST);
    if (response && response.data && response.status === 200) {
      setCountries(response.data);
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ////csss
  const card = {
    marginTop: 10,
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
  };

  const clearAll = {
    marginTop: 30,
    marginBottom: 30,
  };

  const memeberCss = {
    ontFamily: "SemiBold",
    fontSize: 20,
  };

  const memberGridCss = {
    borderRadius: 4,
    cursor: "pointer",
    marginTop: 5,
  };

  return (
    <>
      {!openSort ? (
        <Modal open={open} onClose={handleClose} style={{ overflow: "scroll" }}>
          <Box sx={modalStyles}>
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              Filters
            </Typography>
            <Box>
              <Card style={card} elevation={0}>
                <CardHeader
                  title={<Typography style={memeberCss}>Members</Typography>}
                  action={
                    <IconButton
                      onClick={() => setOpenMembers(!openMembers)}
                      aria-label="expand"
                      size="small"
                    >
                      {openMembers ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  }
                ></CardHeader>
                <div>
                  <Collapse in={openMembers} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Grid
                        value={1}
                        container
                        spacing={2}
                        style={{
                          background:
                            member_type === 1 ? "#2F2D2E" : "transparent",
                          ...memberGridCss,
                        }}
                        onClick={(e) => handleChange(1)}
                      >
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.memberTitleGrid}
                          >
                            <Typography
                              style={{
                                fontFamily: "Medium",
                                color:
                                  member_type === 1
                                    ? "white"
                                    : AppColor.newTextColor,
                              }}
                            >
                              Agents
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.checkedIconGrid}
                          >
                            {member_type === 1 && (
                              <img
                                src="/icons/img_new_check_circle.png"
                                width={20}
                                alt=""
                              />
                            )}
                          </Paper>
                        </Grid>
                      </Grid>

                      <Grid
                        value={1}
                        container
                        spacing={2}
                        style={{
                          background:
                            member_type === 2 ? "#2F2D2E" : "transparent",
                          ...memberGridCss,
                        }}
                        onClick={(e) => handleChange(2)}
                      >
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.memberTitleGrid}
                          >
                            <Typography
                              style={{
                                fontFamily: "Medium",
                                color:
                                  member_type === 2
                                    ? "white"
                                    : AppColor.newTextColor,
                              }}
                            >
                              Team
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.checkedIconGrid}
                          >
                            {member_type === 2 && (
                              <img
                                src="/icons/img_new_check_circle.png"
                                width={20}
                                alt=""
                              />
                            )}
                          </Paper>
                        </Grid>
                      </Grid>

                      <Grid
                        value={1}
                        container
                        spacing={2}
                        style={{
                          background:
                            member_type === 3 ? "#2F2D2E" : "transparent",
                          ...memberGridCss,
                        }}
                        onClick={(e) => handleChange(3)}
                      >
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.memberTitleGrid}
                          >
                            <Typography
                              style={{
                                fontFamily: "Medium",
                                color:
                                  member_type === 3
                                    ? "white"
                                    : AppColor.newTextColor,
                              }}
                            >
                              Coach
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.checkedIconGrid}
                          >
                            {member_type === 3 && (
                              <img
                                src="/icons/img_new_check_circle.png"
                                width={20}
                                alt=""
                              />
                            )}
                          </Paper>
                        </Grid>
                      </Grid>

                      <Grid
                        value={1}
                        container
                        spacing={2}
                        style={{
                          background:
                            member_type === 4 ? "#2F2D2E" : "transparent",
                          ...memberGridCss,
                        }}
                        onClick={(e) => handleChange(4)}
                      >
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.memberTitleGrid}
                          >
                            <Typography
                              style={{
                                fontFamily: "Medium",
                                color:
                                  member_type === 4
                                    ? "white"
                                    : AppColor.newTextColor,
                              }}
                            >
                              Players
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.checkedIconGrid}
                          >
                            {member_type === 4 && (
                              <img
                                src="/icons/img_new_check_circle.png"
                                width={20}
                                alt=""
                              />
                            )}
                          </Paper>
                        </Grid>
                      </Grid>

                      <Grid
                        value={1}
                        container
                        spacing={2}
                        style={{
                          background:
                            member_type === 5 ? "#2F2D2E" : "transparent",
                          ...memberGridCss,
                        }}
                        onClick={(e) => handleChange(5)}
                      >
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.memberTitleGrid}
                          >
                            <Typography
                              style={{
                                fontFamily: "Medium",
                                color:
                                  member_type === 5
                                    ? "white"
                                    : AppColor.newTextColor,
                              }}
                            >
                              Players Without Agents
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.checkedIconGrid}
                          >
                            {member_type === 5 && (
                              <img
                                src="/icons/img_new_check_circle.png"
                                width={20}
                                alt=""
                              />
                            )}
                          </Paper>
                        </Grid>
                      </Grid>

                      {/* <Grid container spacing={0}>
                        <Grid item lg={1} xs={1} md={1}>
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            labelPlacement="start"
                            className={classes.radioButton}
                            onChange={handleChange}
                            checked={member_type === 2}
                            classes={{
                              labelPlacementStart: classes.labelPlacementStart,
                            }}
                          />
                        </Grid>
                        <Grid item lg={11} xs={11} md={11}>
                          <Typography className={classes.memberLabel}>
                            Team
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item lg={1} xs={1} md={1}>
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            labelPlacement="start"
                            className={classes.radioButton}
                            onChange={handleChange}
                            checked={member_type === 3}
                            classes={{
                              labelPlacementStart: classes.labelPlacementStart,
                            }}
                          />
                        </Grid>
                        <Grid item lg={11} xs={11} md={11}>
                          <Typography className={classes.memberLabel}>
                            Coach
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item lg={1} xs={1} md={1}>
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            labelPlacement="start"
                            className={classes.radioButton}
                            onChange={handleChange}
                            checked={member_type === 4}
                            classes={{
                              labelPlacementStart: classes.labelPlacementStart,
                            }}
                          />
                        </Grid>
                        <Grid item lg={11} xs={11} md={11}>
                          <Typography className={classes.memberLabel}>
                            Players
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item lg={1} xs={1} md={1}>
                          <FormControlLabel
                            value="5"
                            control={<Radio />}
                            labelPlacement="start"
                            className={classes.radioButton}
                            onChange={handleChange}
                            checked={member_type === 5}
                            classes={{
                              labelPlacementStart: classes.labelPlacementStart,
                            }}
                          />
                        </Grid>
                        <Grid item lg={11} xs={11} md={11}>
                          <Typography className={classes.memberLabel}>
                            Players Without Agents
                          </Typography>
                        </Grid>
                      </Grid> */}
                    </CardContent>
                  </Collapse>
                </div>
              </Card>

              {(member_type === 4 || member_type === 5) && (
                <Box>
                  <Card style={card} elevation={0}>
                    <CardHeader
                      title={
                        <Typography className={classes.title}>
                          Price (AUD)
                        </Typography>
                      }
                      action={
                        <IconButton
                          onClick={() => setOpenPrice(!openPrice)}
                          aria-label="expand"
                          size="small"
                        >
                          {openPrice ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      }
                    ></CardHeader>
                    <div>
                      <Collapse in={openPrice} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Grid
                            container
                            spacing={10}
                            className={classes.price}
                          >
                            <Grid item xs>
                              <Slider
                                value={priceRange}
                                onChange={handlePriceChange}
                                aria-labelledby="range-slider"
                                min={0}
                                max={100000}
                                marks={priceMarks}
                                step={5}
                                valueLabelDisplay="on"
                                className={classes.slider}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Collapse>
                    </div>
                  </Card>

                  <Card style={card} elevation={0}>
                    <CardHeader
                      title={
                        <Typography className={classes.title}>
                          Position
                        </Typography>
                      }
                      action={
                        <IconButton
                          onClick={() => setOpenPosition(!openPosition)}
                          aria-label="expand"
                          size="small"
                        >
                          {openPosition ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      }
                    ></CardHeader>
                    <div>
                      <Collapse in={openPosition} timeout="auto" unmountOnExit>
                        <CardContent>
                          <FormControl
                            style={{
                              width: "100%",
                              marginBottom: 20,
                              border: "1px solid #7CD1DC",
                              borderRadius: "4px",
                              backgroundColor: AppColor.fieldBackground,
                              marginTop: 4,
                            }}
                          >
                            <InputLabel
                              id="multiple-select-label"
                              style={{ marginLeft: 6, marginBottom: 14 }}
                            >
                              Select Positions
                            </InputLabel>
                            <Select
                              disableUnderline
                              labelId="multiple-select-label"
                              id="multiple-select"
                              multiple
                              value={positions}
                              onChange={handlePositionChange}
                              label="Select Positions"
                              renderValue={(selected) => (
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={value}
                                      label={value}
                                      style={{ margin: 2 }}
                                    />
                                  ))}
                                </div>
                              )}
                            >
                              {positionLists.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </CardContent>
                      </Collapse>
                    </div>
                  </Card>
                </Box>
              )}

              {(member_type === 4 ||
                member_type === 3 ||
                member_type === 5) && (
                <Card style={card} elevation={0}>
                  <CardHeader
                    title={
                      <Typography className={classes.title}>
                        Looking For
                      </Typography>
                    }
                    action={
                      <IconButton
                        onClick={() => setOpenLookingFor(!openLookingFor)}
                        aria-label="expand"
                        size="small"
                      >
                        {openLookingFor ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    }
                  ></CardHeader>
                  <div>
                    <Collapse in={openLookingFor} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Grid
                          container
                          spacing={1}
                          style={{ marginBottom: 50 }}
                        >
                          <Grid container item xs={4}>
                            <Button
                              disableElevation
                              variant="contained"
                              className={
                                amateur
                                  ? "custom-btn-secondary-selected"
                                  : "custom-btn-secondary"
                              }
                              onClick={() => handleContractChange(1)}
                              style={{
                                textTransform: "none",
                                fontWeight: "100",
                              }}
                            >
                              Semi Pro
                            </Button>
                          </Grid>
                          <Grid container item xs={4}>
                            <Button
                              disableElevation
                              variant="contained"
                              className={
                                overseas
                                  ? "custom-btn-secondary-selected"
                                  : "custom-btn-secondary"
                              }
                              onClick={() => handleContractChange(2)}
                              style={{ textTransform: "none" }}
                            >
                              Overseas
                            </Button>
                          </Grid>

                          <Grid container item xs={4}>
                            <Button
                              disableElevation
                              variant="contained"
                              className={
                                professional
                                  ? "custom-btn-secondary-selected"
                                  : "custom-btn-secondary"
                              }
                              onClick={() => handleContractChange(3)}
                              style={{
                                textTransform: "none",
                                fontWeight: "100",
                              }}
                            >
                              Professional Contract
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Collapse>
                  </div>
                </Card>
              )}

              {(member_type === 4 || member_type === 5) && (
                <Box>
                  <Card style={card} elevation={0}>
                    <CardHeader
                      title={
                        <Typography className={classes.title}>
                          Passports
                        </Typography>
                      }
                      action={
                        <IconButton
                          onClick={() => setOpenLocation(!openLocation)}
                          aria-label="expand"
                          size="small"
                        >
                          {openLocation ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      }
                    ></CardHeader>
                    <div>
                      <Collapse in={openLocation} timeout="auto" unmountOnExit>
                        <CardContent>
                          <FormControl
                            style={{
                              width: "100%",
                              marginBottom: 20,
                              border: "1px solid #7CD1DC",
                              borderRadius: "4px",
                              "& .MuiInputBase-root": {
                                borderBottom: "none", // Remove the underline
                              },
                              backgroundColor: AppColor.fieldBackground,
                            }}
                          >
                            <InputLabel
                              id="multiple-select-label"
                              style={{ marginLeft: 6, marginBottom: 14 }}
                            >
                              Select Passports
                            </InputLabel>
                            <Select
                              disableUnderline
                              labelId="multiple-select-label"
                              id="multiple-select"
                              multiple
                              value={passports}
                              onChange={(event) =>
                                setPassports(event.target.value)
                              }
                              label="Select Passports"
                              renderValue={(selected) => (
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={value}
                                      label={value}
                                      style={{ margin: 2 }}
                                    />
                                  ))}
                                </div>
                              )}
                            >
                              {countries.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </CardContent>
                      </Collapse>
                    </div>
                  </Card>

                  <Card style={card} elevation={0}>
                    <CardHeader
                      title={
                        <Typography className={classes.title}>
                          Weight (kgs)
                        </Typography>
                      }
                      action={
                        <IconButton
                          onClick={() => setOpenWeight(!openWeight)}
                          aria-label="expand"
                          size="small"
                        >
                          {openWeight ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      }
                    ></CardHeader>
                    <div>
                      <Collapse in={openWeight} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Grid
                            container
                            spacing={10}
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              marginTop: -30,
                            }}
                          >
                            <Grid item xs>
                              <Slider
                                value={weightRange}
                                onChange={handleWeightChange}
                                aria-labelledby="range-slider"
                                min={0}
                                max={150}
                                marks={weightMarks}
                                step={1}
                                valueLabelDisplay="on"
                                className={classes.slider}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Collapse>
                    </div>
                  </Card>

                  <Card style={card} elevation={0}>
                    <CardHeader
                      title={
                        <Typography className={classes.title}>
                          Height (cms)
                        </Typography>
                      }
                      action={
                        <IconButton
                          onClick={() => setOpenHeight(!openHeight)}
                          aria-label="expand"
                          size="small"
                        >
                          {openHeight ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      }
                    ></CardHeader>
                    <div>
                      <Collapse in={openHeight} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Grid
                            container
                            spacing={10}
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              marginTop: -30,
                            }}
                          >
                            <Grid item xs>
                              <Slider
                                value={heightRange}
                                onChange={handleHeightChange}
                                aria-labelledby="range-slider"
                                min={0}
                                max={250}
                                marks={heightMarks}
                                step={1}
                                valueLabelDisplay="on"
                                className={classes.slider}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Collapse>
                    </div>
                  </Card>

                  <Card style={card} elevation={0}>
                    <CardHeader
                      title={
                        <Typography className={classes.title}>
                          Gender
                        </Typography>
                      }
                      action={
                        <IconButton
                          onClick={() => setOpenGender(!openGender)}
                          aria-label="expand"
                          size="small"
                        >
                          {openGender ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      }
                    ></CardHeader>
                    <div>
                      <Collapse in={openGender} timeout="auto" unmountOnExit>
                        <CardContent>
                          <FormGroup row>
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="All"
                              labelPlacement="start"
                              onChange={() => setGender("0")}
                              checked={gender === "0"}
                              className={classes.radioButton}
                              name="gender"
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Male"
                              labelPlacement="start"
                              onChange={() => setGender("1")}
                              checked={gender === "1"}
                              className={classes.radioButton}
                              name="gender"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="Female"
                              labelPlacement="start"
                              onChange={() => setGender("2")}
                              checked={gender === "2"}
                              className={classes.radioButton}
                              name="gender"
                            />
                          </FormGroup>
                        </CardContent>
                      </Collapse>
                    </div>
                  </Card>

                  <Card style={card} elevation={0}>
                    <CardHeader
                      title={
                        <Typography className={classes.title}>Age</Typography>
                      }
                      action={
                        <IconButton
                          onClick={() => setOpenAge(!openAge)}
                          aria-label="expand"
                          size="small"
                        >
                          {openAge ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      }
                    ></CardHeader>
                    <div>
                      <Collapse in={openAge} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Grid
                            container
                            spacing={10}
                            style={{
                              paddingLeft: 20,
                              paddingRight: 20,
                              marginTop: -30,
                            }}
                          >
                            <Grid item xs>
                              <Slider
                                value={ageRange}
                                onChange={handleAgeChange}
                                aria-labelledby="range-slider"
                                min={0}
                                max={50}
                                marks={ageMarks}
                                step={1}
                                valueLabelDisplay="on"
                                className={classes.slider}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Collapse>
                    </div>
                  </Card>
                </Box>
              )}

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box style={{ marginTop: 30, marginBottom: 30 }}>
                    <CustomSecondaryButton
                      text="Clear All"
                      handleClick={clearFilters}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box style={{ marginTop: 30, marginBottom: 30 }}>
                    <CustomPrimaryButton
                      text="Apply"
                      handleClick={applyFilters}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      ) : (
        <Modal
          open={openSort}
          onClose={handleCloseSort}
          style={{ overflow: "scroll" }}
        >
          <Box sx={modalStyles}>
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              Sort By
            </Typography>
            <Box>
              <Card style={card} elevation={0}>
                <div>
                  <Grid container spacing={0}>
                    <Grid item lg={1} xs={1} md={1}>
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        labelPlacement="start"
                        className={classes.radioButton}
                        onChange={(event) =>
                          setSort(parseInt(event.target.value))
                        }
                        checked={sort === 1}
                        classes={{
                          labelPlacementStart: classes.labelPlacementStart,
                        }}
                      />
                    </Grid>
                    <Grid item lg={11} xs={11} md={11}>
                      <Typography className={classes.memberLabel}>
                        Any
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item lg={1} xs={1} md={1}>
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        labelPlacement="start"
                        className={classes.radioButton}
                        onChange={(event) =>
                          setSort(parseInt(event.target.value))
                        }
                        checked={sort === 2}
                        classes={{
                          labelPlacementStart: classes.labelPlacementStart,
                        }}
                      />
                    </Grid>
                    <Grid item lg={11} xs={11} md={11}>
                      <Typography className={classes.memberLabel}>
                        Youngest to Oldest
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item lg={1} xs={1} md={1}>
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        labelPlacement="start"
                        className={classes.radioButton}
                        onChange={(event) =>
                          setSort(parseInt(event.target.value))
                        }
                        checked={sort === 3}
                        classes={{
                          labelPlacementStart: classes.labelPlacementStart,
                        }}
                      />
                    </Grid>
                    <Grid item lg={11} xs={11} md={11}>
                      <Typography className={classes.memberLabel}>
                        Oldest to Youngest
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item lg={1} xs={1} md={1}>
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        labelPlacement="start"
                        className={classes.radioButton}
                        onChange={(event) =>
                          setSort(parseInt(event.target.value))
                        }
                        checked={sort === 4}
                        classes={{
                          labelPlacementStart: classes.labelPlacementStart,
                        }}
                      />
                    </Grid>
                    <Grid item lg={11} xs={11} md={11}>
                      <Typography className={classes.memberLabel}>
                        Price Low to High
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item lg={1} xs={1} md={1}>
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        labelPlacement="start"
                        className={classes.radioButton}
                        onChange={(event) =>
                          setSort(parseInt(event.target.value))
                        }
                        checked={sort === 5}
                        classes={{
                          labelPlacementStart: classes.labelPlacementStart,
                        }}
                      />
                    </Grid>
                    <Grid item lg={11} xs={11} md={11}>
                      <Typography className={classes.memberLabel}>
                        Price High to Low
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Card>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box style={clearAll}>
                    <CustomSecondaryButton
                      text="Clear All"
                      handleClick={clearFilters}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box style={clearAll}>
                    <CustomPrimaryButton
                      text="Apply"
                      handleClick={applyFilters}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};
export default FilterModal;
