import {
  Avatar,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import AppColor from "../utils/color";
import { HOME_ROUTES } from "../constants/site-routes";
import { Link } from "react-router-dom";
import { putRecord } from "../services/Axios";
import { NOTIFICATION } from "../constants/ApiEndPoint";
import { updateRequestStatus } from "../services/Authorize";
import { useSelector } from "react-redux";

const moment = require("moment");

const NotificationItem = ({ notification }) => {
  const user = useSelector((state) => state.authorize);
  let url = HOME_ROUTES.MEMBER + "/" + notification.profile_id;
  if (notification.user_type === 1) {
    url = HOME_ROUTES.AGENT + "/" + notification.profile_id;
  } else if (notification.user_type === 2) {
    url = HOME_ROUTES.MEMBER + "/" + notification.profile_id;
  } else if (notification.user_type === 3) {
    url = HOME_ROUTES.COACH + "/" + notification.profile_id;
  } else if (notification.user_type === 4) {
    url = HOME_ROUTES.TEAM + "/" + notification.profile_id;
  }

  const formatDateTime = (timestamp) => {
    return moment(timestamp).format("dddd, h:mm A");
  };

  const handelRequestAction = (action) => {
    let status = 1;
    if (action === "decline") {
      status = 2;
    }
    updateRequestStatus({
      from_id: notification.from_id,
      to_id: user.id,
      status: status,
    });
    putRecord(NOTIFICATION.INDEX, notification.id);
  };

  return (
    <ListItem
      style={
        !notification.is_read
          ? {
              backgroundColor: AppColor.lightBlue,
              borderRadius: 10,
              marginBottom: 10,
            }
          : { borderRadius: 10, marginBottom: 10 }
      }
    >
      <ListItemIcon>
        <Avatar
          alt={notification?.from?.avatar_url}
          src={notification?.from?.avatar_url}
          style={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontFamily: "SemiBold",
              }}
            >
              <div>{notification?.title}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "0.9rem",
                fontFamily: "Regular",
                color: AppColor.newTextColor,
              }}
            >
              <div>{notification?.description}</div>
            </div>
          </>
        }
        secondary={
          <span style={{ marginTop: 5 }}>
            <span
              style={{
                fontSize: "0.65rem",
                fontFamily: "Medium",
              }}
            >
              {formatDateTime(notification.created_at)}
            </span>
            <br />
            {notification.action_type === "profile_view" &&
              notification.profile_id && (
                <Link to={url}>
                  <Button
                    style={{
                      color: AppColor.black,
                      backgroundColor: AppColor.defaultBgColor,
                      padding: 5,
                      fontSize: 12,
                      marginTop: 9,
                      fontFamily: "SemiBold",
                    }}
                  >
                    View Profile
                  </Button>
                </Link>
              )}
            {notification.action_type === "received_request" &&
              !notification.action_completed && (
                <>
                  <Button
                    style={{
                      color: AppColor.black,
                      backgroundColor: AppColor.defaultBgColor,
                      margin: "5px 5px 5px 0px",
                      padding: 5,
                      fontSize: 12,
                    }}
                    onClick={() => {
                      handelRequestAction("accept");
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    style={{
                      color: AppColor.black,
                      backgroundColor: AppColor.lightGray,
                      margin: "5px 5px 5px 0px",
                      padding: 5,
                      fontSize: 12,
                    }}
                    onClick={() => {
                      handelRequestAction("decline");
                    }}
                  >
                    Decline
                  </Button>
                </>
              )}
          </span>
        }
      />
    </ListItem>
  );
};

export default NotificationItem;
