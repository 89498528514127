import { Grid, Typography, Button, Box, Paper, useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

function ChooseProfile() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const grid = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const title = {
    color: "#212121",
    fontSize: isMobile ? 32 : 50,
    fontFamily: "Bold",
    lineHeight: 1.2,
  };

  const description = {
    color: "#656C7B",
    fontSize: isMobile ? 16 : 18,
    marginTop: 45,
    fontFamily: "Medium",
  };
  const signupButton = {
    color: "white",
    backgroundColor: "black",
    borderRadius: 28,
    marginTop: 50,
    width: "100%",
    height: 56,
  };
  const signupText = {
    marginLef: 15,
    marginRight: 15,
    marginLeft: 4,
    fontSize: 16,
    fontFamily: "Bold",
    color: "#84ECF5",
  };

  const imageBox = {
    display: "flex",
    alignItems: "center",
    maxWidth: "90%",
    margin: "0 auto",
  };

  const multiImageHolder = {
    background: "white",
  };

  return (
    <div id="profiles" style={{ background: "white", width: "100%" }} >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={grid}
      >
        <Box sx={imageBox}>
          <Grid container spacing={2} columns={12}>
            <Grid item md={6}>
              <Paper style={multiImageHolder} elevation={0}>
                <img
                  src={isMobile ? "/images/img_p_1.png" : "/images/img4.png"}
                  alt="Overlay"
                  style={{ width: isMobile ? "100%" : "130%" }}
                />
              </Paper>
            </Grid>
            <Grid item md={6}>
              <Paper
                style={{
                  background: "white",
                  paddingTop: isMobile ? "0%" : "10%",
                  paddingBottom: "10%",
                }}
                elevation={0}
                justifyContent="center"
              >
                <Typography style={title}>
                  Revolutionising{<br />} Sports Recruitment
                </Typography>

                <Typography style={description}>
                  Talentlockr is the future of sports recruitment, designed to
                  streamline and enhance the process for athletes, scouts,
                  agents, and coaches alike. With our innovative platform, we've
                  simplified the way talent is discovered, evaluated, and
                  connected with the right opportunities.{<br />}
                </Typography>
                <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }} to={isMobile ? "/mobile" : "/iam"}>
                  <Button style={signupButton}>
                    <Typography style={signupText}>{isMobile ? "Download now" : "Sign up now"}</Typography>
                  </Button>
                </Link>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
}

export default ChooseProfile;
