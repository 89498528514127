import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchRecord } from "../../services/Axios";
import { GUEST } from "../../constants/ApiEndPoint";

function FooterView() {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const grid = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "7%",
  };

  const box = {
    display: "flex",
    alignItems: "center",
    maxWidth: "85%",
    margin: "0 auto",
    background: "#212121",
  };

  const button = {
    fontFamily: "Medium",
    fontSize: 16,
    lineHeight: 1.6,
    letterSpacing: 0,
    color: "white",
    textAlign: "left",
  };

  const buttonContainer = {
    marginLeft: -10,
    marginTop: "3%",
    paddingBottom: "4%",
  };

  const imgs = {
    width: 22,
    height: 17,
    marginLeft: "6%",
    objectFit: "contain",
  };

  const fb = {
    width: 9,
    height: 19,
  };

  const socialIconContainer = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  };
  const divider = { background: "#C2C2C220", width: "100%", marginTop: "4%" };

  const [settings, setSettings] = useState({});

  useEffect(() => {
    fetchSettings();
  }, [])

  const fetchSettings = async () => {
    const response = await fetchRecord(GUEST.INDEX);
    if (response && response.data && response.status === 200) {
      setSettings(response.data);
    }
  }


  const signupButton = {
    color: "white",
    backgroundColor: "black",
    borderRadius: 28,
    marginTop: 50,
    width: 260,
    height: 56,
  };
  const signupText = {
    marginLeft: 15,
    marginRight: 15,
    fontSize: 16,
    fontFamily: "Bold",
    color: "#84ECF5",
  };

  return (
    <div style={{ background: "#212121" }}>
      <Box style={box}>
        <Grid container style={grid} direction={"column"} spacing={0}>
          <img src="/img/logo.png" style={{ width: 206, height: 59 }} alt=""/>
          <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }} to={mobileDevice ? "/mobile" : "/iam"}>
            <Button style={signupButton}>
              <Typography style={signupText}>Subscribe to our newsletter</Typography>
            </Button>
          </Link>
          <Divider style={divider} />
          <Grid
            container
            direction={"row"}
            spacing={0}
            xs={12}
            style={buttonContainer}
          >
            <Grid item xs={6} direction={"row"}>
              <Button style={button}>
                <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }} to="/terms-conditions">
                  Terms & Conditions
                </Link>
              </Button>
              <Button style={button}>
                <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }} to="/privacy-policy">
                  Privacy Policy
                </Link>
              </Button>
            </Grid>
            <Grid
              item
              spacing={100}
              xs={6}
              direction={"row"}
              style={socialIconContainer}
            >
              <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", marginLeft: "6%" }} to={settings?.facebook_url}>
                <img src="/img/fb.png" style={fb} alt=""/>
              </Link>
              <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", marginLeft: "6%" }} to={settings?.twitter_url}>
                <img src="/img/twitter.png" style={imgs}  alt=""/>
              </Link>
              <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", marginLeft: "6%" }} to={settings?.instagram_url}>
                <img src="/img/insta.png" style={imgs}  alt=""/>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default FooterView;
