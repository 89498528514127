import { IconButton } from "@material-ui/core";
import React from "react";

const VerifiedPlayer = ({ player }) => {
  const isVerified = player.is_subscribe;
  const isProUser = player.is_super_pro;

  return (
    <IconButton style={{ height: 25, width: 25 }}>
      {isVerified ? (
        <img
          src={"/icons/verified.png"}
          style={{ width: 18, cursor: "auto" }}
          alt="verified"
        />
      ) : (
        isProUser && (
          <img
            src={"/icons/pro_user.png"}
            style={{ width: 18, cursor: "auto" }}
            alt="pro user"
          />
        )
      )}
    </IconButton>
  );
};

export default VerifiedPlayer;
