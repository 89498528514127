/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import Navbar from "../../../layouts/Navigation";
import { Container, Box, Typography, Avatar } from "@material-ui/core";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { fetchRecord } from "../../../services/Axios";
import { useNavigate, useParams } from "react-router-dom";
import { PROFILE } from "../../../constants/ApiEndPoint";
import { makeStyles } from "@material-ui/styles";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";
import AboutOurClub from "../../../components/AboutOurClub";
import { HOME_ROUTES } from "../../../constants/site-routes";
import FavouriteItem from "../../../components/FavouriteItem";
import VerifiedPlayer from "../../../components/VerifiedPlayer";

const useStyles = makeStyles((theme) => ({
  detailBlock: {
    padding: "1rem",
    margin: "50px 10px 10px 0px",
    backgroundColor: "#ffffff",
    border: "1px solid #d2d6de",
    boxShadow: "0 1px 1px rgba(0,0,0,0.1)",
  },
  memberTitle: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  memberCurrentClub: {
    fontSize: "14px",
  },
  container: {
    marginBottom: 10,
    width: "50%",
  },

  floatRight: {
    float: "right",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 22,
  },
}));

const TeamDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState(null);
  const navigate = useNavigate();

  const fetchPlayers = async () => {
    setLoading(true);
    const response = await fetchRecord(PROFILE.DETAIL, id);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setPlayer(response.data);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  const handleContactCoach = () => {
    navigate(HOME_ROUTES.MESSAGES + "/" + player.id);
  };

  return (
    <>
      <Page title={player?.full_name} backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container}>
          <div>
            {player && (
              <div>
                <Box>
                  <Avatar
                    src={player.avatar_url ?? "/images/logo.png"}
                    style={{
                      width: "100%",
                      height: 300,
                      objectFit: "cover",
                      borderRadius: 10,
                    }}
                  />
                </Box>
                <Box style={{ marginTop: 20 }}>
                  <Typography className={classes.memberTitle}>
                    {player.full_name ?? "N/A"} { (player?.is_subscribe || player?.is_super_pro) && (<VerifiedPlayer player={player} />) }
                    <div className={classes.floatRight}>
                      <FavouriteItem player={player} />
                      <img src="/icons/img_team.png" width={20} alt=""/>
                    </div>
                  </Typography>
                  <Typography className={classes.memberCurrentClub}>
                    {player.location ?? "N/A"}
                  </Typography>
                </Box>

                <AboutOurClub aboutClub={player?.team?.about_club} />

                <Box style={{ marginTop: 50 }}>
                  <CustomPrimaryButton
                    text="Contact Team"
                    handleClick={handleContactCoach}
                  />
                </Box>
              </div>
            )}
          </div>
        </Container>
      </Page>
    </>
  );
};

export default TeamDetail;
