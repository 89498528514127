import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { configStore } from './stores/Store';
import { isLoggedIn } from './services/Authorize';
import { registerFcmServiceWorker } from './register-firebase-messaging-sw';

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderApp = (preloadedState) => {
  const store = configStore(preloadedState);
  window.state = store.getState;

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

registerFcmServiceWorker();

(async () => renderApp(await isLoggedIn()))();
