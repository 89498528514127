import {
  fetchRecord,
  postRecord,
  postRecordWithFile,
  putRecord,
  putRecordWithFile,
} from "./Axios";
import {
  ACCOUNT,
  AUTHENTICATION,
  CONTACT,
  ENQUIRY,
  PROFILE,
  REPORT,
} from "../constants/ApiEndPoint";
import { getDatabase, ref, set, update } from "firebase/database";
import { app } from "./Firebase";
import * as Storage from "./Storage";

export const login = async (user) =>
  await postRecord(AUTHENTICATION.LOGIN, user);

export const signup = async (user) =>
  await postRecord(AUTHENTICATION.REGISTER, user);

export const registerPlayerStep1 = async (data) =>
  await postRecordWithFile(AUTHENTICATION.REGISTER_PLAYER_STEP1, data);

export const registerPlayerStep2 = async (data) =>
  await postRecordWithFile(AUTHENTICATION.REGISTER_PLAYER_STEP2, data);

export const registerSchoolPlayerStep1 = async (data) =>
  await postRecordWithFile(AUTHENTICATION.REGISTER_SCHOOL_PLAYER_STEP1, data);

export const registerSchoolPlayerStep2 = async (data) =>
  await postRecordWithFile(AUTHENTICATION.REGISTER_SCHOOL_PLAYER_STEP2, data);

export const registerPlayerStep3 = async (data) =>
  await postRecord(AUTHENTICATION.REGISTER_PLAYER_STEP3, data);

export const updatePlayerStep1 = async (data) =>
  await postRecordWithFile(AUTHENTICATION.UPDATE_PLAYER_STEP_1, data);

export const updatePlayerStep2 = async (data) =>
  await putRecord(AUTHENTICATION.UPDATE_PLAYER_STEP_2, "", "", data);

export const logout = async () => await fetchRecord(AUTHENTICATION.LOGOUT);

export const forgotPassword = async (user) =>
  await postRecord(AUTHENTICATION.FORGOT_PASSWORD, user);

export const updateProfile = async (data) =>
  await putRecordWithFile(PROFILE.UPDATE, data);

export const updateCoachProfile = async (data) =>
  await putRecordWithFile(PROFILE.UPDATE_COACH, data);

export const updateTeamProfile = async (data) =>
  await putRecordWithFile(PROFILE.UPDATE_TEAM, data);

export const addSport = async (data) =>
  await postRecordWithFile(AUTHENTICATION.ADD_SPORT, data);

export const addSchoolSport = async (data) =>
  await postRecord(AUTHENTICATION.ADD_SCHOOL_SPORT, data);

export const addAgency = async (data, id) =>
  await postRecordWithFile(`agents/${id}/agencies`, data);

export const addSchool = async (data) =>
  await postRecordWithFile(`schools`, data);

export const addFavourite = async (data) =>
  await postRecord(`member-list/${data.id}/add-favourite`);

export const sendContact = async (data) => await postRecord(CONTACT.SEND, data);

export const deleteAccount = async () => await postRecord(ACCOUNT.DELETE);

export const updatePassword = async (data) =>
  await postRecord(PROFILE.UPDATE_PASSWORD, data);

export const reportUser = async (data) => await postRecord(REPORT.INDEX, data);

export const storeEnquiry = async (data) => await postRecord(ENQUIRY.INDEX, data);

export const ssoLogin = async (data, provider) =>
  await postRecord(AUTHENTICATION.SOCIAL_LOGIN + "/" + provider, data);

export const isLoggedIn = async () => {
  const response = await fetchRecord(AUTHENTICATION.USER_PROFILE);

  let user = null;
  if (response && response.data) {
    user = response.data;
  }

  let preloadedState = {};
  if (user) {
    preloadedState = {
      authorize: user,
    };
    const db = getDatabase(app);
    const userRef = ref(db, `users/${user.id}`);
    const userDetail = {
      avatar_url: user.avatar_url ?? null,
      date_of_birth: user.date_of_birth ?? null,
      email: user.email ?? null,
      fcmToken: Storage.getFirebaseToken() ? Storage.getFirebaseToken() : "",
      full_name: user.full_name ?? null,
      id: user.id ?? null,
      is_agent_player: user.is_agent_player ?? null,
      is_expired: false,
      is_profile_completed: user.is_profile_completed ?? null,
      is_saved: user.is_saved ?? false,
      is_subscribed: false,
      is_trial: false,
      latitude: user.latitude ?? null,
      longitude: user.longitude ?? null,
      location: user.location ?? null,
      player_register_step: user.player_register_step ?? null,
      sport: user.sport ?? null,
      timezone: user.timezone ?? null,
      type: user.type ?? null,
      uuid: user.uuid ?? null,
      views: user.views ?? null,
    };

    if (user.agent) {
      userDetail.agent = {
        id: user.agent.id ?? null,
        user_id: user.agent.user_id ?? null,
        is_active: user.agent.is_active ?? null,
        created_at: user.agent.created_at ?? null,
        updated_at: user.agent.updated_at ?? null,
      };
      if (user.agent.agency) {
        userDetail.agent.agency = {
          id: user.agent.agency.id ?? null,
          name: user.agent.agency.name ?? null,
          abn_license: user.agent.agency.abn_license ?? null,
          about: user.agent.agency.about ?? null,
          agent_id: user.agent.agency.agent_id ?? null,
          created_at: user.agent.agency.created_at ?? null,
          updated_at: user.agent.agency.updated_at ?? null,
        };
      }
    }

    if (user.coach) {
      userDetail.coach = {
        id: user.coach.id ?? null,
        user_id: user.coach.user_id ?? null,
        is_active: user.coach.is_active ?? null,
      };
    }

    set(userRef, userDetail);
  }

  return preloadedState;
};

export const updateRequestStatus = async (payload) => {
  console.log("payload", payload);
  const db = getDatabase(app);
  const ref1 = ref(db, `conversations/${payload.from_id}/${payload.to_id}`);
  update(ref1, {conversationStatus: payload.status});

  const ref2 = ref(db, `conversations/${payload.to_id}/${payload.from_id}`);
  update(ref2, {conversationStatus: payload.status});
};
