import React from "react";
import Page from "../../components/Page";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, TextField } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { AUTH_ROUTES } from "../../constants/site-routes";
import CustomPrimaryButton from "../../components/Common/CustomPrimaryButton";
import Logo from "../../components/Common/Logo";
import AppColor from "../../utils/color";
import { useDispatch } from "react-redux";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import Notify from "../../components/Common/Notify";
import { forgotPassword } from "../../stores/actions/Authorize";
import CustomButton from "../../components/Common/CustomButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  heading: {
    fontWeight: "bold",
    fontSize: "40px",
    fontFamily: "Noventa812, sans-serif",
  },

  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },

  topBox: {
    height: "40vh",
    textAlign: "center",
  },

  bottomBox: {
    backgroundColor: theme.palette.btnColors.white,
    borderRadius: "20px 20px 0px 0px",
    marginTop: "-10px",
    [theme.breakpoints.down("sm")]: {
      height: "75vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "70vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "70vh",
    },
  },

  fromBlock: {
    margin: "10px auto",
    width: "40%",
  },

  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },

  textField: {
    width: "100%",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    borderRadius: 30,
    fontFamily: "Medium",
    "& input::placeholder": {
      fontSize: "16",
      fontFamily: "Medium",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "16",
      fontFamily: "Medium",
    },
    marginTop: 20,
  },
}));

const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleClickResetPassword = async () => {
    const body = {
      email: email,
    };

    setLoading(true);
    await dispatch(forgotPassword(body));
    setLoading(false);
  };

  const handleClickGoBack = () => {
    navigate(AUTH_ROUTES.LOGIN);
  };

  return (
    <>
      <Page
        className={classes.root}
        title="Forgot Password"
        backgroundColor="#5BEDF6"
      >
        {loading && <SimpleBackdrop />}
        <Notify />
        <Box className={classes.topBox}>
          <Box className={classes.subTopBox}>
            <Typography className={classes.heading}>Forgot Password</Typography>
          </Box>
          <Box className={classes.subTopBox}>
            <Logo />
          </Box>
        </Box>
        <Box className={classes.bottomBox}>
          <Box className={classes.fromBlock}>
            <Typography
              className={classes.forgotPassword}
              style={{ fontSize: 18, fontFamily: "Medium", paddingTop: "50px" }}
              variant="h1"
              gutterBottom
            >
              Forgot Password
            </Typography>
            <Typography
              style={{
                color: AppColor.newTextColor,
                fontSize: 13,
                fontFamily: "Medium",
              }}
            >
              Please enter your email address below and the recovery email will
              be send with a link to reset your password.
            </Typography>
            <Box className={classes.textFieldBlock} style={{ marginTop: 10 }}>
              <TextField
                variant="outlined"
                placeholder="Enter your email address"
                className={classes.textField}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Box
              className={classes.textFieldBlock}
              style={{ paddingTop: "50px" }}
            >
              <CustomPrimaryButton
                text="Reset Password"
                handleClick={handleClickResetPassword}
              />
            </Box>
            <Box className={classes.textFieldBlock} style={{ marginTop: 20 }}>
              <CustomButton text="Go Back" handleClick={handleClickGoBack} />
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default ForgotPassword;
