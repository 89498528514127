import React, { useState } from "react";
import Page from "../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  TextField,
  Link,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import "./index.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { AUTH_ROUTES, HOME_ROUTES } from "../../constants/site-routes";
import CustomPrimaryButton from "../../components/Common/CustomPrimaryButton";
import { login, ssoLogin } from "../../stores/actions/Authorize";
import { setNotify } from "../../stores/actions/Notify";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import Notify from "../../components/Common/Notify";
import { useDispatch } from "react-redux";
import Logo from "../../components/Common/Logo";
import AppColor from "../../utils/color";
import * as Storage from "../../services/Storage";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Configs from "../../configs/Configs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "40px",
    fontFamily: "Noventa812, sans-serif",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    height: "40vh",
    textAlign: "center",
  },
  bottomBox: {
    height: "60vh",
    backgroundColor: "white",
    borderRadius: "20px 20px 0px 0px",
    marginTop: "-10px",
  },
  textFieldBlock: {
    height: "80px",
    width: "70%",
    fontFamily: "Medium",
    margin: "10px auto",
  },

  textField: {
    width: "100%",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    fontFamily: "Medium",
    borderRadius: 60,
    "& input::placeholder": {
      fontSize: "16",
      fontFamily: "Medium",
    },
  },
  forgotPassword: {
    marginBottom: "50px",
    color: AppColor.appBlueSecondary,
    marginRight: "20%",
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "10px auto",
      width: "50%",
    },
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  async function handleSubmit() {
    const body = {
      email: email,
      password: password,
      device_id: Date.now(),
      device_type: "WEB",
      device_push_token: Storage.getFirebaseToken()
        ? Storage.getFirebaseToken()
        : "",
    };

    setLoading(true);
    let response = await dispatch(login(body));
    setLoading(false);
    if (response.status === 200) {
      if (response.data.type !== 2 && !response.data.sport) {
        window.location.href = AUTH_ROUTES.REGISTER_SPORT;
      } else {
        window.location.href = HOME_ROUTES.INDEX;
      }
    } else {
      dispatch(setNotify(response));
    }
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const body = {
        token: tokenResponse.access_token,
        provider: "google",
        device_id: Date.now(),
        device_type: "WEB",
        device_push_token: Storage.getFirebaseToken()
          ? Storage.getFirebaseToken()
          : "",
        type: null,
      };

      setLoading(true);
      let response = await dispatch(ssoLogin(body, "google"));
      setLoading(false);
      if (response.status === 200) {
        if (response.data.type !== 2 && !response.data.sport) {
          window.location.href = AUTH_ROUTES.REGISTER_SPORT;
        } else {
          window.location.href = HOME_ROUTES.INDEX;
        }
      }
    },
  });

  const responseFacebook = async (tokenResponse) => {
    const body = {
      token: tokenResponse.accessToken,
      provider: "facebook",
      device_id: Date.now(),
      device_type: "WEB",
      device_push_token: Storage.getFirebaseToken()
        ? Storage.getFirebaseToken()
        : "",
      type: null,
    };

    setLoading(true);
    let response = await dispatch(ssoLogin(body, "facebook"));
    setLoading(false);
    if (response.status === 200) {
      if (response.data.type !== 2 && !response.data.sport) {
        window.location.href = AUTH_ROUTES.REGISTER_SPORT;
      } else {
        window.location.href = HOME_ROUTES.INDEX;
      }
    }
  };

  return (
    <>
      <Page className={classes.root} title="Login" backgroundColor="#5BEDF6">
        {loading && <SimpleBackdrop />}
        <Box className={classes.topBox}>
          <Box className={classes.subTopBox}>
            <Typography className={classes.heading}>SIGN IN</Typography>
          </Box>
          <Box className={classes.subTopBox}>
            <Logo />
          </Box>
        </Box>
        <Box className={classes.bottomBox}>
          <form onSubmit={handleSubmit} className={classes.fromBlock}>
            <Box
              style={{ paddingTop: "10px", width: "70%", margin: "10px auto" }}
            >
              <Notify />
            </Box>
            <Box
              className={classes.textFieldBlock}
              style={{ paddingTop: "20px" }}
            >
              {/* <TextField
                variant="outlined"
                placeholder="Enter your email address"
                className={classes.textField}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  style: {
                    fontFamily: "Medium", // Change font family here
                    fontSize: 16,
                  },
                }}
              /> */}

              <TextField
                variant="outlined"
                placeholder="Enter your email address"
                className={classes.textField}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{
                  sx: {
                    fontFamily: "Medium", // Change to your custom font name
                    fontSize: 16,
                    color: "red", // Change label color if needed
                    paddingBottom: 10,
                  },
                }}
                sx={{
                  "& .MuiFormLabel-root": {
                    fontSize: "0.1rem",
                  },
                }}
              />
            </Box>
            <Box className={classes.textFieldBlock}>
              <TextField
                variant="outlined"
                placeholder="Enter your password"
                className={classes.textField}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className={classes.forgotPassword}>
              <Link
                component={RouterLink}
                variant="subtitle1"
                color="inherit"
                to={AUTH_ROUTES.FORGOT_PASSWORD}
                underline="none"
                style={{
                  float: "right",
                  fontFamily: "Medium",
                }}
              >
                forgot password?
              </Link>
            </Box>
            <Box className={classes.textFieldBlock}>
              <CustomPrimaryButton
                text="Continue"
                handleClick={handleSubmit}
                style={{ marginTop: 50 }}
              />
            </Box>
          </form>

          <Box style={{ textAlign: "center", marginBottom: 20 }}>
            <Typography
              style={{
                fontSize: 12,
                marginBottom: 20,
                fontFamily: "Medium",
              }}
            >
              or sign in with
            </Typography>
            <FacebookLogin
              appId={Configs.FACEBOOK_SSO_API_KEY}
              autoLoad={false}
              fields="name,email"
              scope="email"
              callback={responseFacebook}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "#1278f2",
                    cursor: "pointer",
                    border: "1px solid #1278f2",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <img
                    src="/facebook.png"
                    alt="facebook"
                    style={{ width: 30, height: 30 }}
                  />
                </button>
              )}
            />
            <button
              onClick={() => googleLogin()}
              style={{
                backgroundColor: "#ffffff",
                cursor: "pointer",
                border: "1px solid #ffffff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 5,
              }}
            >
              <img
                src="/google.png"
                alt="google"
                style={{ width: 30, height: 30 }}
              />
            </button>
          </Box>

          <Box style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: 12, fontFamily: "Medium" }}>
              Don't have an account? &nbsp;
              <Link
                component={RouterLink}
                variant="subtitle1"
                color="inherit"
                to={AUTH_ROUTES.IAM}
                underline="none"
                style={{
                  color: AppColor.appBlueSecondary,
                  fontSize: 12,
                  fontFamily: "Medium",
                }}
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default Login;
