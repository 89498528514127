import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import AppColor from "../utils/color";

const AboutOurClub = ({ aboutClub }) => {
  const [openAboutOurClub, setOpenAchievement] = useState(false);

  ///css
  const titleCSS = {
    fontFamily: "Medium",
    fontSize: 14,
    color: AppColor.newTextColor,
  };

  return (
    <Card
      style={{
        marginTop: 50,
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
      elevation={0}
    >
      <CardHeader
        title={
          <Typography style={{ fontFamily: "Bold", fontSize: 20 }}>
            About Our Club
          </Typography>
        }
        action={
          <IconButton
            onClick={() => setOpenAchievement(!openAboutOurClub)}
            aria-label="expand"
            size="small"
          >
            {openAboutOurClub ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        }
      ></CardHeader>
      <div>
        <Collapse in={openAboutOurClub} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography style={titleCSS}>{aboutClub ?? "N/A"}</Typography>
          </CardContent>
        </Collapse>
      </div>
    </Card>
  );
};

export default AboutOurClub;
