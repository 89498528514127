import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { app } from "../services/Firebase";
import { getDatabase, onValue, ref } from "firebase/database";
import AppColor from "../utils/color";

const ConversationItem = ({ conversation, receiver_id }) => {
  let { sender_name, sender_avatar, sender_id } = conversation;

  if (conversation.receiver_id !== receiver_id) {
    sender_name = conversation.receiver_name;
    sender_avatar = conversation.receiver_avatar;
    sender_id = conversation.receiver_id;
  }
  const db = getDatabase(app);
  const [receiverDetails, setReceiverDetails] = useState(null);

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const userQuery = ref(db, `users/${sender_id}`);
    return onValue(
      userQuery,
      (snapshot) => {
        setReceiverDetails(snapshot.val());
      },
      {
        onlyOnce: true,
      }
    );
  }, [db, sender_id]);

  const memberType = (member_type) => {
    switch (member_type) {
      case 1:
        return "/icons/img_agent.png";
      case 2:
        return "/icons/img_player.png";
      case 3:
        return "/icons/coach.png";
      case 4:
        return "/icons/img_team.png";
      default:
        return "No Type";
    }
  };

  return (
    <ListItem
      button
      component={Link}
      to={`/messages/${sender_id}`}
      elevation={0}
    >
      <ListItemIcon>
        <Avatar
          alt={receiverDetails?.full_name ?? sender_name}
          src={receiverDetails?.avatar_url ?? sender_avatar}
          style={{
            width: 40,
            height: 40,
            borderRadius: "10%",
            objectFit: "cover",
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontFamily: "Medium",
              paddingBottom: 5,
            }}
          >
            <div>{receiverDetails?.full_name ?? sender_name}</div>
            <div>
              <Typography
                style={{
                  fontFamily: "Medium",
                  fontSize: 12,
                  color: AppColor.newTextColor,
                }}
              >
                {formatDateTime(conversation.created_at)}
              </Typography>
            </div>
          </div>
        }
        secondary={
          <span style={{ marginTop: 8, fontFamily: "Medium" }}>
            <span>
              <img
                src={memberType(receiverDetails?.type)}
                width={12}
                style={{ paddingRight: 10 }}
                alt=""
              />
            </span>

            {conversation.message}
          </span>
        }
      />
    </ListItem>
  );
};

export default ConversationItem;
