export const AUTHENTICATION = {
  LOGIN: 'login',
  USER_PROFILE: 'profile',
  REGISTER: 'register',
  LOGOUT: 'logout',
  FORGOT_PASSWORD: 'password/email',
  UPDATE_PLAYER_STEP_1: 'player/step1',
  UPDATE_PLAYER_STEP_2: 'player/step2',
  REGISTER_PLAYER_STEP1: 'register/player/step1',
  REGISTER_PLAYER_STEP2: 'register/player/step2',
  REGISTER_PLAYER_STEP3: 'register/player/step3',
  REGISTER_SCHOOL_PLAYER_STEP1: 'register/player/school/step1',
  REGISTER_SCHOOL_PLAYER_STEP2: 'register/player/school/step2',
  ADD_SPORT: 'categories',
  ADD_SCHOOL_SPORT: 'categories/school',
  SOCIAL_LOGIN: 'social-login',
};

export const SPORTS = {
  LIST: 'sports',
  OTHERS: 'others/sports',
};

export const CATEGORIES = {
  LIST: 'categories',
};

export const PLAYERS = {
  LIST: 'players',
  PROFILE: 'players/profile',
  AGENT: 'players/agent-players',
  SCHOOL: 'players/school-players',
};

export const COUNTRY = {
  LIST: 'countries',
};

export const POSITION = {
  ALL: 'positions/all',
  LIST: 'positions',
};

export const MEMBER = {
  LIST: 'member-list',
  FAVOURITE: 'member-list/favourite-members',
};

export const POST = {
  LIST: 'post',
  CREATE: 'post',
  LIKE: 'post/like',
};

export const PROFILE = {
  DETAIL: 'profile',
  UPDATE: 'profile',
  UPDATE_COACH: 'coaches',
  UPDATE_TEAM: 'team',
  UPDATE_PASSWORD: 'password/edit',
};

export const CONTACT = {
  SEND: 'contact',
};

export const ACCOUNT = {
  DELETE: 'delete-account',
};

export const REPORT = {
  REASONS: 'reports/report-reasons',
  INDEX: 'reports',
};

export const AGENT = {
  DETAIL: '/agents',
};

export const ENQUIRY = {
  INDEX: '/enquire',
};

export const NOTIFICATION = {
  INDEX: '/notifications',
  MARK_ALL_READ: '/notifications/mark-all-read',
};

export const GUEST = {
  INDEX: '/guests',
};