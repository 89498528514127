import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  typography: {
    fontFamily: ["Regular", "Medium", "Semibold", "Bold"].join(","),
    letterSpacing: "-0.3px",
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#76ebf4",
    },
    background: {
      white: "#ffffff",
    },
    btnColors: {
      white: "#ffffff",
    },

    dark: {
      main: "#2F2D2E",
    },
  },
  overrides: {
    MuiSlider: {
      valueLabel: {
        fontSize: "11px",
      },
    },
  },
});
