import React, { useState, useEffect } from "react";
import Page from "../../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  FormControl,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTES } from "../../../../constants/site-routes";
import CustomPrimaryButton from "../../../../components/Common/CustomPrimaryButton";
import Logo from "../../../../components/Common/Logo";
import CustomSecondaryButton from "../../../../components/Common/CustomSecondaryButton";
import SimpleBackdrop from "../../../../components/Common/SimpleBackdrop";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../../../components/Common/Notify";
import { PLAYERS, POSITION } from "../../../../constants/ApiEndPoint";
import { fetchRecord } from "../../../../services/Axios";
import { updatePlayerStep2 } from "../../../../stores/actions/Authorize";
import AppColor from "../../../../utils/color";
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  heading: {
    fontSize: 17,
    fontFamily: "Medium",
  },

  subHeading: {
    fontFamily: "Medium",
    fontSize: 14,
    marginTop: 20,
    color: AppColor.textColor,
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  bottomBox: {
    backgroundColor: theme.palette.btnColors.white,
    borderRadius: "20px 20px 0px 0px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      height: "100%",
    },
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      width: "50%",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px auto",
      width: "50%",
    },
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
  textField: {
    width: "100%",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    fontFamily: "Medium",
    borderRadius: 60,
    "& input::placeholder": {
      fontSize: "16",
      fontFamily: "Medium",
    },

    "& .MuiOutlinedInput-input": {
      fontFamily: "Medium",
      fontSize: 16,
    },
  },
  radioButton: {
    marginBottom: "30px",
  },
  slider: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  error: {
    color: "#f44336",
  },
}));

const ProfileStep3 = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authorize);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [amateur, setAmature] = useState(false);
  const [overseas, setOverseas] = useState(false);
  const [professional, setProfessional] = useState(false);

  const [formData, setFormData] = useState({
    positions: [],
    want_contracts: [],
    // rep_honours_names: [''],
    current_club: "",
    previous_clubs: [""],
  });

  // const [errors, setErrors] = useState({
  //   honours: [],
  //   previous_clubs: [],
  // });

  async function handleSaveAndContinue() {
    let valid = true;
    // const errors = {
    //   honours: [],
    //   previous_clubs: [],
    // };
    // formData.rep_honours_names.forEach((honour, index) => {
    //   if (honour === '' || !honour) {
    //     valid = false;
    //     errors.honours[index] = true;
    //   } else {
    //     errors.honours[index] = false;
    //   }
    // });

    // formData.previous_clubs.forEach((previous_club, index) => {
    //   if (previous_club === "" || !previous_club) {
    //     valid = false;
    //     errors.previous_clubs[index] = true;
    //   } else {
    //     errors.previous_clubs[index] = false;
    //   }
    // });

    // setErrors(errors);

    if (!valid) {
      return false;
    }

    setLoading(true);
    let response = await dispatch(updatePlayerStep2(formData));
    setLoading(false);
    if (response.status === 200) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setInterval(() => {
        window.location.href = HOME_ROUTES.INDEX;
      }, 2000);
    }
  }

  const handelGoBack = async () => {
    navigate(HOME_ROUTES.PROFILE_STEP_2);
  };

  const fetchProfile = async () => {
    setLoading(true);
    const response = await fetchRecord(PLAYERS.PROFILE + "/" + user.id);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      const profile = response.data;

      const userPositions = [];
      if (profile.positions && profile.positions.length > 0) {
        profile.positions.forEach((position) => {
          userPositions.push(position.position_name);
        });
      }

      const contracts = [];
      if (profile.contracts && profile.contracts.length > 0) {
        profile.contracts.forEach((contract) => {
          contracts.push(parseInt(contract.contract));
          if (parseInt(contract.contract) === 1) setAmature(true);
          if (parseInt(contract.contract) === 2) setOverseas(true);
          if (parseInt(contract.contract) === 3) setProfessional(true);
        });
      }

      // const honours = [];
      // if (profile.player_rep_honour && profile.player_rep_honour.length > 0) {
      //   profile.player_rep_honour.forEach((position) => {
      //     honours.push(position.rep_honours_name);
      //   });
      // } else {
      //   honours.push('');
      // }

      const previousClubs = [];
      if (
        profile.player_previous_club &&
        profile.player_previous_club.length > 0
      ) {
        profile.player_previous_club.forEach((previous_club) => {
          previousClubs.push(previous_club.previous_club);
        });
      }

      setFormData({
        positions: userPositions,
        want_contracts: contracts,
        // rep_honours_names: honours,
        current_club: profile.current_club,
        previous_clubs: previousClubs,
      });

      const res = await fetchRecord(
        POSITION.LIST,
        "",
        "",
        "sport=" + profile.sport
      );
      if (res && res.data && res.status === 200) {
        const positions = [];
        res.data.forEach((position) => {
          positions.push({ id: position.name, text: position.name });
        });
        setPositions(positions);
      }
    }
  };

  const handlePositionChange = (event) => {
    const positions = [...event.target.selectedOptions].map(option => option.value);
    setFormData({ ...formData, positions: positions });
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleContractChange = async (value) => {
    const index = formData.want_contracts.indexOf(value);
    if (index === -1) {
      formData.want_contracts.push(value);
      setFormData(formData);
      if (value === 1) setAmature(true);
      if (value === 2) setOverseas(true);
      if (value === 3) setProfessional(true);
    } else {
      formData.want_contracts.splice(index, 1);
      setFormData(formData);
      if (value === 1) setAmature(false);
      if (value === 2) setOverseas(false);
      if (value === 3) setProfessional(false);
    }
  };

  // const addNewHonourField = () => {
  //   setFormData({
  //     ...formData,
  //     rep_honours_names: [...formData.rep_honours_names, ''],
  //   });
  // };

  // const removeHonourField = (index, e) => {
  //   formData.rep_honours_names.splice(index, 1);
  //   setFormData({ ...formData, rep_honours_names: formData.rep_honours_names });
  // };

  // const handleHonoursChange = (index, event) => {
  //   const updatedTextAreas = formData.rep_honours_names;
  //   updatedTextAreas[index] = event.target.value;
  //   setFormData({ ...formData, rep_honours_names: updatedTextAreas });
  // };

  const addPrevClubField = () => {
    setFormData({
      ...formData,
      previous_clubs: [...formData.previous_clubs, ""],
    });
  };

  const removeClubField = (index, e) => {
    formData.previous_clubs.splice(index, 1);
    setFormData({ ...formData, previous_clubs: formData.previous_clubs });
  };

  const handlePrevClubChange = (index, event) => {
    const updatedTextAreas = formData.previous_clubs;
    updatedTextAreas[index] = event.target.value;
    setFormData({ ...formData, previous_clubs: updatedTextAreas });
  };

  const handleCurrentClubChange = (value) => {
    setFormData({ ...formData, current_club: value });
  };

  return (
    <>
      <Page className={classes.root} title="More About You">
        {loading && <SimpleBackdrop />}
        <Notify />
        <Box className={classes.topBox}>
          <Box className={classes.subTopBox}>
            <Logo />
          </Box>
        </Box>
        <Box className={classes.bottomBox}>
          <Box className={classes.fromBlock}>
            <Box
              className={classes.textFieldBlock}
              style={{ paddingTop: "50px" }}
            >
              <Typography className={classes.heading} gutterBottom>
                A little more about you
              </Typography>

              <Typography
                className={classes.subHeading}
                variant="h1"
                gutterBottom
              >
                Position
              </Typography>

              <FormControl
                style={{
                  width: "100%",
                  marginBottom: 20,
                  border: "0px solid #7CD1DC",
                  borderRadius: "30px",
                  backgroundColor: AppColor.newTextFieldBackgroundColor,
                  marginTop: 4,
                }}
              >
                <Select2
                  multiple
                  value={ formData.positions }
                  data={positions}
                  options={
                    {
                      placeholder: 'Select Positions',
                    }
                  }
                  onSelect={handlePositionChange}
                />
              </FormControl>

              <Typography
                className={classes.subHeading}
                variant="h2"
                gutterBottom
              >
                Looking For
              </Typography>

              <Grid container spacing={1} style={{ marginBottom: 50 }}>
                <Grid container item xs={4}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={
                      amateur
                        ? "custom-btn-secondary-selected"
                        : "custom-btn-secondary"
                    }
                    onClick={() => handleContractChange(1)}
                    style={{ textTransform: "none", fontWeight: "100" }}
                  >
                    Semi Pro
                  </Button>
                </Grid>
                <Grid container item xs={4}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={
                      overseas
                        ? "custom-btn-secondary-selected"
                        : "custom-btn-secondary"
                    }
                    onClick={() => handleContractChange(2)}
                    style={{ textTransform: "none", fontWeight: "100" }}
                  >
                    Overseas
                  </Button>
                </Grid>

                <Grid container item xs={4}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={
                      professional
                        ? "custom-btn-secondary-selected"
                        : "custom-btn-secondary"
                    }
                    onClick={() => handleContractChange(3)}
                    style={{ textTransform: "none", fontWeight: "100" }}
                  >
                    Professional Contract
                  </Button>
                </Grid>
              </Grid>

              {/* {formData.rep_honours_names.map((text, index) => (
                <div>
                  <Grid container spacing={2} alignItems="center" key={index}>
                    <Grid item xs={10}>
                      <TextField
                        variant="outlined"
                        placeholder="Enter rep honours name(s)"
                        className={classes.textField}
                        value={text}
                        type="text"
                        onChange={(e) => handleHonoursChange(index, e)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {index === 0 && (
                        <Button
                          disableElevation
                          variant="contained"
                          color="secondary"
                          onClick={addNewHonourField}
                          style={{
                            marginBottom: 30,
                            paddingTop: 15,
                            paddingBottom: 15,
                            float: 'right',
                            backgroundColor: '#D8D8D8',
                          }}
                        >
                          <AddIcon />
                        </Button>
                      )}
                      {index > 0 && (
                        <Button
                          disableElevation
                          variant="contained"
                          color="secondary"
                          onClick={(e) => removeHonourField(index, e)}
                          style={{
                            marginBottom: 30,
                            paddingTop: 15,
                            paddingBottom: 15,
                            float: 'right',
                            backgroundColor: '#D8D8D8',
                          }}
                        >
                          <ClearIcon />
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <label className={classes.error}>
                    {errors.honours[index] ? 'Please add honour' : ''}
                  </label>
                </div>
              ))} */}

              <TextField
                variant="outlined"
                placeholder="Enter current club name"
                className={classes.textField}
                value={formData.current_club}
                type="text"
                onChange={(e) => handleCurrentClubChange(e.target.value)}
              />

              {formData.previous_clubs.map((text, index) => (
                <div>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    key={index}
                    style={{ marginTop: 20 }}
                  >
                    {index === 0 ? (
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          placeholder="Enter previous club name(s)"
                          className={classes.textField}
                          value={text}
                          type="text"
                          onChange={(e) => handlePrevClubChange(index, e)}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={10}>
                          <TextField
                            variant="outlined"
                            placeholder="Enter previous club name(s)"
                            className={classes.textField}
                            value={text}
                            type="text"
                            onChange={(e) => handlePrevClubChange(index, e)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          {index === 0 && (
                            <Button
                              disableElevation
                              variant="contained"
                              color="secondary"
                              onClick={addPrevClubField}
                              style={{
                                marginBottom: 30,
                                paddingTop: 15,
                                paddingBottom: 15,
                                float: "right",
                                backgroundColor: "#D8D8D8",
                              }}
                            >
                              <AddIcon />
                            </Button>
                          )}
                          <Button
                            disableElevation
                            variant="contained"
                            color="secondary"
                            onClick={(e) => removeClubField(index, e)}
                            style={{
                              marginBottom: 30,
                              paddingTop: 15,
                              paddingBottom: 15,
                              float: "right",
                              backgroundColor: "#D8D8D8",
                            }}
                          >
                            <ClearIcon />
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {/* <label className={classes.error}>
                    {errors.previous_clubs[index]
                      ? "Please add previous club"
                      : ""}
                  </label> */}
                </div>
              ))}
              <div style={{ marginTop: 30 }}>
                <CustomPrimaryButton
                  text="Save and Continue"
                  handleClick={handleSaveAndContinue}
                  style={{ marginTop: 50 }}
                />
              </div>

              <div style={{ marginTop: 20 }}>
                <CustomSecondaryButton
                  text="Go Back"
                  handleClick={handelGoBack}
                  style={{ marginTop: 50 }}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default ProfileStep3;
