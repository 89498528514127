import { Grid, Typography, Box, Paper, useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

function BottomView() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const title = {
    color: "#84ECF5",
    fontSize: isMobile ? 32 : 56,
    fontFamily: "Medium",
    letterSpacing: "-1px",
    lineHeight: 1.1,
  };

  const description = {
    color: "rgb(255, 255, 255, 0.7)",
    fontSize: isMobile ? 16 : 18,
    marginTop: 30,
    fontFamily: "Medium",
    letterSpacing: "0px",
    lineHeight: 1.8,
  };

  const image = {
    width: 100,
  };

  const android = {
    width: 100,
    paddingLeft: 20,
  };

  const box = {
    display: "flex",
    alignItems: "center",
    maxWidth: "90%",
    margin: "0 auto",
    background: "black",
    borderTopRightRadius: 31,
    borderTopLeftRadius: 31,
    paddingTop: "0%",
  };

  const bannerImage = {
    width: "100%",
    borderTopRightRadius: 10,
    marginRight: "5%",
    background: "transparent",
  };

  const mainGrid = {
    paddingLeft: 52,
    paddingRight: 55,
  };

  return (
    <div style={{ background: "white" }}>
      <Box elevation={0} sx={box}>
        <Grid container spacing={2} columns={12} style={mainGrid}>
          <Grid item md={6} style={{ marginTop: "10%" }}>
            <Paper
              elevation={0}
              style={{ color: "white", background: "transparent" }}
            >
              <Typography style={title}>
                Download Now And{<br />}Unlock Your Potential.
              </Typography>
              <Typography style={description}>
                With its unparalleled features and user-friendly interface,
                Talent Lockr is the only way to recruit in today's competitive
                sports landscape.{<br />} Join us today and experience the
                future of sports recruitment with Talent Lockr.
                {<br />} {<br />} {<br />}
              </Typography>
              <span>
                <Link to="https://apps.apple.com/us/app/talent-lockr/id6444275012">
                  <img src="/img/apple.png" style={image} alt=""/>
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=io.everestapps.talentlockrapp&pli=1">
                  <img src="/img/android.png" style={android} alt=""/>
                </Link>
              </span>
              {<br />} {<br />}
            </Paper>
          </Grid>
          <Grid item md={6}>
            <Paper
              elevation={0}
              style={{ color: "white", background: "transparent" }}
            >
              <img
                src={isMobile ? "/images/img_p_7.png" : "/images/img8.png"}
                style={bannerImage}
                alt="Your"
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default BottomView;
