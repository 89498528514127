import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  FormControl,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../../../../components/Common/Notify";
import AppColor from "../../../../../utils/color";
import { registerSchoolPlayerStep2 } from "../../../../../stores/actions/Authorize";
import { HOME_ROUTES } from "../../../../../constants/site-routes";
import { fetchRecord } from "../../../../../services/Axios";
import { PLAYERS, POSITION } from "../../../../../constants/ApiEndPoint";
import Page from "../../../../../components/Page";
import SimpleBackdrop from "../../../../../components/Common/SimpleBackdrop";
import Logo from "../../../../../components/Common/Logo";
import CustomPrimaryButton from "../../../../../components/Common/CustomPrimaryButton";
import CustomSecondaryButton from "../../../../../components/Common/CustomSecondaryButton";
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: 17,
  },
  subHeading: {
    fontWeight: "300",
    fontSize: 14,
    marginTop: 20,
    color: AppColor.textColor,
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  bottomBox: {
    backgroundColor: theme.palette.btnColors.white,
    borderRadius: "20px 20px 0px 0px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      height: "100%",
    },
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      width: "50%",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px auto",
      width: "50%",
    },
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "30px",
    backgroundColor: "#E6E7E8",
    fontFamily: "Bai Jamjuree, sans-serif",
    borderRadius: 60,
  },
  radioButton: {
    marginBottom: "30px",
  },
  slider: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  error: {
    color: "#f44336",
  },
}));

const SchoolPlayerRegisterStep2 = () => {
  const location = useLocation();
  const playerId = new URLSearchParams(location.search).get('id');
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authorize);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [amateur, setAmature] = useState(false);
  const [overseas, setOverseas] = useState(false);
  const [professional, setProfessional] = useState(false);

  const [formData, setFormData] = useState({
    positions: [],
    want_contracts: [],
    current_club: "",
    previous_clubs: [""],
    player_id: "",
    foot: null,
  });

  const handleSaveAndContinue = async() => {
    let valid = true;

    if (!valid) {
      return false;
    }

    setLoading(true);
    let response = await dispatch(registerSchoolPlayerStep2(formData));
    setLoading(false);
    console.log(response);
    if (response.status === 200) {
      navigate(HOME_ROUTES.SCHOOL+"/"+user.school?.id+"/players");
    }
  }

  const handelGoBack = async () => {
    navigate(HOME_ROUTES.PLAYER_REGISTER_STEP_1);
  };

  const fetchProfile = async () => {
    setLoading(true);
    const response = await fetchRecord(PLAYERS.PROFILE + "/" + playerId);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      const profile = response.data;
      setFormData({ ...formData, player_id: profile.id });
      let sports = profile.sport ? profile.sport.replace('/', 'backslash').split(', ') : [];
      sports = sports.filter(sport => sport !== 'Sports School')
      const selectedSport = sports.length > 0  ? sports[0].replace('/', 'backslash') : '';
      const res = await fetchRecord(POSITION.LIST, "", "", "sport=" + selectedSport);
      if (res && res.data && res.status === 200) {
        const positions = [];
        res.data.forEach((position) => {
          positions.push({ id: position.name, text: position.name });
        });
        setPositions(positions);
      }
    }
  };

  const handlePositionChange = (event) => {
    const positions = [...event.target.selectedOptions].map(option => option.value);
    setFormData({ ...formData, positions: positions });
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleContractChange = async (value) => {
    const index = formData.want_contracts.indexOf(value);
    if (index === -1) {
      formData.want_contracts.push(value);
      setFormData(formData);
      if (value === 1) setAmature(true);
      if (value === 2) setOverseas(true);
      if (value === 3) setProfessional(true);
    } else {
      formData.want_contracts.splice(index, 1);
      setFormData(formData);
      if (value === 1) setAmature(false);
      if (value === 2) setOverseas(false);
      if (value === 3) setProfessional(false);
    }
  };

  const removeClubField = (index, e) => {
    formData.previous_clubs.splice(index, 1);
    setFormData({ ...formData, previous_clubs: formData.previous_clubs });
  };

  const handlePrevClubChange = (index, event) => {
    const updatedTextAreas = formData.previous_clubs;
    updatedTextAreas[index] = event.target.value;
    setFormData({ ...formData, previous_clubs: updatedTextAreas });
  };

  const handleCurrentClubChange = (value) => {
    setFormData({ ...formData, current_club: value });
  };

  const handleFootChange = async (value) => {
    if (formData.foot) {
      if (formData.foot === value) {
        setFormData({ ...formData, foot: null });
      } else {
        if (formData.foot === 3 && value === 1) {
          setFormData({ ...formData, foot: 2 });
        }
        if (formData.foot === 3 && value === 2) {
          setFormData({ ...formData, foot: 1 });
        }
        if (formData.foot === 1 && value === 2) {
          setFormData({ ...formData, foot: 3 });
        }
        if (formData.foot === 2 && value === 1) {
          setFormData({ ...formData, foot: 3 });
        }
      }
    } else {
      setFormData({ ...formData, foot: value });
    }
  };

  return (
    <>
      <Page className={classes.root} title="More About You">
        {loading && <SimpleBackdrop />}
        <Notify />
        <Box className={classes.topBox}>
          <Box className={classes.subTopBox}>
            <Logo />
          </Box>
        </Box>
        <Box className={classes.bottomBox}>
          <Box className={classes.fromBlock}>
            <Box
              className={classes.textFieldBlock}
              style={{ paddingTop: "50px" }}
            >
              <Typography className={classes.heading} variant="h4" gutterBottom>
                A little more about you
              </Typography>

              <Typography
                className={classes.subHeading}
                variant="h1"
                gutterBottom
              >
                Position
              </Typography>

              <FormControl
                style={{
                  width: "100%",
                  marginTop: 4,
                  backgroundColor: AppColor.newTextFieldBackgroundColor,
                  fontFamily: "Medium",
                  borderRadius: 30,
                }}
              >
                <Select2
                  multiple
                  value={ formData.positions }
                  data={positions}
                  options={
                    {
                      placeholder: 'Select Positions',
                    }
                  }
                  onSelect={handlePositionChange}
                />
              </FormControl>

              <Typography
                className={classes.subHeading}
                variant="h2"
                gutterBottom
              >
                Foot
              </Typography>

              <Grid container spacing={1} style={{ marginBottom: 30 }}>
                <Grid container item xs={6}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={formData.foot === 1 || formData.foot === 3 ? "custom-btn-secondary-selected" : "select-btn-secondary"}
                    onClick={() => handleFootChange(1)}
                    style={{ textTransform: "none", fontFamily: "Medium" }}
                  >
                    <img src="/left-foot.png" alt="" style={{marginRight: 10}}/> Left Foot
                  </Button>
                </Grid>

                <Grid container item xs={6}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={formData.foot === 2 || formData.foot === 3 ? "custom-btn-secondary-selected" : "select-btn-secondary"}
                    onClick={() => handleFootChange(2)}
                    style={{ textTransform: "none", fontWeight: "100" }}
                  >
                    Right Foot <img src="/right-foot.png" alt="" style={{marginLeft: 10}}/>
                  </Button>
                </Grid>
              </Grid>

              <Typography
                className={classes.subHeading}
                variant="h2"
                gutterBottom
              >
                Looking For
              </Typography>

              <Grid container spacing={1} style={{ marginBottom: 50 }}>
                <Grid container item xs={4}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={
                      amateur
                        ? "custom-btn-secondary-selected"
                        : "select-btn-secondary"
                    }
                    onClick={() => handleContractChange(1)}
                    style={{ textTransform: "none", fontWeight: "100" }}
                  >
                    Semi Pro
                  </Button>
                </Grid>
                <Grid container item xs={4}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={
                      overseas
                        ? "custom-btn-secondary-selected"
                        : "select-btn-secondary"
                    }
                    onClick={() => handleContractChange(2)}
                    style={{ textTransform: "none" }}
                  >
                    Overseas
                  </Button>
                </Grid>

                <Grid container item xs={4}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={
                      professional
                        ? "custom-btn-secondary-selected"
                        : "select-btn-secondary"
                    }
                    onClick={() => handleContractChange(3)}
                    style={{ textTransform: "none", fontWeight: "100" }}
                  >
                    Professional Contract
                  </Button>
                </Grid>
              </Grid>

              <TextField
                variant="outlined"
                placeholder="Enter current club name"
                className={classes.textField}
                value={formData.current_club}
                type="text"
                onChange={(e) => handleCurrentClubChange(e.target.value)}
              />

              {formData.previous_clubs.map((text, index) => (
                <div>
                  <Grid container spacing={2} alignItems="center" key={index}>
                    {index === 0 ? (
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          placeholder="Enter previous club name(s)"
                          className={classes.textField}
                          value={text}
                          type="text"
                          onChange={(e) => handlePrevClubChange(index, e)}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={10}>
                          <TextField
                            variant="outlined"
                            placeholder="Enter previous club name(s)"
                            className={classes.textField}
                            value={text}
                            type="text"
                            onChange={(e) => handlePrevClubChange(index, e)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            disableElevation
                            variant="contained"
                            color="secondary"
                            onClick={(e) => removeClubField(index, e)}
                            style={{
                              marginBottom: 30,
                              paddingTop: 15,
                              paddingBottom: 15,
                              float: "right",
                              backgroundColor: "#D8D8D8",
                            }}
                          >
                            <ClearIcon />
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
              ))}

              <CustomPrimaryButton
                text="Save and Continue"
                handleClick={handleSaveAndContinue}
              />

              <div style={{ marginTop: 50 }}>
                <CustomSecondaryButton
                  text="Go Back"
                  handleClick={handelGoBack}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default SchoolPlayerRegisterStep2;
