import React, { useState, useEffect } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Avatar,
  Grid,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { AUTH_ROUTES, HOME_ROUTES } from "../../../constants/site-routes";
import { useSelector } from "react-redux";
import { PhotoCamera } from "@material-ui/icons";
import Notify from "../../../components/Common/Notify";
import Navbar from "../../../layouts/Navigation";
import AppColor from "../../../utils/color";
import CustomButton from "../../../components/Common/CustomButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      width: "50%",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px auto",
      width: "50%",
    },
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "30px",
  },
  radioButton: {
    marginBottom: "30px",
  },
  slider: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  error: {
    color: "#f44336",
  },

  avatar: {
    width: 150,
    height: 150,
    borderRadius: 80,
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    border: `6px ${AppColor.newDarkBlue} solid`,
  },
}));

const Profile = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authorize);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);

  async function handleEditProfile() {
    navigate(HOME_ROUTES.PROFILE_EDIT);
  }

  const handelEditPlayerProfile = async () => {
    if (user.is_profile_completed === "1") {
      navigate(HOME_ROUTES.PROFILE_STEP_2);
    } else {
      navigate(AUTH_ROUTES.REGISTER_STEP_2);
    }
  };

  const handelEditAgentProfile = async () => {
    navigate(HOME_ROUTES.PROFILE_AGENT);
  };

  const handelEditSchoolProfile = async () => {
    navigate(HOME_ROUTES.PROFILE_SCHOOL);
  };

  useEffect(() => {
    setSelectedImage(user.avatar_url);
  }, [user]);

  return (
    <>
      <Page className={classes.root} title="Profile" backgroundColor="#ffffff">
        <Navbar />
        <Container className={classes.container}>
          <Notify />
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              fontFamily: "Noventa812, sans-serif",
            }}
          >
            Profile
          </Typography>
          <Box className={classes.fromBlock}>
            <div style={{ width: "100%", textAlign: "center" }}>
              {selectedImage ? (
                <div style={{ position: "relative" }}>
                  <Avatar src={selectedImage} className={classes.avatar} />
                </div>
              ) : (
                <IconButton
                  color="primary"
                  style={{
                    color: "#76ebf4",
                    padding: "60px",
                    borderRadius: "20px",
                    border: "#76ebf4 solid 1px",
                  }}
                >
                  <PhotoCamera />
                </IconButton>
              )}

              <Typography
                style={{ fontFamily: "SemiBold", fontSize: 24, marginTop: 20 }}
              >
                {user.full_name}
              </Typography>

              <Typography
                style={{
                  marginBottom: 50,
                  fontFamily: "Regular",
                  color: "#57585B",
                  fontSize: 14,
                }}
              >
                {user.email}
              </Typography>
            </div>

            <Grid
              container
              spacing={0}
              style={{
                textAlign: "center",
                marginBottom: 60,
                borderRadius: 5,
                padding: 10,
                backgroundColor: "rgba(194, 194, 194, 0.265)",
                border: `1px solid rgb(87, 109, 109, 0.2)`,
              }}
            >
              <Grid item xs={1} direction="column">
                <img src="/icons/menuplayer.png" width={20} alt=""/>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontFamily: "SemiBold",
                    textAlign: "left",
                    marginLeft: "8%",
                  }}
                >
                  {user.views} Profile views in this week
                </Typography>
              </Grid>
            </Grid>

            <CustomButton
              text="Edit Profile"
              handleClick={handleEditProfile}
              style={{ marginTop: 20 }}
            />

            {user.type === 2 && (
              <div style={{ marginTop: 20 }}>
                <CustomButton
                  text="Edit Player Profile"
                  handleClick={handelEditPlayerProfile}
                  style={{ marginTop: 20 }}
                />
              </div>
            )}

            {user.type === 1 && user.is_profile_completed !== "1" && (
              <div style={{ marginTop: 20 }}>
                <CustomButton
                  text="Edit Agent Profile"
                  handleClick={handelEditAgentProfile}
                  style={{ marginTop: 20 }}
                />
              </div>
            )}

            {user.type === 5 && user.is_profile_completed !== "1" && (
              <div style={{ marginTop: 20 }}>
                <CustomButton
                  text="Edit School Profile"
                  handleClick={handelEditSchoolProfile}
                  style={{ marginTop: 20 }}
                />
              </div>
            )}
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default Profile;
