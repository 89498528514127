import React, { useState } from "react";
import Page from "../components/Page";
import { makeStyles } from "@material-ui/styles";
import { Box, TextareaAutosize, Typography } from "@material-ui/core";
import SimpleBackdrop from "../components/Common/SimpleBackdrop";
import AppColor from "../utils/color";
import CustomPrimaryButton from "../components/Common/CustomPrimaryButton";
import Notify from "../components/Common/Notify";
import { useDispatch } from "react-redux";
import { sendContact } from "../stores/actions/Authorize";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  heading: {
    fontSize: 32,
    fontWeight: "bold",
    fontFamily: "Noventa812, sans-serif",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
    width: "100%",
    textAlign: "center",
  },

  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    height: "15vh",
    textAlign: "center",
  },

  bottomBox: {
    height: "70vh",
    padding: 50,
    backgroundColor: "white",
    borderRadius: "20px 20px 0px 0px",
  },

  textFieldBlock: {
    height: "100px",
    width: "70%",
    fontFamily: "Medium",
    margin: "10px auto",
  },

  textField: {
    width: "100%",
    backgroundColor: "#eaf9f9",
  },

  forgotPassword: {
    marginBottom: "50px",
    color: "white",
    marginRight: "20%",
  },

  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "10px auto",
      width: "50%",
    },
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  textarea: {
    width: "99.50%!important",
    marginBottom: "30px",
    fontFamily: "Medium",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    borderRadius: 20,
    border: "0px solid #7CD1DC",
    resize: "none",
    height: "200px !important",
    paddingTop: 10,
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading(true);
    await dispatch(sendContact({ message: message }));
    setMessage("");
    setLoading(false);
  };

  return (
    <div style={{ background: "white" }}>
      <Page className={classes.root} title="Contact Us">
        {loading && <SimpleBackdrop />}
        <Typography className={classes.heading}>Contact Us</Typography>
        <Box className={classes.bottomBox}>
          <div style={{ width: "50%", margin: "auto" }}>
            <Notify />
            <TextareaAutosize
              minRows={5}
              variant="outlined"
              placeholder=" Enter your message here"
              value={message}
              className={classes.textarea}
              onChange={(e) => setMessage(e.target.value)}
            />
            <CustomPrimaryButton text="Submit" handleClick={handleSubmit} />
          </div>
        </Box>
      </Page>
    </div>
  );
};

export default ContactUs;
