import { Grid, Typography, Button, Box, Paper, useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

function PerfectMatch() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const title = {
    color: "#84ECF5",
    fontSize: isMobile ? 32 : 59,
    fontFamily: "Medium",
    letterSpacing: "-1px",
    lineHeight: 1.1,
  };

  const description = {
    color: "white",
    fontSize: isMobile ? 16 : 18,
    marginTop: 30,
    fontFamily: "Regular",
    lineHeight: 1.8,
    lineSpacing: "0px",
  };
  const signupButton = {
    color: "white",
    backgroundColor: "black",
    boxShadow: "rgb(91, 238, 246) 0px 0px 8px 1px",
    borderRadius: 28,
    width: isMobile ? 172 : 162,
    height: 56,
    marginTop: "10%",
  };

  const signupText = {
    marginLeft: 15,
    marginRight: 15,
    fontSize: 16,
    fontWeight: "bold",
    color: "#84ECF5",
  };

  const box = {
    display: "flex",
    alignItems: "center",
    maxWidth: isMobile ? "100%" : "85%",
    margin: "0 auto",
    background: " radial-gradient(circle at top right, #1D1D1D, #000000)",
    borderRadius: isMobile ? 0 : 8,
  };

  const transparent = {
    backgroundColor: "transparent",
  };

  const textContainer = {
    ...transparent,
    paddingTop: "10%",
    paddingBottom: "0%",
    paddingLeft: isMobile ? "10%" : "0%",
    paddingRight: isMobile ? "10%" : "0%",
  };

  const bannerImage = {
    width: isMobile ? "100%" : "117%",
    borderTopRightRadius: 22,
    marginRight: isMobile ? "0%" : "5%",
    background: "transparent",
    marginLeft: isMobile ? "0%" : "-17%",
  };

  return (
    <div style={{ marginTop: "10%" }}>
      <Box style={box}>
        <Grid container spacing={0} columns={12}>
          <Grid item md={6}>
            <Paper style={textContainer} elevation={0} justifyContent="center">
              <Typography style={title}>
                {"Find A Perfect Match With Talentlockr's Filter tool."}
              </Typography>
              <Typography style={description}>
                This powerful feature enables users to narrow down their search
                by choosing specific criteria such as Member Type, Price,
                Position, Looking For, Passport, Weight (Kg), Height (Cm),
                Gender and Age.
              </Typography>
              <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }} to={isMobile ? "/mobile" : "/iam"}>
                <Button style={signupButton}>
                  <Typography style={signupText}>Sign up now</Typography>
                </Button>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={6}>
            <Paper style={transparent}>
              <img src={isMobile ? "/images/img_p_5.png" : "/images/img6.png"} style={bannerImage} alt="Your" />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default PerfectMatch;
