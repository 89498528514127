import React, { useState, useEffect, useRef } from "react";
import Page from "../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  IconButton,
  Container,
  TextareaAutosize,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTES } from "../../constants/site-routes";
import CustomPrimaryButton from "../../components/Common/CustomPrimaryButton";
import CustomSecondaryButton from "../../components/Common/CustomSecondaryButton";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../components/Common/Notify";
import {
  updateProfile,
  updateCoachProfile,
  updateTeamProfile,
} from "../../stores/actions/Authorize";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import AppColor from "../../utils/color";
import { LoadScript } from "@react-google-maps/api";
import Configs from "../../configs/Configs";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "30px",
    width: "50%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  fromBlock: {
    margin: "30px auto",
    // [theme.breakpoints.down("sm")]: {
    //   width: "80%",
    // },
    // [theme.breakpoints.up("md")]: {
    //   width: "50%",
    // },
    // [theme.breakpoints.up("xl")]: {
    //   width: "50%",
    // },
  },
  textField: {
    width: "100%",
    marginBottom: "30px",
    backgroundColor: "#E6E7E8",
    fontFamily: "Bai Jamjuree, sans-serif",
    borderRadius: 60,
  },
  textarea: {
    width: "97%!important",
    marginBottom: "30px",
    fontFamily: "Bai Jamjuree, sans-serif",
    backgroundColor: "#E6E7E8",
    borderRadius: 4,
    resize: "none",
    height: 80,
    paddingLeft: 15,
    paddingTop: 10,
    borderColor: "#E6E7E8",
  },
  radioButton: {
    marginBottom: "30px",
  },
  slider: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  error: {
    color: "#f44336",
  },
  title: {
    fontWeight: "bold",
    fontSize: "40px",
    fontFamily: "Noventa812, sans-serif",
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
}));

const RegisterTeamProfile = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authorize);
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [firstName, setFirstname] = useState(false);
  const [location, setLocation] = useState("");
  const [achievement, setAchievements] = useState("");
  const [lookingFor, setLookingFor] = useState([]);
  const [dob, setDob] = useState("");
  const [curentTeam, setCurrentTeam] = useState("");
  const [previousTeam, setPreviousTeam] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const libraries = ["places"];
  const googleMapsApiKey = Configs.GOOGLE_API_KEY;
  const contracts = ["Amateur", "Overseas", "Professional Contracts"];
  const [aboutClub, setAboutClub] = useState("");

  const lookingForName = (contract) => {
    if (contract === "1") return "Amateur";
    if (contract === "2") return "Overseas";
    if (contract === "3") return "Professional Contracts";
    return "";
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function reformatDate(date) {
    const parts = date.split("/");
    const day = String(parseInt(parts[0], 10)).padStart(2, "0");
    const month = String(parseInt(parts[1], 10)).padStart(2, "0");
    const year = String(parseInt(parts[2], 10)).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  async function handleSaveChanges() {
    setLoading(true);
    let response = null;
    if (user.type === 3) {
      const params = {
        name: firstName,
        location: location,
        latitude: latitude,
        longitude: longitude,
        achievement: achievement,
        current_team: curentTeam,
        previous_team: previousTeam,
        image: image,
      };

      if (dob) {
        const date = new Date(dob);
        params.date_of_birth = formatDate(date);
      }

      if (lookingFor.length > 0) {
        const lookings = [];
        lookingFor.map((contract) =>
          lookings.push(contracts.indexOf(contract) + 1)
        );
        params.looking_for = lookings;
      }

      response = await dispatch(updateCoachProfile(params));
    } else if (user.type === 4) {
      const params = {
        name: firstName,
        location: location,
        latitude: latitude,
        longitude: longitude,
        about_club: aboutClub,
        image: image,
      };
      response = await dispatch(updateTeamProfile(params));
    } else {
      response = await dispatch(
        updateProfile({
          image: image,
          first_name: firstName,
          email: user.email,
        })
      );
    }

    setLoading(false);
    if (response.status === 200) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setInterval(() => {
        window.location.href = HOME_ROUTES.PROFILE;
      }, 2000);
    }
  }

  const handelSkip = async () => {
    navigate(HOME_ROUTES.INDEX);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setSelectedImage(user.avatar_url);
    setFirstname(user.full_name);

    if (user.type === 3 || user.type === 4) {
      setLocation(user.location);
      setLatitude(user.latitude);
      setLongitude(user.longitude);
    }

    if (user.type === 4) {
      setAboutClub(user.team?.about_club);
    }

    if (user.type === 3) {
      const contracts = [];
      user.coach.contracts.map((contract) => {
        if (lookingForName(contract.contract)) {
          contracts.push(lookingForName(contract.contract));
        }
        return contracts;
      });
      setLookingFor(contracts);
      setAchievements(user.coach.achievement);
      if (user.coach.date_of_birth) {
        setDob(reformatDate(user.coach.date_of_birth));
      }
      setCurrentTeam(user.coach.current_team);
      setPreviousTeam(user.coach.previous_team);
      if (user.date_of_birth) {
        setDob(reformatDate(user.date_of_birth));
      }
    }
  }, [user]);

  const handleLocationSelect = async (selectedAddress) => {
    try {
      setLocation(selectedAddress);
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setLatitude(latLng.lat.toString());
      setLongitude(latLng.lng.toString());
    } catch (error) {
      setLocation(user.location);
      setLatitude(user.latitude);
      setLongitude(user.longitude);
      console.log(error);
    }
  };

  const handleOpen = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  const clearImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Page
        className={classes.root}
        title="Set Profile"
        backgroundColor="#ffffff"
      >
        {loading && <SimpleBackdrop />}
        <Container className={classes.container}>
          <Notify />
          <Box className={classes.subTopBox}>
            <Typography className={classes.title}>INFO</Typography>
          </Box>
          <Typography
            variant="h4"
            style={{ fontWeight: "bold", marginTop: 30, fontFamily: "Bai Jamjuree, sans-serif" }}
          >
            Welcome
          </Typography>
          <Typography
            style={{ marginTop: 10, fontSize: 14, color: AppColor.textColor, fontFamily: "Bai Jamjuree, sans-serif" }}
          >
            Please upload a profile photo to be added on your profile
          </Typography>
          <Box className={classes.fromBlock}>
            <div style={{ width: "100%", marginBottom: 30 }}>
              <div style={{ marginBottom: "30px" }}>
                <LoadScript
                  googleMapsApiKey={googleMapsApiKey}
                  libraries={libraries}
                >
                  <PlacesAutocomplete
                    value={location}
                    onChange={setLocation}
                    onSelect={handleLocationSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: location ?? "Enter your location",
                          })}
                          className="MuiInputBase-input"
                          style={{
                            padding: "18.5px 0px 18.5px 0.5px",
                            backgroundColor: "#E6E7E8",
                            fontFamily: "Bai Jamjuree, sans-serif",
                            borderRadius: 60,
                            paddingLeft: 15,
                            width: "98%",
                            border: 0,
                          }}
                        />
                        <div>
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion, index) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                            };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                                style={{
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  textAlign: "initial",
                                }}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </LoadScript>
              </div>

              <Box>
                <TextareaAutosize
                  minRows={5}
                  variant="outlined"
                  placeholder="About Our Club"
                  value={aboutClub}
                  className={classes.textarea}
                  onChange={(e) => setAboutClub(e.target.value)}
                />
              </Box>

              <Typography variant="h5" style={{ fontWeight: "bold", fontFamily: "Bai Jamjuree, sans-serif" }}>
                Upload Profile Photo
              </Typography>

              {selectedImage ? (
                <div style={{ position: "relative", width: 80, marginTop: 10 }}>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    width="80px"
                    height="80px"
                    style={{ borderRadius: "20px" }}
                  />
                  <IconButton
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "2px",
                      backgroundColor: "gray",
                      borderRadius: "0 20px 0 0px",
                    }}
                    onClick={clearImage}
                  >
                    <Close />
                  </IconButton>
                </div>
              ) : (
                <div style={{ position: "relative", width: 80, marginTop: 10 }}>
                  <IconButton
                    color="primary"
                    onClick={handleOpen}
                    style={{
                      color: "#76ebf4",
                      padding: "0px",
                      borderRadius: "10px",
                      border: "#76ebf4 solid 1px",
                      height: 80,
                      width: 80,
                      backgroundColor: "#e3fbfc",
                    }}
                  >
                    <img
                      src="/images/profile.png"
                      alt="Selected"
                      style={{ height: 30, width: 30 }}
                    />
                  </IconButton>
                </div>
              )}

              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none", paddingLeft: "10px" }}
                id="imageInput"
                ref={imageRef}
              />
            </div>

            <CustomPrimaryButton
              text="Save & Continue"
              handleClick={handleSaveChanges}
            />

            <div style={{ marginTop: 30 }}>
              <CustomSecondaryButton text="Skip" handleClick={handelSkip} />
            </div>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default RegisterTeamProfile;
