import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import Configs from "../configs/Configs";

const firebaseConfig = {
  apiKey: Configs.FIREBASE_API_KEY,
  authDomain: Configs.FIREBASE_AUTH_DOMAIN,
  databaseURL: Configs.FIREBASE_DATABASE_URL,
  projectId: Configs.FIREBASE_PROJECT_ID,
  storageBucket: Configs.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Configs.FIREBASE_MESSAGE_SENDER_ID,
  appId: Configs.FIREBASE_API_ID,
  measurementId: Configs.FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
