import { Grid, Typography, Button, Box, Paper, useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

function ConnectCustomize({featuresRef}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const title = {
    color: "#84ECF5",
    fontSize: isMobile ? 32 : 59,
    fontWeight: "Medium",
    letterSpacing: "-1px",
    lineHeight: 1.1,
    fontFamily: "Medium",
  };

  const description = {
    color: "white",
    fontSize: 18,
    marginTop: 30,
    fontFamily: "regular",
    letterSpacing: "0px",
    lineHeight: 1.8,
  };

  const signupButton = {
    color: "white",
    backgroundColor: "black",
    boxShadow: "rgb(91, 238, 246) 0px 0px 8px 1px",
    borderRadius: 28,
    width: 162,
    height: 56,
    marginTop: "20%",
  };

  const signupText = {
    color: "#5BEEF6",
    marginLeft: 15,
    marginRight: 15,
    fontSize: 16,
    fontFamily: "Bold",
  };

  const transparent = {
    backgroundColor: "transparent",
  };

  const box = {
    display: "flex",
    alignItems: "center",
    maxWidth: "85%",
    margin: "0 auto",
    background: "black",
  };
  return (
    <div style={{ marginTop: "10%" }} ref={featuresRef}>
      <Box sx={box}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={isMobile ? 12 : 3}>
            <Paper style={{ background: "black", textAlign: "left", }}>
              <Typography style={title}>
                <br />
                Connect With Other Users.{" "}
              </Typography>

              <Typography style={description}>
                Connect with athletes, scouts, agents, and coaches from around
                the world in real-time, fostering meaningful conversations and
                building valuable relationships. "
              </Typography>
                <Paper
                  style={{
                    ...transparent,
                    textAlign: isMobile ? "center" : "left",
                  }}
                  elevation={0}
                >
                <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }} to={isMobile ? "/mobile" : "/iam"}>
                  <Button style={signupButton}>
                    <Typography style={signupText}>{isMobile ? "Download now" : "Sign up now"}</Typography>
                  </Button>
                </Link>
              </Paper>
            </Paper>
          </Grid>
          <Grid item xs={isMobile ? 12 : 6}>
            <Paper style={{ background: "black", textAlign: "center" }}>
              <img
                src={isMobile ? "/images/img_p_3.png" : "/images/img5.png"}
                style={{
                  width: isMobile ? "100%" : "130%",
                  marginLeft: isMobile ? "0%" : "-5%",
                  paddingBottom: isMobile ? "0%" : "5%",
                  marginTop: !isMobile ? "11%" : "0%",
                }}
                alt=""
              />
            </Paper>
          </Grid>
          <Grid item xs={isMobile ? 12 : 3}>
            <Paper style={{ background: "black", textAlign: isMobile ? "left" : "right", }}>
              <Typography style={title}>
                <br />
                Customise The User Experience.
              </Typography>
              <Typography style={description}>
                The Favourites tab offers users the flexibility to curate a
                personalized section based on their preferences. This feature
                enables you to easily manage your preferred connections,
              </Typography>
              <Paper
                style={{
                  ...transparent,
                  textAlign: isMobile ? "center" : "left",
                }}
                elevation={0}
              >
                <Link style={{ textDecoration: 'none', color: 'inherit', textTransform: "none", textAlign: isMobile ? "center" : "left", }} to={isMobile ? "/mobile" : "/iam"}>
                  <Button style={signupButton}>
                    <Typography style={signupText}>{isMobile ? "Download now" : "Sign up now"}</Typography>
                  </Button>
                </Link>
              </Paper>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default ConnectCustomize;
