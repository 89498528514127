import React, { useState, useRef, useEffect } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  IconButton,
  Container,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import { HOME_ROUTES } from "../../../constants/site-routes";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../../components/Common/Notify";
import { addSchool } from "../../../stores/actions/Authorize";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import AppColor from "../../../utils/color";
import { LoadScript } from "@react-google-maps/api";
import Configs from "../../../configs/Configs";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import EditIcon from "@material-ui/icons/Edit";
import Navbar from "../../../layouts/Navigation";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      // width: '80%',
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      // width: '50%',
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px auto",
      // width: '50%',
    },
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "30px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#e3fbfc",
    borderRadius: 4,
    border: "1px solid #7CD1DC",
  },
  textarea: {
    width: "97%!important",
    marginBottom: "30px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#e3fbfc",
    borderRadius: 4,
    border: "1px solid #7CD1DC",
    resize: "none",
    height: 80,
    paddingLeft: 15,
    paddingTop: 10,
  },
  radioButton: {
    marginBottom: "30px",
  },
  slider: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  error: {
    color: "#f44336",
  },
}));

const SchoolProfile = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authorize);
  const imageRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [highlight_link, setHighlightLink] = useState("");
  const [about, setAbout] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState("");
  const libraries = ["places"];
  const googleMapsApiKey = Configs.GOOGLE_API_KEY;

  async function handleSaveChanges() {
    setLoading(true);
    const params = {
      name: name,
      highlight_link: highlight_link,
      location: location,
      latitude: latitude,
      longitude: longitude,
      about: about,
      image: image,
    };
    let response = await dispatch(addSchool(params));

    setLoading(false);
    if (response.status === 200) {
      window.location.href = HOME_ROUTES.PROFILE;
    }
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLocationSelect = async (selectedAddress) => {
    try {
      setLocation(selectedAddress);
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setLatitude(latLng.lat.toString());
      setLongitude(latLng.lng.toString());
    } catch (error) {
      setLocation(user.location);
      setLatitude(user.latitude);
      setLongitude(user.longitude);
      console.log(error);
    }
  };

  useEffect(() => {
    setSelectedImage(user.avatar_url);
    setName(user.school?.name);
    setHighlightLink(user.school?.highlight_link);
    setAbout(user.school?.about);
    setLocation(user.location);
    setLatitude(user.latitude);
    setLongitude(user.longitude);
  }, [user]);

  return (
    <>
      <Page
        className={classes.root}
        title="Set School"
        backgroundColor="#ffffff"
      >
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container}>
          <Notify />
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            Edit School Profile
          </Typography>
          <Box className={classes.fromBlock}>
            <div style={{ width: "100%", marginBottom: 30, textAlign: 'center' }}>
              {selectedImage ? (
                <div style={{ position: "relative" }}>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    width="150px"
                    height="150px"
                    style={{ borderRadius: "20px" }}
                  />
                  <label htmlFor="imageInput">
                    <IconButton variant="contained" component="span">
                      <EditIcon />
                    </IconButton>
                  </label>
                </div>
              ) : (
                <div style={{ position: "relative" }}>
                  <img
                    src="/images/logo.png"
                    alt="Selected"
                    width="150px"
                    height="150px"
                    style={{ borderRadius: "20px" }}
                  />
                  <label htmlFor="imageInput">
                    <IconButton variant="contained" component="span">
                      <EditIcon />
                    </IconButton>
                  </label>
                </div>
              )}

              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none", paddingLeft: "10px" }}
                id="imageInput"
                ref={imageRef}
              />

              <TextField
                variant="outlined"
                placeholder="Enter you school's name"
                value={name}
                className={classes.textField}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                variant="outlined"
                placeholder="Highlight Link"
                value={highlight_link}
                className={classes.textField}
                onChange={(e) => setHighlightLink(e.target.value)}
              />

              <div style={{ marginBottom: "30px" }}>
                <LoadScript
                  googleMapsApiKey={googleMapsApiKey}
                  libraries={libraries}
                >
                  <PlacesAutocomplete
                    value={location}
                    onChange={setLocation}
                    onSelect={handleLocationSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter your location",
                          })}
                          className="MuiInputBase-input"
                          style={{
                            padding: "18.5px 0px 18.5px 0.5px",
                            borderColor: AppColor.appBlueSecondary,
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderRadius: "4px",
                            fontFamily: "Montserrat, sans-serif",
                            backgroundColor: AppColor.fieldBackground,
                            paddingLeft: 15,
                            width: "97%",
                          }}
                        />
                        <div>
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion, index) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                            };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                                style={{
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  textAlign: "initial",
                                }}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </LoadScript>
              </div>

              <Box>
                <TextareaAutosize
                  minRows={5}
                  variant="outlined"
                  placeholder="About"
                  value={about}
                  className={classes.textarea}
                  onChange={(e) => setAbout(e.target.value)}
                />
              </Box>
            </div>

            <CustomPrimaryButton
              text="Save Changes"
              handleClick={handleSaveChanges}
            />
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default SchoolProfile;
