import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import AppColor from "../utils/color";

const AgentInformation = ({ player }) => {
  const [openAchievement, setOpenAchievement] = useState(false);

  const subTitleCSS = {
    textAlign: "start",
    fontFamily: "Medium",
    fontSize: 18,
  };

  const titleCSS = {
    fontFamily: "Medium",
    fontSize: 14,
    color: AppColor.newTextColor,
  };

  return (
    <Card
      style={{ boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
      elevation={0}
    >
      <CardHeader
        title={
          <Typography style={{ fontFamily: "Bold", fontSize: 20 }}>
            Agent Information
          </Typography>
        }
        action={
          <IconButton
            onClick={() => setOpenAchievement(!openAchievement)}
            aria-label="expand"
            size="small"
          >
            {openAchievement ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        }
      ></CardHeader>
      <div>
        <Collapse in={openAchievement} timeout="auto" unmountOnExit>
          <CardContent>
            <Container
              sx={{
                height: 100,
                lineHeight: 2,
              }}
            >
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography style={titleCSS}>Agent Name</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={subTitleCSS}>
                    {player.user?.full_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography style={titleCSS}>Email</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={subTitleCSS}>
                    {player.user?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </CardContent>
        </Collapse>
      </div>
    </Card>
  );
};

export default AgentInformation;
