import { Container, Grid, List, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page from "../../components/Page";
import { app } from "../../services/Firebase";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDatabase, onValue, ref } from "firebase/database";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import Navbar from "../../layouts/Navigation";
import ConversationItem from "../../components/ConversationItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  chatSection: {
    marginTop: 10,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
}));

export default function MessageRequests() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const db = getDatabase(app);
  const user = useSelector((state) => state.authorize);
  const [conversations, setConversations] = useState(null);

  useEffect(() => {
    setLoading(true);
    const conversationQuery = ref(db, `conversations/${user.id}`);
    return onValue(
      conversationQuery,
      (snapshot) => {
        setLoading(false);
        var dataArray = [];
        snapshot.forEach(function (childSnapshot) {
          var childData = childSnapshot.val();
          console.log(childData);
          if (
            childData.conversationStatus === 0 &&
            user.id + 1 === childData.receiverStatus
          ) {
            dataArray.push(childData);
          }
        });
        setConversations(
          [...dataArray].sort((a, b) => b["created_at"] - a["created_at"])
        );
      },
      {
        onlyOnce: true,
      }
    );
  }, [db, user.id]);

  return (
    <Page className={classes.root} title="Messages" backgroundColor="#ffffff">
      {loading && <SimpleBackdrop />}
      <Navbar />
      <Container className={classes.container}>
        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            fontFamily: "Noventa812, sans-serif",
          }}
        >
          Messages
        </Typography>
        <Typography
          style={{
            fontFamily: "SemiBold",
            fontSize: 14,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          Message Request
        </Typography>
        <Grid
          container
          component={Paper}
          className={classes.chatSection}
          elevation={0}
        >
          <Grid item xs={12}>
            <List className={classes.messageArea}>
              {conversations &&
                conversations.map((conversation, index) => (
                  <div key={index}>
                    <ConversationItem
                      conversation={conversation}
                      receiver_id={user.id}
                    />
                  </div>
                ))}

              {conversations && conversations.length < 1 && (
                <Typography
                  style={{
                    fontFamily: "Medium",
                    fontSize: 12,
                    textAlign: "center",
                    width: "100%",
                    marginTop: "20%",
                    marginBottom: "20%",
                  }}
                >
                  {" "}
                  No Messages Request
                </Typography>
              )}
            </List>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
