import React, { useState, useRef } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { AUTH_ROUTES } from "../../../constants/site-routes";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";
import Logo from "../../../components/Common/Logo";
import CustomSecondaryButton from "../../../components/Common/CustomSecondaryButton";
import { logout, registerPlayerStep1 } from "../../../stores/actions/Authorize";
import { clearAccessToken, setPlayerId } from "../../../services/Storage";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import Notify from "../../../components/Common/Notify";
import AppColor from "../../../utils/color";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "30vh",
    },
  },
  bottomBox: {
    backgroundColor: theme.palette.btnColors.white,
    borderRadius: "20px 20px 0px 0px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      height: "70vh",
    },
  },
  fromBlock: {
    margin: "10px auto",
    width: "40%",
    // [theme.breakpoints.down("sm")]: {
    //   margin: "10px auto",
    //   width: "60%",
    // },
    // [theme.breakpoints.up("md")]: {
    //   margin: "10px auto",
    //   width: "50%",
    // },
    // [theme.breakpoints.up("xl")]: {
    //   margin: "10px auto",
    //   width: "50%",
    // },
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "50px",
    fontFamily: "Montserrat, sans-serif",
  },

  highlightIcon: {
    marginLeft: -50,
  },
}));

const RegisterStep1 = () => {
  const user = useSelector((state) => state.authorize);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const imageRef = useRef(null);
  const highlightRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedHighlight, setSelectedHighlight] = useState(null);
  const [firstVideo, setFirstVideo] = useState(null);
  const [secondVideo, setSecondVideo] = useState(null);
  const [image, setImage] = useState(null);
  const [highlight, setHighlight] = useState(null);

  async function handleSaveAndContinue() {
    const data = {
      photos: [image, highlight],
      video_links: [firstVideo, secondVideo],
      agent_id: user?.agent?.id ?? null,
    };
    setLoading(true);
    let response = await dispatch(registerPlayerStep1(data));
    setPlayerId(response.data.id);
    setLoading(false);
    if (response.status === 200) {
      navigate(AUTH_ROUTES.REGISTER_STEP_2);
    }
  }

  const handleLogout = async () => {
    setLoading(true);
    await dispatch(logout());
    setLoading(false);
    clearAccessToken();
    window.location.href = AUTH_ROUTES.LOGIN;
  };

  const handleOpen = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
      setImage(file);
    }
  };

  const clearImage = () => {
    setSelectedImage(null);
  };

  const handleOpenHighlight = () => {
    if (highlightRef.current) {
      highlightRef.current.click();
    }
  };

  const handleHighlightChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedHighlight(e.target.result);
      };
      reader.readAsDataURL(file);
      setHighlight(file);
    }
  };

  const clearHighlight = () => {
    setSelectedHighlight(null);
  };

  return (
    <>
      <Page className={classes.root} title="Portfolio Pictures & Videos">
        {loading && <SimpleBackdrop />}
        <Notify />
        <Box className={classes.topBox}>
          <Box className={classes.subTopBox}>
            <Logo />
          </Box>
        </Box>
        <Box className={classes.bottomBox} style={{paddingBottom: 100}}>
          <Box className={classes.fromBlock}>
            <Box
              className={classes.textFieldBlock}
              style={{ paddingTop: "50px" }}
            >
              <Typography
                style={{ fontSize: 20, fontWeight: "500" }}
                variant="h2"
                gutterBottom
              >
                Add Portfolio Pictures & Videos
              </Typography>
              <Typography style={{ color: AppColor.textColor, fontSize: 13 }}>
                Please add a minimun of 1 photo and 1 highlight clip to showcase
                your talent. Highlights are crucial part to being recurited.
              </Typography>
              <Grid container spacing={0} style={{ marginTop: 20 }}>
                <Grid container item xs={3}>
                  {selectedImage ? (
                    <div style={{ position: "relative" }}>
                      <img
                        src={selectedImage}
                        alt="Selected"
                        width="80px"
                        height="80px"
                        style={{ borderRadius: "20px" }}
                      />
                      <IconButton
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "2px",
                          backgroundColor: "gray",
                          borderRadius: "0 20px 0 0px",
                        }}
                        onClick={clearImage}
                      >
                        <Close />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={handleOpen}
                      style={{
                        color: "#76ebf4",
                        padding: "0px",
                        borderRadius: "10px",
                        border: "#76ebf4 solid 1px",
                        height: 80,
                        width: 80,
                        backgroundColor: "#e3fbfc",
                      }}
                    >
                      <img
                        alt="photocamera"
                        src="/images/profile.png"
                        style={{ height: 30, width: 30 }}
                      />
                      {/* <PhotoCamera /> */}
                    </IconButton>
                  )}
                </Grid>
                <Grid container item xs={3}>
                  {selectedHighlight ? (
                    <div
                      style={{ position: "relative" }}
                      className={classes.highlightIcon}
                    >
                      <img
                        src={selectedHighlight}
                        alt="Selected"
                        width="80px"
                        height="80px"
                        style={{ borderRadius: "20px" }}
                      />
                      <IconButton
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "2px",
                          backgroundColor: "gray",
                          borderRadius: "0 20px 0 0px",
                        }}
                        onClick={clearHighlight}
                      >
                        <Close />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={handleOpenHighlight}
                      className={classes.highlightIcon}
                      style={{
                        color: "#76ebf4",
                        padding: "0px",
                        borderRadius: "10px",
                        border: "#76ebf4 solid 1px",
                        height: 80,
                        width: 80,
                        backgroundColor: "#e3fbfc",
                      }}
                    >
                      {/* <PhotoCamera /> */}
                      <img
                        alt="photocamera"
                        src="/images/profile.png"
                        style={{ height: 30, width: 30 }}
                      />
                    </IconButton>
                  )}
                </Grid>

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="imageInput"
                  ref={imageRef}
                />

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleHighlightChange}
                  style={{ display: "none" }}
                  id="highlightInput"
                  ref={highlightRef}
                />
              </Grid>
              <Typography
                style={{
                  color: "rgb(122 114 114)",
                  fontSize: 14,
                  marginTop: 35,
                }}
              >
                Add URL of your videos
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Enter URL of Video"
                className={classes.textField}
                value={firstVideo}
                onChange={(e) => setFirstVideo(e.target.value)}
              />
              <TextField
                variant="outlined"
                placeholder="Enter URL of Video"
                className={classes.textField}
                value={secondVideo}
                onChange={(e) => setSecondVideo(e.target.value)}
              />
              <CustomPrimaryButton
                text="Save and Continue"
                handleClick={handleSaveAndContinue}
              />
              {user && user.type !== 1 && (
                <div style={{ marginTop: 50 }}>
                  <CustomSecondaryButton
                    text="Logout"
                    handleClick={handleLogout}
                  />
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default RegisterStep1;
