import React from "react";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import AppColor from "../../utils/color";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "100vh",
    zIndex: "999",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backdropFilter: "blur(3px)",
    backgroundColor: `rgba(0,0,0,0.6)`,
  },
  boxContainer: {
    width: "320px",
    height: "320px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: "32px 24px",
    border: `2px solid ${AppColor.appBlueSecondary}`,
  },
  headImg: {
    width: "90px",
  },
  topBox: {
    textAlign: "center",
    justifyContent: "center",
    gap: "16px",
  },
  subHeading: {
    fontSize: "16px",
    lineHeight: "22px",
  },
  bottomBox: {
    marginTop: "24px",
    gap: "10px",
  },
  mainButton: {
    width: "100%",
    padding: "8px",
    fontWeight: "600",
    fontSize: "18px",
    backgroundColor: `${AppColor.newLightBlue}`,
    "&:hover": {
      backgroundColor: `${AppColor.newDarkBlue}`,
    },
  },
  secondayButton: {
    width: "100%",
    padding: "8px",
    fontSize: "18px",
    color: `${AppColor.black}`,
    "&:hover": {
      backgroundColor: `${AppColor.grayBackground}`,
    },
  },
}));

const SubscriptionModal = ({ setSubscriptionModal }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.boxContainer}>
        <Grid container className={classes.topBox}>
          <img
            className={classes.headImg}
            src="/icons/subscription_currency.png"
            alt="subscription"
          />
          <p className={classes.subHeading}>
            You are in free version plan so, please subscribe the plan to access
            premium members.
          </p>
        </Grid>

        <Grid container className={classes.bottomBox}>
          <Button className={classes.mainButton}>Subscribe</Button>
          <Button
            onClick={() => setSubscriptionModal(false)}
            className={classes.secondayButton}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </Box>
  );
};

export default SubscriptionModal;
