import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, ButtonBase, Box } from "@material-ui/core";
import Page from "../../components/Page";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { AUTH_ROUTES } from "../../constants/site-routes";
import CustomPrimaryButton from "../../components/Common/CustomPrimaryButton";
import CustomSecondaryButton from "../../components/Common/CustomSecondaryButton";
import * as Storage from "../../services/Storage";
import AppColor from "../../utils/color";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  block: {
    display: "block",
    padding: "0rem 1rem 1rem 1rem",
    border: `#ffffff solid 1px`,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      width: "50%",
      flexBasis: "48%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "10px auto",
      width: "50%",
      flexBasis: "48%",
    },
  },
  blockActive: {
    display: "block",
    padding: "0rem 1rem 1rem 1rem",
    border: `#5BEDF6 solid 1px`,
    borderRadius: "5px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      width: "50%",
      flexBasis: "48%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "10px auto",
      width: "50%",
      flexBasis: "48%",
    },
    backgroundColor: "#5BEDF6",
  },
  img: {
    width: "50px",
    height: "50px",
    padding: "5px",
    backgroundColor: `${AppColor.fieldBackground}`,
    paddingTop: "20px",
    marginTop: -1,

    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  imgActive: {
    width: "50px",
    height: "50px",
    padding: "5px",
    backgroundColor: "#ffffff",
    paddingTop: "20px",
    marginTop: -1,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  blockTextContainer: {
    // margin: "0rem 1rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50%",
    },
  },
  heading: {
    fontFamily: "SemiBold",
    fontSize: 20,
    paddingTop: 20,
  },
  fromBlock: {
    margin: "10px auto",
    width: "35%",
  },
  title: {
    fontWeight: "bold",
    fontSize: "40px",
    fontFamily: "Noventa812, sans-serif",
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },

  iam: {
    textAlign: "center",
    fontSize: 35,
    fontWeight: "800",
    marginTop: 20,
    width: "60%",
    fontFamily: "SemiBold",
  },
}));

export default function IAM() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeGrid, setActiveGrid] = useState(null);
  const data = [
    {
      id: 0,
      title: "Player",
      description:
        "Upload your player profile and highlights to connect to teams, coaches and agents around the world.",
      type: "2",
      image: "/img/types/player.svg",
    },
    {
      id: 1,
      title: "Coach / Scout",
      description:
        "Sign up as a Coach or a Scout to search and connect with players and teams around the world.",
      type: "3",
      image: "/img/types/coach.svg",
    },
    {
      id: 2,
      title: "Team",
      description:
        "Signup as a team to search and connect with players, coaches and agents around the world",
      type: "4",
      image: "/img/types/team.svg",
    },
    {
      id: 3,
      title: "Agent",
      description:
        "Upload your player's profiles and highlights to be connected directly from Teams and coaches around the world",
      type: "1",
      image: "/img/types/agent.svg",
    },
    {
      id: 4,
      title: "Schools",
      description:
        "Sign up as sports school to recurit and showcase your school and students, and to connect with others arount the world",
      type: "5",
      image: "/img/types/agent.svg",
    },
  ];

  useEffect(() => {
    Storage.setUserType(2);
  }, []);

  const handleClickContinue = (e) => {
    e.preventDefault();
    navigate(AUTH_ROUTES.REGISTER);
  };

  const handleClickGoBack = () => {
    navigate(AUTH_ROUTES.HOME);
  };

  const handleSelectType = async (type, index) => {
    await Storage.clearUserType();
    await Storage.setUserType(type);
    setActiveGrid(index);
  };

  const IamBlocks = ({ item, isActive }) => {
    return (
      <Grid
        container
        style={{ width: "70%", margin: "10px auto", cursor: "pointer" }}
        onClick={() => handleSelectType(item.type, item.id)}
      >
        <Grid container>
          <Grid
            container
            item
            sm={12}
            md={6}
            className={isActive ? classes.blockActive : classes.block}
          >
            <Grid item>
              <ButtonBase
                style={{
                  background: "white",
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <img
                  className={isActive ? classes.imgActive : classes.img}
                  alt="complex"
                  src={item.image}
                />
              </ButtonBase>
            </Grid>
            <Grid
              item
              xs={12}
              sm
              container
              className={classes.blockTextContainer}
            >
              <Grid item xs container direction="column">
                <Grid item xs>
                  <Typography gutterBottom className={classes.heading}>
                    {item.title}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 12,
                      color: "#57585B",
                      fontFamily: "Medium",
                      lineHeight: 1.2,
                      letterSpacing: 0,
                      marginTop: -4,
                      paddingBottom: 13,
                    }}
                  >
                    {item.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const gridItems = data.map((item) => (
    <IamBlocks
      isActive={(!activeGrid && item.id === 0) || item.id === activeGrid}
      item={item}
      key={item.id}
    />
  ));

  return (
    <Page className={classes.root} title="IAM" backgroundColor="#ffffff">
      <div className={classes.root}>
        <Box className={classes.subTopBox}>
          <Typography className={classes.title}>PROFILE</Typography>
        </Box>
        <Grid
          container
          style={{ width: "70%", margin: "10px auto", cursor: "pointer" }}
        >
          <Grid container>
            <Typography component={"h1"} className={classes.iam}>
              I AM
            </Typography>
          </Grid>
        </Grid>
        {gridItems}
        <Box className={classes.fromBlock} style={{ textAlign: "center" }}>
          <Box
            className={classes.textFieldBlock}
            style={{ paddingTop: "30px" }}
          >
            <CustomPrimaryButton
              text="Continue"
              handleClick={handleClickContinue}
            />
          </Box>
          <Box className={classes.textFieldBlock} style={{ marginTop: 25 }}>
            <CustomSecondaryButton
              text="Go Back"
              handleClick={handleClickGoBack}
            />
          </Box>
        </Box>
      </div>
    </Page>
  );
}
