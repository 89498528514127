import { Box, Button, AppBar, Toolbar, Typography, useTheme, useMediaQuery, Grid, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

function ApppBar({scrollToFeatures, scrollToTestimonials, scrollToProfiles}) {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const signUpButton = {
    color: "white",
    backgroundColor: "black",
    boxShadow: "rgb(91, 238, 246) 0px 0px 8px 1px",
    borderRadius: 28,
    width: mobileDevice ? 115 : 162,
    height: mobileDevice ? 30 : 56,
    marginLeft: "2%",
    marginTop: mobileDevice ? "3%" : "-2%",
  };
  const login = {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Medium",
  };
  const signupText = {
    marginLeft: 15,
    marginRight: 15,
    fontSize: mobileDevice ? 10 : 16,
    color: "#84ECF5",
    fontFamily: "SemiBold",
  };
  const generalLabel = {
    color: "white",
    fontSize: 16,
    fontFamily: "Medium",
    textTransform: "none",
  };

  return (
    <div>
      <AppBar style={{backgroundColor: "black"}} elevation={1}>
        <Toolbar style={{ justifyContent: "space-between", marginTop: "2%" }}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Paper style={{ background: "black" }} elevation={0}>
                <img src="/images/logo.png" style={{ width: mobileDevice ? 141 : 206 }} alt=""/>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                style={{ background: "black", textAlign: "center" }}
                elevation={0}
              >
                {!mobileDevice && (
                  <Box
                    sx={{ flex: 1, display: "flex", justifyContent: "center" }}
                  >
                    <Button style={generalLabel}>
                      <Link to="/about-us" style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }}>
                        About
                      </Link>
                    </Button>
                    <Button style={generalLabel} onClick={scrollToProfiles}>
                      Profiles
                    </Button>
                    <Button style={generalLabel} onClick={scrollToFeatures}>
                      Features
                    </Button>
                    <Button style={generalLabel} onClick={scrollToTestimonials}>
                      Testimonials
                    </Button>
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                style={{ background: "black", textAlign: "right" }}
                elevation={0}
              >
                {!mobileDevice && (
                  <Button style={login}>
                    <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }} to="/login">Login</Link>
                  </Button>
                )}
                <Link style={{ textDecoration: 'none', color: 'inherit',textTransform: "none", }} to={mobileDevice ? "/mobile" : "/iam"}>
                  <Button style={signUpButton}>
                    <Typography style={signupText}>
                      {mobileDevice ? "Download now" : "Sign up now"}
                    </Typography>
                  </Button>
                </Link>
              </Paper>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default ApppBar;
