/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import Navbar from "../../../layouts/Navigation";
import {
  Container,
  Box,
  Button,
  Grid,
  Typography,
  Card,
  Avatar,
} from "@material-ui/core";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { fetchRecord } from "../../../services/Axios";
import { useNavigate, useParams } from "react-router-dom";
import { PLAYERS } from "../../../constants/ApiEndPoint";
import { makeStyles } from "@material-ui/styles";
import { HOME_ROUTES } from "../../../constants/site-routes";
import AppColor from "../../../utils/color";
import PlayerInfromation from "../../../components/PlayerInformation";
import PlayerPosition from "../../../components/PlayerPosition";
import FavouriteItem from "../../../components/FavouriteItem";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";
import AgentInformation from "../../../components/AgentInformation";
import VerifiedPlayer from "../../../components/VerifiedPlayer";

const useStyles = makeStyles((theme) => ({
  detailBlock: {
    padding: "1rem",
    margin: "10px 10px 10px 0px",
    backgroundColor: "#ffffff",
    border: "1px solid #d2d6de",
    boxShadow: "0 1px 1px rgba(0,0,0,0.1)",
  },
  memberTitle: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  memberCurrentClub: {
    fontSize: "14px",
    color: AppColor.textColor,
  },

  viewPlayerHeightLights: {
    width: "100%",
    textTransform: "none",
    height: 50,
    borderRadius: 4,
    fontSize: 16,
    fontFamily: "SemiBold",
    background: "#2F2D2E",
    border: `1px ${AppColor.newLightBlue} solid`,
    color: AppColor.newLightBlue,
  },

  card: {
    marginTop: 70,
    marginBottom: 50,
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
  },

  title: {
    color: AppColor.newTextColor,
    fontFamily: "SemiBold",
    fontSize: 14,
  },

  value: {
    fontFamily: "Medium",
    color: AppColor.newTextColor,
    fontSize: 20,
  },

  floatRight: {
    float: "right",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const MemberDetail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState(null);

  const fetchPlayers = async () => {
    setLoading(true);
    const response = await fetchRecord(PLAYERS.LIST, id);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setPlayer(response.data);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  const handleClick = () => {
    navigate(HOME_ROUTES.PLAYER_HIGHLIGHTS.replace(":id", id));
  };

  const handleContactCoach = () => {
    if (player.school_id) {
      navigate(`${HOME_ROUTES.MESSAGES}/${player.school.user_id}?player=${id}`);
    } else {
      navigate(HOME_ROUTES.MESSAGES + "/" + player.user_player?.id);
    }
  };

  const handleContactAgent = () => {
    navigate(`${HOME_ROUTES.MESSAGES}/${player.agent?.user_id}?player=${id}`);
  };

  const lookingForImage = (contract) => {
    if (contract === "1") return "/icons/amature.png";
    if (contract === "2") return "/icons/overseas.png";
    if (contract === "3") return "/icons/professional.png";
    return "";
  };

  ///css
  const avatar = {
    width: "100%",
    height: 300,
    objectFit: "cover",
    borderRadius: 10,
  };

  return (
    <>
      <Page title={player?.name} backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container style={{ marginTop: 10, width: "60%", marginBottom: 20 }}>
          <div>
            {player && (
              <div>
                <Box>
                  <Avatar
                    src={
                      player.media?.length > 0
                        ? player.media[0].media_url
                        : "/images/logo.png"
                    }
                    style={avatar}
                  />
                </Box>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: -30 }}
                >
                  <Grid item sx={12} style={{ width: "90%" }}>
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      disableElevation
                      color="dark"
                      className={classes.viewPlayerHeightLights}
                    >
                      <img
                        src="/icons/play_circle.png"
                        width={25}
                        style={{ paddingRight: 10 }}
                        alt=""
                      />
                      View Player Highlights
                    </Button>
                  </Grid>
                </Grid>

                <Box style={{ marginTop: 20, marginBottom: 20 }}>
                  <Typography className={classes.memberTitle}>
                    {player.name || ""} { (player?.user_player?.is_subscribe || player?.user_player?.is_super_pro) && (<VerifiedPlayer player={player?.user_player} />) }
                    <div className={classes.floatRight}>
                      <FavouriteItem player={player} />
                      <img src="/icons/img_player.png" width={20} alt=""/>

                      {player.contracts && player.contracts.length > 0 && (
                        <img
                          src={`${lookingForImage(
                            player.contracts[0].contract
                          )}`}
                          width={20}
                          style={{ marginTop: 7 }}
                          alt=""
                        />
                      )}
                    </div>
                  </Typography>
                  <Typography className={classes.memberCurrentClub}>
                    {player.agent ? (
                      <>
                        <span style={{ color: AppColor.appBlue }}>
                          <img
                            src="/icons/agent_icon.png"
                            width={15}
                            style={{ objectFit: "contain" }}
                            alt=""
                          />
                        </span>{" "}
                        <span style={{ color: AppColor.newTextColor }}>
                          | {player.user_player?.location}
                        </span>{" "}
                      </>
                    ) : (
                      player.location
                    )}
                  </Typography>
                </Box>
                <Card className={classes.card} elevation={0}>
                  <Grid container style={{ padding: 10 }}>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                      <Typography className={classes.title}>Age</Typography>
                      <Typography className={classes.value}>
                        {player.age}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                      <Typography className={classes.title}>Height</Typography>
                      <Typography className={classes.value}>
                        {player.height} CM
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                      <Typography className={classes.title}>Weight</Typography>
                      <Typography className={classes.value}>
                        {player.weight} KG
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>

                <PlayerInfromation player={player} />

                <br />

                {player.user_player?.is_agent_player === 1 && (
                  <>
                    <AgentInformation player={player} />
                    <br />
                  </>
                )}

                <PlayerPosition positions={player.positions} />

                <Box style={{ marginTop: 50, marginBottom: 50 }}>
                  {player.user_player?.is_agent_player === 1 ? (
                    <CustomPrimaryButton
                      text="Contact Agent"
                      handleClick={handleContactAgent}
                    />
                  ) : (
                    <CustomPrimaryButton
                      text="Contact Player"
                      handleClick={handleContactCoach}
                    />
                  )}
                </Box>
              </div>
            )}
          </div>
        </Container>
      </Page>
    </>
  );
};

export default MemberDetail;
