import React, { useEffect, useState } from "react";
import Page from "../components/Page";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import SimpleBackdrop from "../components/Common/SimpleBackdrop";
import AppColor from "../utils/color";
import { SettingKey } from "../constants/enum";
import { fetchRecord } from "../services/Axios";
import { HOME_ROUTES } from "../constants/site-routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  heading: {
    fontSize: 32,
    fontWeight: "bold",
    fontFamily: "Noventa812, sans-serif",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
    width: "100%",
    textAlign: "center",
  },

  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },

  topBox: {
    height: "14vh",
    textAlign: "center",
  },

  bottomBox: {
    padding: 20,
    backgroundColor: "white",
    borderRadius: "20px 20px 0px 0px",
    textAlign: "center",
    height: "65vh",
  },

  textFieldBlock: {
    height: "100px",
    width: "70%",
    fontFamily: "Montserrat, sans-serif",
    margin: "10px auto",
  },

  textField: {
    width: "100%",
    backgroundColor: "#eaf9f9",
  },

  forgotPassword: {
    marginBottom: "50px",
    color: AppColor.appBlueSecondary,
    marginRight: "20%",
  },

  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "10px auto",
      width: "50%",
    },
  },

  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },

  bottomBoxHeading: {
    fontSize: "30px",
    fontWeight: "600",
    textDecoration: "underline",
    color: AppColor.alertTitleColor,
  },

  bottomBoxSubHeading: {
    fontSize: "20px",
    fontWeight: "600",
    marginTop: "20px",
    color: AppColor.alertTitleColor,
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [aboutUs, setAboutUs] = useState("");

  const fetchSetting = async () => {
    setLoading(true);
    const response = await fetchRecord(
      HOME_ROUTES.SETTINGS,
      SettingKey.about_us
    );
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setAboutUs(response.data?.value);
    }
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  return (
    <div style={{ background: "white" }}>
      <Page className={classes.root} title="About Us">
        {loading && <SimpleBackdrop />}
        <Box className={classes.topBox}>
          <Typography className={classes.heading}>About Us</Typography>
        </Box>
        <Box className={classes.bottomBox}>
          <Typography className={classes.bottomBoxHeading}>
            Talent Lockr - About us
          </Typography>
          <Typography className={classes.bottomBoxSubHeading}>
            Unlock a world of talent
          </Typography>
          <Typography>
            {aboutUs.split("\n").map((str) => (
              <p>{str}</p>
            ))}
          </Typography>
        </Box>
      </Page>
    </div>
  );
};

export default AboutUs;
