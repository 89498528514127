import * as Authorize from '../../services/Authorize';
import * as Storage from '../../services/Storage';
import { setNotify } from './Notify';

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER';

export const receiveCurrentUser = (user) => ({
  type: RECEIVE_CURRENT_USER,
  user,
});

const logoutCurrentUser = () => ({
  type: LOGOUT_CURRENT_USER,
});

export const login = (user) => async (dispatch) => {
  const response = await Authorize.login(user);
  if (response.status === 200) {
    await Storage.clearAccessToken();
    await Storage.setAccessToken(response.data.access_token);
    dispatch(receiveCurrentUser(response.data));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const register = (user) => async (dispatch) => {
  const response = await Authorize.signup(user);
  if (response.status === 200) {
    await Storage.clearAccessToken();
    await Storage.setAccessToken(response.data.access_token);
    dispatch(receiveCurrentUser(response.data));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const logout = () => async (dispatch) => {
  const response = await Authorize.logout();
  await Storage.clearAccessToken();
  if (response) {
    dispatch(logoutCurrentUser());
  } else if (response && response.status === 401) {
    dispatch(logoutCurrentUser());
  }
  window.state().authorize = false;
  dispatch(setNotify(response));
};

export const registerPlayerStep1 = (data) => async (dispatch) => {
  const response = await Authorize.registerPlayerStep1(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const registerPlayerStep2 = (data) => async (dispatch) => {
  const response = await Authorize.registerPlayerStep2(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const registerSchoolPlayerStep1 = (data) => async (dispatch) => {
  const response = await Authorize.registerSchoolPlayerStep1(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const registerSchoolPlayerStep2 = (data) => async (dispatch) => {
  const response = await Authorize.registerSchoolPlayerStep2(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const registerPlayerStep3 = (data) => async (dispatch) => {
  const response = await Authorize.registerPlayerStep3(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const updatePlayerStep1 = (profile) => async (dispatch) => {
  const response = await Authorize.updatePlayerStep1(profile);
  if (response.status === 200) {
    // dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const updatePlayerStep2 = (profile) => async (dispatch) => {
  const response = await Authorize.updatePlayerStep2(profile);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const updateProfile = (data) => async (dispatch) => {
  const response = await Authorize.updateProfile(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const updateCoachProfile = (data) => async (dispatch) => {
  const response = await Authorize.updateCoachProfile(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const updateTeamProfile = (data) => async (dispatch) => {
  const response = await Authorize.updateTeamProfile(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const forgotPassword = (data) => async (dispatch) => {
  const response = await Authorize.forgotPassword(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const addSport = (data) => async (dispatch) => {
  const response = await Authorize.addSport(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const addSchoolSport = (data) => async (dispatch) => {
  const response = await Authorize.addSchoolSport(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const addAgency = (data, id) => async (dispatch) => {
  const response = await Authorize.addAgency(data, id);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const addSchool = (data, id) => async (dispatch) => {
  const response = await Authorize.addSchool(data, id);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const addFavourite = (data) => async () => {
  return Authorize.addFavourite(data);
};

export const sendContact = (data) => async (dispatch) => {
  const response = await Authorize.sendContact(data);
  if (response.status === 200) {
    response.message = response.data;
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const deleteAccount = () => async (dispatch) => {
  const response = await Authorize.deleteAccount();
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const updatePassword = (data) => async (dispatch) => {
  const response = await Authorize.updatePassword(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const reportUser = (data) => async (dispatch) => {
  const response = await Authorize.reportUser(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const storeEnquiry = (data) => async (dispatch) => {
  const response = await Authorize.storeEnquiry(data);
  if (response.status === 200) {
    dispatch(setNotify(response));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};

export const ssoLogin = (data, provider) => async (dispatch) => {
  const response = await Authorize.ssoLogin(data, provider);
  if (response.status === 200) {
    await Storage.clearAccessToken();
    await Storage.setAccessToken(response.data.access_token);
    dispatch(receiveCurrentUser(response.data));
    return response;
  }
  dispatch(setNotify(response));
  return response;
};