/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import Navbar from "../../../layouts/Navigation";
import {
  Avatar,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import PostCard from "./PostCard";
import { fetchRecord } from "../../../services/Axios";
import { POST } from "../../../constants/ApiEndPoint";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTES } from "../../../constants/site-routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  tabs: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "20px",
  },
  tab: {
    marginRight: "20px",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: 500,
  },
  activeTab: {
    color: theme.palette.primary.main,
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    padding: "10px",
    borderRadius: "30px",
    width: "100%",
  },
  avatar: {
    width: "40px",
    height: "40px",
    marginRight: "10px",
    border: "2px solid #00bcd4", // For the cyan border
  },
  writeblock: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  sendButton: {
    fontSize: "14px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "15px",
  },
  charLimit: {
    textAlign: "right",
    marginTop: "5px",
  },
}));

function objectToQueryString(obj) {
  const queryParams = Object.keys(obj)
    .map((key) => {
      if (Array.isArray(obj[key])) {
        let query = "";
        for (let index = 0; index < obj[key].length; index++) {
          if (index === obj[key].length - 1) {
            query += `${key}[]=${obj[key][index]}`;
          } else {
            query += `${key}[]=${obj[key][index]}&`;
          }
        }
        if (query.length > 0) {
          return query;
        }
        return `${key}[]=`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join("&");
  return queryParams;
}

const Discover = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("All");
  const user = useSelector((state) => state.authorize);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (activeTab === "Opportunities") {
      fetchPosts({ type_id: 1, page: page });
    } else if (activeTab === "Achievements") {
      fetchPosts({ type_id: 2, page: page });
    } else if (activeTab === "Events") {
      fetchPosts({ type_id: 3, page: page });
    } else {
      fetchPosts({ page: page });
    }
  }, [activeTab, page]);

  const fetchPosts = async (query) => {
    setLoading(true);
    const queryString = objectToQueryString(query);
    const response = await fetchRecord(POST.LIST, "", "", queryString);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      if (posts && query.page > 1) {
        setPosts([...posts, ...response.data]);
      } else {
        setPosts(response.data);
      }
      if (response.data.length === 0) {
        setHasMoreData(false);
      }
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 1 &&
      !loading &&
      hasMoreData
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setPage(1);
  };

  const openPostModal = () => {
    navigate(HOME_ROUTES.DISCOVER_CREATE);
  };

  return (
    <>
      <Page className={classes.root} title="Discover" backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container} style={{ marginTop: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  fontFamily: "Noventa812, sans-serif",
                }}
              >
                Discover
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.tabs}>
            {["All", "Opportunities", "Achievements", "Events"].map((tab) => (
              <Typography
                key={tab}
                className={`${classes.tab} ${
                  activeTab === tab ? classes.activeTab : ""
                }`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </Typography>
            ))}
          </div>

          <div className={classes.inputContainer}>
            <Avatar
              alt="User Avatar"
              src={user.avatar_url}
              className={classes.avatar}
            />
            <Typography
              placeholder="Write Something..."
              className={classes.writeblock}
              onClick={openPostModal}
            >
              Write Something...
            </Typography>

            <IconButton className={classes.sendButton} onClick={openPostModal}>
              Next
            </IconButton>
          </div>

          {/* Character limit */}
          <Typography className={classes.charLimit} variant="caption">
            character limit <strong>350</strong>
          </Typography>

          <div style={{ marginTop: 20 }}></div>

          {posts.map((post) => (
            <PostCard key={post.id} post={post} />
          ))}
        </Container>
      </Page>
    </>
  );
};

export default Discover;
