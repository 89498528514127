import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
  makeStyles,
  styled,
} from "@material-ui/core";
import AppColor from "../utils/color";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 46,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#2ECA45"
            : `${AppColor.newLightBlue}`,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light"
        ? `${AppColor.lightBlue}`
        : `${AppColor.appBlueSecondary}`,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles((theme) => ({
  toggler: {
    width: "100%",
    justifyContent: "space-between",
    marginTop: "15px",
    backgroundColor: `${AppColor.lightBlue}`,
    padding: "8px 0px",
    borderRadius: "45px",
  },
  heading: {
    marginLeft: 12,
  },
}));

const ViewVerifiedPlayerToggler = ({ setToggleView }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.toggler}>
      <Typography className={classes.heading}>
        View Pro/Verified members only
      </Typography>
      <FormGroup>
        <FormControlLabel
          onClick={() => setToggleView((value) => !value)}
          control={<IOSSwitch sx={{ m: 1 }} />}
        />
      </FormGroup>
    </Grid>
  );
};

export default ViewVerifiedPlayerToggler;
