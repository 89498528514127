import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

const SizeList = ({ ssize, handleSizeChange }) => {
  const [openSize, setOpenSize] = useState(true);
  const sizes = [
    {
      id: 1,
      name: "1 - 49",
    },
    {
      id: 2,
      name: "50 - 249",
    },
    {
      id: 3,
      name: "250 - 999",
    },
    {
      id: 4,
      name: "1000+",
    },
  ];

  return (
    <Card
      style={{ marginTop: 20, marginBottom: 20, boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
      elevation={0}
    >
      <CardHeader
        title={
          <Typography style={{ fontFamily: "Bold", fontSize: 20 }}>
            Size
          </Typography>
        }
        action={
          <IconButton
            onClick={() => setOpenSize(!openSize)}
            aria-label="expand"
            size="small"
          >
            {openSize ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        }
      ></CardHeader>
      <div>
        <Collapse in={openSize} timeout="auto" unmountOnExit>
          <CardContent>
            <Container
              sx={{
                height: 100,
                lineHeight: 2,
              }}
            >
              {sizes.map((size, index) => {
                return <Grid key={index} container item xs={8}>
                  <FormControl row>
                    <FormControlLabel
                      value={size.id}
                      control={<Radio />}
                      label={size.name}
                      labelPlacement="end"
                      onChange={handleSizeChange}
                      checked={ssize === size.id}
                    />
                  </FormControl>
                </Grid>})}
            </Container>
          </CardContent>
        </Collapse>
      </div>
    </Card>
  );
};

export default SizeList;
