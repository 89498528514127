import { Box, IconButton } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { addFavourite } from "../stores/actions/Authorize";
import { BsFillStarFill } from "react-icons/bs";
import { BsStar } from "react-icons/bs";

const FavouriteItem = ({ player }) => {
  const dispatch = useDispatch();
  const [favourite, setFavourite] = React.useState(player.is_saved);

  const handleFavourite = async (id, type) => {
    setFavourite(type);
    await dispatch(addFavourite({id: id}));
  };

  return (
    <Box>
      {favourite ? (
        <IconButton onClick={() => handleFavourite(player.id, false)} style={{height:50, width: 50}}>
          <BsFillStarFill style={{width: 100}} />
        </IconButton>
      ) : (
        <IconButton onClick={() => handleFavourite(player.id, true)} style={{height:50, width: 50}}>
          <BsStar style={{width: 100}} />
        </IconButton>
      )}
    </Box>
  );
};

export default FavouriteItem;
