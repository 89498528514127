const accessTokenIdentifierKey = 'accessToken';
const userType = 'userType';
const fcmIdentifierKey = 'fcmToken';
const playerIdKey = 'playerId';
const sportIdKey = 'sportId';

export const setAccessToken = token => {
  return localStorage.setItem(accessTokenIdentifierKey, token);
};

export const getAccessToken = () => {
  return localStorage.getItem(accessTokenIdentifierKey);
};

export const clearAccessToken = () => {
  return localStorage.removeItem(accessTokenIdentifierKey);
};

export const setUserType = type => {
  return localStorage.setItem(userType, type);
};

export const getUserType = () => {
  return localStorage.getItem(userType);
};

export const clearUserType = () => {
  return localStorage.removeItem(userType);
};

export const setFirebaseToken = token => {
  return localStorage.setItem(fcmIdentifierKey, token);
};

export const getFirebaseToken = () => {
  return localStorage.getItem(fcmIdentifierKey);
};

export const setPlayerId = token => {
  return localStorage.setItem(playerIdKey, token);
};

export const getPlayerId = () => {
  return localStorage.getItem(playerIdKey) ?? null;
};

export const setSportId = token => {
  return localStorage.setItem(sportIdKey, token);
};

export const getSportId = () => {
  return localStorage.getItem(sportIdKey) ?? null;
};