import { Typography, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";

function DownloadScreen() {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const boldText = {
    fontSize: mobileDevice ? 40 : 74,
    color: "white",
    whiteSpace: "pre-line",
    fontFamily: "Bold",
    marginTop: mobileDevice ? "15%" : "2%",
  };

  const downloadText = {
    fontSize: mobileDevice ? 40 : 74,
    color: "white",
    whiteSpace: "pre-line",
    fontFamily: "Bold",
    marginTop: mobileDevice ? 0 : -30,
  };

  const joinText = {
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: mobileDevice ? 16 : 18,
    fontFamily: "Medium",
  };

  const image = {
    width: mobileDevice ? 150 : 200,
    padding: 6,
  };

  const overlayImage = {
    position: "absolute",
    top: "2%",
    left: "20%",
    width: "60%",
    height: "auto",
    display: "block",
    opacity: 1,
  };
  const backgroundImage = {
    width: "100%",
    height: "auto",
    display: "block",
    // marginTop: "-12%",
  };
  const grid = { textAlign: "center", background: "black" };

  return (
    <div style={{ marginTop: mobileDevice ? "12%" : "6%" }}>
      <Grid item style={grid}>
        <Typography style={boldText}>Download.</Typography>
        <Typography style={downloadText}>Upload. Connect.</Typography>
        <Typography style={joinText}>
          Join the Revolution in Sport Recruitment
        </Typography>

        <Grid columns style={{ marginTop: 10 }}>
          {/* <img src="/img/apple.png" style={image} alt=""/>
          <img src="/img/android.png" style={image} alt=""/> */}
          <Link to="https://apps.apple.com/us/app/talent-lockr/id6444275012">
            <img src="/img/apple.png" style={image} alt=""/>
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=io.everestapps.talentlockrapp&pli=1">
            <img src="/img/android.png" style={image} alt=""/>
          </Link>
        </Grid>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: 15, position: "relative" }}
        >
          <img
            src="/img/img1.png"
            alt="Overlay"
            style={overlayImage}
          />
          <img
            src="/img/ecllipse.png"
            alt="Background"
            style={backgroundImage}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default DownloadScreen;
