import React from "react";
import { Button } from "@material-ui/core";
import "./button.css";

function CustomSecondaryButton({ text, handleClick }) {
  return (
    <Button
      variant="contained"
      className="custom-btn-secondary custom-secondary-btn"
      onClick={handleClick}
      disableElevation
      style={{
        textTransform: "none",
        height: 54,
        boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
        fontFamily: "Medium",
      }}
    >
      {text}
    </Button>
  );
}

export default CustomSecondaryButton;
