/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import Navbar from "../../../layouts/Navigation";
import {
  Container,
  Grid,
  Typography,
  ButtonBase,
  Select,
  MenuItem,
  FormControl,
  Avatar,
  Button,
} from "@material-ui/core";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { fetchRecord } from "../../../services/Axios";
import { useNavigate, useParams } from "react-router-dom";
import { PLAYERS } from "../../../constants/ApiEndPoint";
import { HOME_ROUTES } from "../../../constants/site-routes";
import AppColor from "../../../utils/color";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import AgentFilterModal from "../../../components/AgentFilterModal";
import FavouriteItem from "../../../components/FavouriteItem";
import { useSelector } from "react-redux";
import { BiSortDown } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  block: {
    padding: "1rem",
    // margin: "10px 10px 10px 0px",
    backgroundColor: "white",
    border: `1px solid white`,
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
  },
  img: {
    width: "100px",
    height: "100px",
    borderRadius: 4,
  },
  blockTextContainer: {
    margin: "0rem 1rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50%",
    },
  },
  heading: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
  memberBlock: {
    marginBottom: 20,
    width: "100%",
    padding: "1rem",
    // margin: "10px 10px 10px 0px",
    backgroundColor: "white",
    border: `1px solid white`,
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
  },
  memberTitle: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  memberLocation: {
    fontSize: "14px",
  },
  memberLabel: {
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "#00000090",
    color: "#ffffff",
    padding: 2,
    fontSize: 12,
    fontWeight: "300",
    borderTopRightRadius: 4,
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: 4,
    padding: 5,
    backgroundColor: "#91919159",
    "& input": {
      flex: 1,
      border: "none",
      outline: "none",
      padding: 5,
      backgroundColor: "#91919100",
      fontSize: 16,
      color: "#000000",
    },
    "& input::placeholder": {
      color: "#000000",
    },
    "& button": {
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      padding: 5,
    },
  },
  formControl: {
    height: 50,
    minWidth: "100%",
    backgroundColor: "#91919159",
    borderRadius: 4,
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
    },
    "& fieldset": {
      display: "none",
    },
  },
  filterBlock: {
    display: "flex",
    justifyContent: "flex-end",
  },
  filterIcon: {
    backgroundColor: "#91919159",
    borderWidth: 0,
    cursor: "pointer",
  },
}));

function objectToQueryString(obj) {
  const queryParams = Object.keys(obj)
    .map((key) => {
      if(Array.isArray(obj[key])) {
        let query = "";
        for (let index = 0; index < obj[key].length; index++) {
          if (index === obj[key].length - 1) {
            query+= `${key}[]=${obj[key][index]}`;
          } else {
            query+= `${key}[]=${obj[key][index]}&`;
          }
        }
        if(query.length > 0) {
          return query;
        }
        return `${key}[]=`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join("&");
  return queryParams;
}

const SchoolPlayers = () => {
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Successfully obtained the user's current position
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          // Handle error (e.g., user denied location access)
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error("Geolocation is not supported by your browser");
    }
  }, []);


  const user = useSelector((state) => state.authorize);
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState(null);
  const [open, setOpen] = useState(false);
  const [q, setName] = useState("");
  const [distance, setDistance] = useState("Any");
  const [page, setPage] = useState(1);
  const [extraFilters, setExtraFilters] = useState({});
  const [openSort, setOpenSort] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const fetchMembers = async (query) => {
    setLoading(true);
    const queryString = objectToQueryString(query);
    const response = await fetchRecord(PLAYERS.SCHOOL, id, "", queryString);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      if (members && query.page > 1) {
        setMembers([...members, ...response.data]);
      } else {
        setMembers(response.data);
      }
    }
  };

  const handelFilter = async (data) => {
    setShowSort(false);
    // setMembers(null);
    setExtraFilters(data);
    const filters = {
      ...data,
      ...{
        q,
        distance,
        per_page: 20,
        page: page,
        latitude,
        longitude,
      },
    };
    const query = {};
    const useFilter = data.useFilter ?? false;

    if (!useFilter && distance === "Any") {
      query.name = filters.q;
      query.page = filters.page;
    } else if (!useFilter && distance !== "Any") {
      query.name = filters.q;
      query.page = filters.page;
      query.distance = filters.distance;
      query.latitude = filters.latitude;
      query.longitude = filters.longitude;
    } else if (useFilter && distance !== "Any") {
        setShowSort(true);
        query.name = filters.q;
        query.page = filters.page;
        query.distance = filters.distance;
        query.latitude = filters.latitude;
        query.longitude = filters.longitude;
        query.member_type = 4;
        query.lower_price = filters.lower_price;
        query.higher_price = filters.higher_price;
        query.positions = filters.positions;
        query.passports = filters.passports;
        query.want_contracts = filters.want_contracts;
        query.lower_height = filters.lower_height;
        query.higher_height = filters.higher_height;
        query.lower_weight = filters.lower_weight;
        query.higher_weight = filters.higher_weight;
        query.lower_age = filters.lower_age;
        query.higher_age = filters.higher_age;
        query.sort = filters.sort;
        if(filters.gender !== "0") {
          query.gender = filters.gender;
        }
    } else if (useFilter && distance === "Any") {
        setShowSort(true);
        query.name = filters.q;
        query.page = filters.page;
        query.member_type = 4;
        query.lower_price = filters.lower_price;
        query.higher_price = filters.higher_price;
        query.positions = filters.positions;
        query.passports = filters.passports;
        query.want_contracts = filters.want_contracts;
        query.lower_height = filters.lower_height;
        query.higher_height = filters.higher_height;
        query.lower_weight = filters.lower_weight;
        query.higher_weight = filters.higher_weight;
        query.lower_age = filters.lower_age;
        query.higher_age = filters.higher_age;
        query.sort = filters.sort;
        if(filters.gender !== "0") {
          query.gender = filters.gender;
        }
    }

    fetchMembers(query);
  };

  useEffect(() => {
    handelFilter(extraFilters);
  }, [distance, page]);

  const handelClick = (player) => {
    navigate(HOME_ROUTES.MEMBER + "/" + player?.id);
  };

  const handleDistanceChange = (event) => {
    setPage(1);
    setDistance(event.target.value);
  };

  const openModal = () => {
    setOpen(true);
  };

  const openSortModal = () => {
    setOpen(true);
    setOpenSort(true);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 1 &&
      !loading
    ) {
      setPage(page + 1);
      // fetchMembers({
      //   ...extraFilters,
      //   ...{ q, distance, per_page: 20, page: page + 1 },
      // });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  const handleAddButton = () => {
    navigate(HOME_ROUTES.SCHOOL_PLAYER_REGISTER_STEP_1);
  }

  return (
    <>
      <Page
        className={classes.root}
        title="View Players"
        backgroundColor="#ffffff"
      >
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container} style={{ marginTop: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={8} sm={8} md={8} lg={8}>
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                {user && user.type === 1 ? "My Players" : "View Players"}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <div className={classes.filterBlock}>
                <div>
                  <FilterListIcon
                    onClick={() => openModal()}
                    className={classes.filterIcon}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: 14,
                      marginTop: 5,
                    }}
                  >
                    Filter
                  </div>
                </div>
                {showSort && (
                  <div style={{ marginLeft: 10 }}>
                    <BiSortDown
                      onClick={() => openSortModal()}
                      className={classes.filterIcon}
                      style={{ fontSize: "1.5em" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: 14,
                        marginTop: 5,
                      }}
                    >
                      Sort
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <div className={classes.searchBox}>
                <input
                  type="text"
                  value={q}
                  placeholder="Search..."
                  onChange={(event) => setName(event.target.value)}
                />
                <button
                  onClick={() => {
                    setPage(1);
                    handelFilter(extraFilters);
                  }}
                >
                  <SearchIcon />
                </button>
              </div>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  displayEmpty
                  value={distance}
                  onChange={(event) => handleDistanceChange(event)}
                  label="Age"
                >
                  <MenuItem  value="Any">
                    <em>Any (Km)</em>
                  </MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{ marginTop: 20 }}>
            {members &&
              members.map((player, index) => {
                return (
                  <Grid key={index} container className={classes.memberBlock}>
                    <Grid
                      container
                      item
                      lg={11}
                      sm={11}
                      onClick={() => handelClick(player)}
                      style={{ cursor: "pointer" }}
                    >
                      <Grid item>
                        <ButtonBase className={classes.image}>
                          <Avatar
                            src={
                              player.media?.length > 0
                                ? player.media[0].media_url
                                : player.avatar_url
                            }
                            className={classes.img}
                          />
                          <Typography className={classes.memberLabel}>
                            Player
                          </Typography>
                        </ButtonBase>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm
                        container
                        className={classes.blockTextContainer}
                      >
                        <Grid item xs container direction="column">
                          <Grid item xs>
                            <Typography className={classes.memberTitle}>
                              {player.name || ""}
                            </Typography>
                            <Typography className={classes.memberLocation}>
                              <span style={{ color: AppColor.appBlue }}>A</span>{" "}
                              <span style={{ color: AppColor.lightGray }}>
                                |
                              </span>
                              {" "} {player.location}
                            </Typography>
                            <div style={{ marginTop: 25 }}>
                              {player.positions.map((position, index) => {
                                return (
                                  index < 2 && (
                                    <label
                                      key={index}
                                      style={{
                                        padding: 5,
                                        marginRight: 5,
                                        backgroundColor: "#d2d6de90",
                                        borderRadius: 4,
                                        fontSize: 10,
                                        color: AppColor.textColor,
                                        fontWeight: "500",
                                        fontFamily: "Montserrat, sans-serif",
                                      }}
                                    >
                                      {position.position_name}
                                    </label>
                                  )
                                );
                              })}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item lg={1} sm={1}>
                      <span style={{ float: "right", cursor: "pointer" }}>
                        <FavouriteItem player={player} />
                      </span>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>

          {user && user.type === 5 && (
            <Button
            variant="contained"
            className="custom-btn-primary"
            onClick={handleAddButton}
            style={{
              textTransform: "none",
              height: 54,
              // position: "absolute",
              bottom: 0,
              right: 0,
            }}
            disableElevation
          >
            Add Player
          </Button>
          )}
        </Container>
        <AgentFilterModal
          open={open}
          openSort={openSort}
          handleClose={() => setOpen(false)}
          handleCloseSort={() => {
            setOpen(false);
            setOpenSort(false);
          }}
          handelFilter={(data) => {
            setPage(1);
            handelFilter(data);
          }}
        />
      </Page>
    </>
  );
};

export default SchoolPlayers;
