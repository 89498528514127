import React from "react";
import { Button } from "@material-ui/core";
import "./button.css";

function CustomPrimaryButton({ text, handleClick }) {
  return (
    <Button
      variant="contained"
      className="custom-btn-primary"
      onClick={handleClick}
      style={{ textTransform: "none", height: 54, fontFamily: "Medium" }}
      disableElevation
    >
      {text}
    </Button>
  );
}

export default CustomPrimaryButton;
