import React, { useState, useEffect, useRef } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  IconButton,
  Container,
  TextField,
  TextareaAutosize,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTES } from "../../../constants/site-routes";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../../components/Common/Notify";
import Navbar from "../../../layouts/Navigation";
import EditIcon from "@material-ui/icons/Edit";
import {
  updateProfile,
  updateCoachProfile,
  updateTeamProfile,
} from "../../../stores/actions/Authorize";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import AppColor from "../../../utils/color";
import { LoadScript } from "@react-google-maps/api";
import Configs from "../../../configs/Configs";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CustomButton from "../../../components/Common/CustomButton";
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      // width: '80%',
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      // width: '50%',
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px auto",
      // width: '50%',
    },
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },

  textField: {
    width: "100%",
    marginBottom: "30px",
    fontFamily: "Regular",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    borderRadius: 40,
    border: "0px solid #7CD1DC",
  },

  textarea: {
    width: "97%!important",
    marginBottom: "30px",
    fontFamily: "Regular",
    backgroundColor: AppColor.newTextFieldBackgroundColor,
    borderRadius: 20,
    border: "0px solid #7CD1DC",
    resize: "none",
    height: 80,
    paddingLeft: 15,
    paddingTop: 10,
  },
  radioButton: {
    marginBottom: "30px",
  },
  slider: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  error: {
    color: "#f44336",
  },

  iconButton: {
    background: AppColor.newLightBlue,
    borderRadius: 5,
    width: 11,
    height: 11,
    marginLeft: -17,
    marginBottom: 10,
  },
}));

const EditProfile = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authorize);
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [firstName, setFirstname] = useState(false);
  const [location, setLocation] = useState(user.location ?? "");
  const [achievement, setAchievements] = useState("");
  const [lookingFor, setLookingFor] = useState([]);
  const [dob, setDob] = useState("");
  const [curentTeam, setCurrentTeam] = useState("");
  const [previousTeam, setPreviousTeam] = useState("");
  const [latitude, setLatitude] = useState(user.latitude ?? "");
  const [longitude, setLongitude] = useState(user.longitude ?? "");
  const libraries = ["places"];
  const googleMapsApiKey = Configs.GOOGLE_API_KEY;
  const contracts = ["Amateur", "Overseas", "Professional Contracts"];
  const [aboutClub, setAboutClub] = useState("");
  const [about, setAbout] = useState(user.agent?.agency?.about ?? "");
  const [agencyName, setAgencyName] = useState(user.agent?.agency?.name ?? "");
  // const [abn_license, setAbnLicense] = useState(
  //   user.agent?.agency?.abn_license ?? ""
  // );

  const lookingForName = (contract) => {
    if (contract === "1") return "Amateur";
    if (contract === "2") return "Overseas";
    if (contract === "3") return "Professional Contracts";
    return "";
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function reformatDate(date) {
    const parts = date.split("/");
    const day = String(parseInt(parts[0], 10)).padStart(2, "0");
    const month = String(parseInt(parts[1], 10)).padStart(2, "0");
    const year = String(parseInt(parts[2], 10)).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  async function handleSaveChanges() {
    console.log();
    setLoading(true);
    let response = null;
    if (user.type === 3) {
      const params = {
        name: firstName,
        location: location,
        latitude: latitude,
        longitude: longitude,
        achievement: achievement,
        current_team: curentTeam,
        previous_team: previousTeam,
        image: image,
      };

      if (dob) {
        const date = new Date(dob);
        params.date_of_birth = formatDate(date);
      }

      if (lookingFor.length > 0) {
        const lookings = [];
        lookingFor.map((contract) =>
          lookings.push(contracts.indexOf(contract) + 1)
        );
        params.looking_for = lookings;
      }

      response = await dispatch(updateCoachProfile(params));
    } else if (user.type === 4) {
      const params = {
        name: firstName,
        location: location,
        latitude: latitude,
        longitude: longitude,
        about_club: aboutClub,
        image: image,
      };
      response = await dispatch(updateTeamProfile(params));
    } else if (user.type === 5) {
      const params = {
        first_name: firstName.split(" ")[0],
        last_name: firstName.split(" ")[1] ?? "",
        name: firstName,
        location: location,
        latitude: latitude,
        longitude: longitude,
        about_club: aboutClub,
        image: image,
      };
      response = await dispatch(updateProfile(params));
    } else if (user.type === 2) {
      response = await dispatch(
        updateProfile({
          first_name: firstName.split(" ")[0],
          last_name: firstName.split(" ")[1] ?? "",
          name: firstName,
          image: image,
          email: user.email,
        })
      );
    } else {
      response = await dispatch(
        updateProfile({
          first_name: agencyName.split(" ")[0],
          last_name: agencyName.split(" ")[1] ?? "",
          image: image,
          email: user.email,
          name: agencyName,
          // abn_license: abn_license,
          location: location,
          latitude: latitude,
          longitude: longitude,
          about: about,
        })
      );
    }

    setLoading(false);
    if (response.status === 200) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setInterval(() => {
        window.location.href = HOME_ROUTES.PROFILE;
      }, 2000);
    }
  }

  const handelChangePassword = async () => {
    navigate(HOME_ROUTES.PROFILE_CHANGE_PASSWORD);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setSelectedImage(user.avatar_url);
    setFirstname(user.full_name);

    if (user.type === 3 || user.type === 4) {
      setLocation(user.location);
      setLatitude(user.latitude);
      setLongitude(user.longitude);
    }

    if (user.type === 4) {
      setAboutClub(user.team.about_club);
    }

    if (user.type === 5) {
      setAboutClub(user.school.about);
    }

    if (user.type === 3) {
      const contracts = [];
      user.coach.contracts.map((contract) => {
        if (lookingForName(contract.contract)) {
          contracts.push(lookingForName(contract.contract));
        }
        return contracts;
      });
      setLookingFor(contracts);
      setAchievements(user.coach.achievement);
      if (user.coach.date_of_birth) {
        setDob(reformatDate(user.coach.date_of_birth));
      }
      setCurrentTeam(user.coach.current_team);
      setPreviousTeam(user.coach.previous_team);
      if (user.date_of_birth) {
        setDob(reformatDate(user.date_of_birth));
      }
    }
  }, [user]);

  const handleLocationSelect = async (selectedAddress) => {
    try {
      setLocation(selectedAddress);
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setLatitude(latLng.lat.toString());
      setLongitude(latLng.lng.toString());
    } catch (error) {
      setLocation(user.location);
      setLatitude(user.latitude);
      setLongitude(user.longitude);
      console.log(error);
    }
  };

  const handleLookingForChange = (event) => {
    const lookingFors = [...event.target.selectedOptions].map(option => option.value);
    setLookingFor(lookingFors);
  };

  return (
    <>
      <Page
        className={classes.root}
        title="Edit Profile"
        backgroundColor="#ffffff"
      >
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container}>
          <Notify />
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              fontFamily: "Noventa812, sans-serif",
            }}
          >
            Profile
          </Typography>
          <Box className={classes.fromBlock}>
            <div
              style={{ width: "100%", textAlign: "center", marginBottom: 10 }}
            >
              {selectedImage ? (
                <div style={{ position: "relative" }}>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    width="150px"
                    height="150px"
                    style={{ borderRadius: "20px" }}
                  />
                  <label htmlFor="imageInput">
                    <IconButton
                      variant="contained"
                      component="span"
                      className={classes.iconButton}
                    >
                      <EditIcon />
                    </IconButton>
                  </label>
                </div>
              ) : (
                <div style={{ position: "relative" }}>
                  <img
                    src="/images/logo.png"
                    alt="Selected"
                    width="150px"
                    height="150px"
                    style={{ borderRadius: "20px", objectFit: "contain"}}
                  />
                  <label htmlFor="imageInput">
                    <IconButton
                      variant="contained"
                      component="span"
                      className={classes.iconButton}
                    >
                      <EditIcon />
                    </IconButton>
                  </label>
                </div>
              )}

              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none", paddingLeft: "10px" }}
                id="imageInput"
                ref={imageRef}
              />

              {user.type === 1 ? (
                <>
                  <TextField
                    variant="outlined"
                    placeholder="Enter you agency's name"
                    value={agencyName}
                    className={classes.textField}
                    onChange={(e) => setAgencyName(e.target.value)}
                  />
                  {/* <TextField
                    variant="outlined"
                    placeholder="ABN / License number"
                    value={abn_license}
                    className={classes.textField}
                    onChange={(e) => setAbnLicense(e.target.value)}
                  /> */}
                </>
              ) : (
                <TextField
                  variant="outlined"
                  placeholder="Enter Name"
                  value={firstName}
                  onChange={(e) => setFirstname(e.target.value)}
                  className={classes.textField}
                />
              )}

              {(user.type === 3 || user.type === 4 || user.type === 1) && (
                <div style={{ marginBottom: "30px" }}>
                  <LoadScript
                    googleMapsApiKey={googleMapsApiKey}
                    libraries={libraries}
                  >
                    <PlacesAutocomplete
                      value={location}
                      onChange={setLocation}
                      onSelect={handleLocationSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: location ?? "Enter your location",
                            })}
                            className="MuiInputBase-input"
                            style={{
                              padding: "18.5px 0px 18.5px 0.5px",
                              borderColor: AppColor.appBlueSecondary,
                              borderStyle: "solid",
                              borderWidth: "0px",
                              borderRadius: "30px",
                              fontFamily: "Medium",
                              backgroundColor:
                                AppColor.newTextFieldBackgroundColor,
                              paddingLeft: 15,
                              width: "97%",
                            }}
                          />
                          <div>
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion, index) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41b6e6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                  style={{
                                    backgroundColor: "white",
                                    padding: "10px",
                                    borderRadius: "30px",
                                    cursor: "pointer",
                                    textAlign: "initial",
                                  }}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </LoadScript>
                </div>
              )}

              {user.type === 1 && (
                <Box>
                  <TextareaAutosize
                    minRows={5}
                    variant="outlined"
                    placeholder="About"
                    value={about}
                    className={classes.textarea}
                    onChange={(e) => setAbout(e.target.value)}
                  />
                </Box>
              )}

              {user.type === 3 && (
                <Box>
                  <TextareaAutosize
                    minRows={5}
                    variant="outlined"
                    placeholder="Achievements (Short description)"
                    value={achievement}
                    className={classes.textarea}
                    onChange={(e) => setAchievements(e.target.value)}
                  />

                  <FormControl
                    style={{
                      width: "100%",
                      marginBottom: 30,
                      border: "0px solid #7CD1DC",
                      borderRadius: 30,
                      "& .MuiInputBaseRoot": {
                        borderBottom: "none", // Remove the underline
                      },
                      backgroundColor: AppColor.newTextFieldBackgroundColor,
                      marginTop: -5,
                    }}
                  >
                    <InputLabel
                      id="multiple-select-label"
                      style={{ marginLeft: 10, marginBottom: 14 }}
                    >
                      Looking For
                    </InputLabel>
                    <Select2
                      multiple
                      value={ lookingFor }
                      data={contracts}
                      options={
                        {
                          placeholder: 'Select Positions',
                        }
                      }
                      onSelect={handleLookingForChange}
                    />
                  </FormControl>

                  <TextField
                    variant="outlined"
                    placeholder="Date of Birth (DD/MM/YYYY)"
                    className={classes.textField}
                    type="date"
                    name="dob"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    placeholder="Current Team"
                    value={curentTeam}
                    className={classes.textField}
                    onChange={(e) => setCurrentTeam(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    placeholder="Previous Team"
                    value={previousTeam}
                    className={classes.textField}
                    onChange={(e) => setPreviousTeam(e.target.value)}
                  />
                </Box>
              )}

              {user.type === 4 && (
                <Box>
                  <TextareaAutosize
                    minRows={5}
                    variant="outlined"
                    placeholder="About Our Club"
                    value={aboutClub}
                    className={classes.textarea}
                    onChange={(e) => setAboutClub(e.target.value)}
                  />
                </Box>
              )}
            </div>

            <CustomPrimaryButton
              text="Save Changes"
              handleClick={handleSaveChanges}
            />

            <div style={{ marginTop: 30 }}>
              <CustomButton
                text="Change Password"
                handleClick={handelChangePassword}
              />
            </div>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default EditProfile;
