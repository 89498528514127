const AppColor = {
  lightTextColor: "#8d99a5",
  blue: "#0fb4f0",
  buttonBackground: "#f1f2f4",
  Divider: {
    dividerColor: "#f6f7f8",
    dividerDark: "#dddedf",
  },
  dividerColor: "#f6f7f8",
  dividerDar: "#dddedf",
  appBlue: "#04abed",
  subTitleColor: "#9A9B9C",
  lightBlue: "#cceefb",
  lightGray: "#d8d9db",
  iconGray: "#98a1aa",
  borderColor: "#e0e2e5",
  appBlack: "#8A96A3",
  white: "#ffffff",
  appBGColor: "#000000",
  appTextColor: "#ffffff",
  black: "#131418",
  red: "#F1322C",
  pink: "#dea4ba",
  appBlueSecondary: "#7CD1DC",
  textColor: "#576D6D",
  fieldBackground: "#e3fbfc",
  defaultBgColor: "#76ebf4",
  alertTitleColor: "#576D6D",
  grayBackground: "#d2d6de90",

  newTextColor: "#57585B",
  newTextFieldBackgroundColor: "#E6E7E8",
  newDarkBlue: "#7CD0E0",
  newLightBlue: "#5BEDF6",
};

export default AppColor;
