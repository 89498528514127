import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import AppColor from "../utils/color";

const PlayerPosition = ({ positions }) => {
  const [openAchievement, setOpenAchievement] = useState(false);

  return (
    <Card
      style={{ marginTop: 0, boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)" }}
    >
      <CardHeader
        title={
          <Typography style={{ fontFamily: "Bold", fontSize: 20 }}>
            Position(s)
          </Typography>
        }
        action={
          <IconButton
            onClick={() => setOpenAchievement(!openAchievement)}
            aria-label="expand"
            size="small"
          >
            {openAchievement ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        }
      ></CardHeader>
      <div>
        <Collapse in={openAchievement} timeout="auto" unmountOnExit>
          <CardContent>
            {positions.length > 0 ? (
              positions.map((position, index) => {
                return (
                  <label
                    key={index}
                    style={{
                      padding: 5,
                      marginRight: 5,
                      backgroundColor: "#5BEDF630",
                      borderRadius: 20,
                      fontSize: 14,
                      color: AppColor.newTextColor,
                      fontFamily: "Medium",
                    }}
                  >
                    {position.position_name}
                  </label>
                );
              })
            ) : (
              <Typography>No Any Positions</Typography>
            )}
          </CardContent>
        </Collapse>
      </div>
    </Card>
  );
};

export default PlayerPosition;
