/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Page from "../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  Container,
  FormGroup,
  Grid,
  Paper,
} from "@material-ui/core";
import { AUTH_ROUTES } from "../../constants/site-routes";
import CustomPrimaryButton from "../../components/Common/CustomPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../components/Common/Notify";
import { addSport } from "../../stores/actions/Authorize";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import AppColor from "../../utils/color";
import { fetchRecord } from "../../services/Axios";
import { CATEGORIES } from "../../constants/ApiEndPoint";
import { useNavigate } from "react-router-dom";
import SizeList from "../../components/SizeList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      // width: '80%',
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      // width: '50%',
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px auto",
      // width: '50%',
    },
  },
  textField: {
    width: "100%",
    marginBottom: "30px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#e3fbfc",
    borderRadius: 4,
    border: "1px solid #7CD1DC",
  },
  textarea: {
    width: "97%!important",
    marginBottom: "30px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#e3fbfc",
    borderRadius: 4,
    border: "1px solid #7CD1DC",
    resize: "none",
    height: 80,
    paddingLeft: 15,
    paddingTop: 10,
  },
  radioButton: {
    marginBottom: "30px",
  },
  slider: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  error: {
    color: "#f44336",
  },
  title: {
    fontWeight: "bold",
    fontSize: "40px",
    fontFamily: "Noventa812, sans-serif",
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
  activeCard: {
    backgroundColor: "#57585B",
    color: "#ffffff",
  },
  inactiveCard: {
    backgroundColor: "#ffffff",
    color: "rgb(87, 88, 91)",
  },

  memberTitleGrid: {
    textAlign: "left",
    background: "transparent",
  },

  checkedIconGrid: {
    textAlign: "right",
    background: "transparent",
  },
}));

const RegisterSchoolSport = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authorize);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const navigate = useNavigate();
  const [size, setSize] = useState(1);

  const fetchCategories = async () => {
    setLoading(true);
    const response = await fetchRecord(CATEGORIES.LIST);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      const cats = [];
      response.data.map((item) => {
        if (item.is_comming_soon !== 1 && item.is_school !== 1) {
          cats.push(item);
        }
      });
      setCategories(cats);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [user]);

  async function handleSaveChanges() {
    setLoading(true);
    let response = await dispatch(
      addSport({
        categoryId: categoryId,
        size: size,
      })
    );
    setLoading(false);
    if (response.status === 200) {
      if (user.type === 1) {
        navigate(AUTH_ROUTES.REGISTER_AGENT);
      } else if (user.type === 3) {
        navigate(AUTH_ROUTES.REGISTER_COACH);
      } else if (user.type === 4) {
        navigate(AUTH_ROUTES.REGISTER_TEAM);
      } else if (user.type === 5) {
        navigate(AUTH_ROUTES.REGISTER_SCHOOL);
      }
    }
  }

  const handleChange = (catId) => {
    if (categoryId.indexOf(catId) === -1) {
        categoryId.push(catId);
        setCategoryId([...categoryId]);
    } else {
        categoryId.splice(categoryId.indexOf(catId), 1);
        setCategoryId([...categoryId]);
    }
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  return (
    <>
      <Page className={classes.root} title="Set Sort" backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Container className={classes.container}>
          <Notify />
          <Box className={classes.subTopBox}>
            <Typography className={classes.title}>SPORT</Typography>
          </Box>
          <Typography
            variant="h4"
            style={{ fontWeight: "bold", marginTop: 30, fontFamily: "Medium" }}
          >
            Choose Sport
          </Typography>
          <Typography
            style={{
              marginTop: 10,
              fontSize: 14,
              color: AppColor.textColor,
              fontFamily: "Medium",
            }}
          >
            Welcome, please select your preferred sport to continue the signup
            process
          </Typography>
          <Box className={classes.fromBlock}>
            <FormGroup row style={{ width: "98%" }}>
              {categories.map((category) => {
                return (
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 20,
                      borderRadius: 4,
                      marginTop: 20,
                      marginLeft: 5,
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid
                        value={1}
                        container
                        spacing={2}
                        style={{
                          background:
                            categoryId.indexOf(category.id) !== -1
                              ? "#2F2D2E"
                              : "transparent",
                          borderRadius: 4,
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleChange(category.id)}
                      >
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.memberTitleGrid}
                          >
                            <Typography
                              style={{
                                fontFamily: "Medium",
                                color:
                                categoryId.indexOf(category.id) !== -1
                                    ? "white"
                                    : AppColor.newTextColor,
                              }}
                            >
                              {category.name}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper
                            elevation={0}
                            className={classes.checkedIconGrid}
                          >
                            {categoryId.indexOf(category.id) !== -1 && (
                              <img
                                src="/icons/img_new_check_circle.png"
                                width={20}
                                alt=""
                              />
                            )}
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </FormGroup>

            <SizeList ssize={parseInt(size)} handleSizeChange={handleSizeChange}/>

            <CustomPrimaryButton text="Next" handleClick={handleSaveChanges} />
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default RegisterSchoolSport;
