import React, { useState } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, Container, TextField, InputAdornment, IconButton } from "@material-ui/core";
import { HOME_ROUTES } from "../../../constants/site-routes";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";
import { useDispatch } from "react-redux";
import Notify from "../../../components/Common/Notify";
import Navbar from "../../../layouts/Navigation";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { updatePassword } from "../../../stores/actions/Authorize";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      // width: '80%',
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      // width: '50%',
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px auto",
      // width: '50%',
    },
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "60px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "30px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#e3fbfc",
    borderRadius: 4,
    border: "1px solid #7CD1DC",
  },
  textarea: {
    width: "97%!important",
    marginBottom: "30px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#e3fbfc",
    borderRadius: 4,
    border: "1px solid #7CD1DC",
    resize: "none",
    height: 80,
    paddingLeft: 15,
    paddingTop: 10,
  },
  error: {
    color: "#f44336",
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [current_password, setCurrentPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  async function handleChangePassword() {
    setLoading(true);
    const params = {
      password: password,
      current_password: current_password,
      password_confirmation: password_confirmation,
    };

    const response = await dispatch(updatePassword(params));
    console.log(response);

    setLoading(false);
    if (response.status === 200) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setInterval(() => {
        window.location.href = HOME_ROUTES.PROFILE;
      }, 2000);
    }
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  }

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <>
      <Page
        className={classes.root}
        title="Change Password"
        backgroundColor="#ffffff"
      >
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container}>
          <Notify />
          <Typography
            variant="h4"
            style={{ fontWeight: "bold", marginTop: 10, marginBottom: 30 }}
          >
            Change Password
          </Typography>
          <Box className={classes.fromBlock}>
            <div
              style={{ width: "100%", textAlign: "center", marginBottom: 10 }}
            >
              <TextField
                variant="outlined"
                placeholder="Current Password"
                value={current_password}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className={classes.textField}
                type={showCurrentPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowCurrentPassword}
                      >
                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div
              style={{ width: "100%", textAlign: "center", marginBottom: 10 }}
            >
              <TextField
                variant="outlined"
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={classes.textField}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div
              style={{ width: "100%", textAlign: "center", marginBottom: 10 }}
            >
              <TextField
                variant="outlined"
                placeholder="Confirm Password"
                value={password_confirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                className={classes.textField}type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <CustomPrimaryButton
              text="Change Password"
              handleClick={handleChangePassword}
            />
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default ChangePassword;
