import React, { useState } from "react";
import Page from "../components/Page";
import { makeStyles } from "@material-ui/styles";
import Navbar from "../layouts/Navigation";
import {
  Container,
  Typography,
  Modal,
  Box,
  Button,
  Card,
} from "@material-ui/core";
import SimpleBackdrop from "../components/Common/SimpleBackdrop";
import { useDispatch } from "react-redux";
import AppColor from "../utils/color";
import { deleteAccount } from "../stores/actions/Authorize";
import Notify from "../components/Common/Notify";
import { HOME_ROUTES } from "../constants/site-routes";
import { clearAccessToken } from "../services/Storage";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 300,
  bgcolor: "white",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
  borderRadius: 8,
  textAlign: "center",
  padding: "16px",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  buttonStyles: {
    marginTop: "16px",
    width: "45%",
    margin: "5px",
    textTransform: "none",
    backgroundColor: "#e4f5f8",
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const handleNo = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    setLoading(true);
    const response = await dispatch(deleteAccount());
    setLoading(false);
    setOpen(false);
    if (response.status === 200) {
      clearAccessToken();
      setInterval(() => {
        window.location.href = HOME_ROUTES.INDEX;
      }, 2000);
    }
  };

  return (
    <>
      <Page className={classes.root} title="Settings" backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container}>
          <Typography
            variant="h4"
            style={{ fontWeight: "bold", fontFamily: "Noventa812, sans-serif" }}
          >
            Settings
          </Typography>

          <Notify />

          <Typography
            style={{ marginBottom: 20, fontFamily: "SemiBold", marginTop: 10 }}
          >
            Account Settings
          </Typography>

          <Card
            style={{
              padding: 10,
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
            elevation={0}
          >
            <Button onClick={() => setOpen(true)}>
              <Typography style={{ color: "red", textTransform: "none" }}>
                Delete Account
              </Typography>
            </Button>
          </Card>
        </Container>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={modalStyles}>
            <Typography
              id="modal-title"
              variant="h4"
              component="h2"
              style={{ fontSize: 15, fontWeight: "700" }}
            >
              Are you sure you want to delete your account?
            </Typography>
            <Button
              variant="contained"
              onClick={handleNo}
              className={classes.buttonStyles}
              disableElevation
              style={{ color: AppColor.textColor }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyles}
              disableElevation
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </Box>
        </Modal>
      </Page>
    </>
  );
};

export default Settings;
