import React, { useState, useRef } from "react";
import Page from "../../components/Page";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  IconButton,
  Container,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTES } from "../../constants/site-routes";
import CustomPrimaryButton from "../../components/Common/CustomPrimaryButton";
import CustomSecondaryButton from "../../components/Common/CustomSecondaryButton";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../components/Common/Notify";
import { addSchool } from "../../stores/actions/Authorize";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import AppColor from "../../utils/color";
import { LoadScript } from "@react-google-maps/api";
import Configs from "../../configs/Configs";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "50px",
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  topBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
    [theme.breakpoints.up("md")]: {
      height: "200px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "300px",
    },
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      // width: '80%',
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px auto",
      // width: '50%',
    },
    [theme.breakpoints.up("xl")]: {
      margin: "10px auto",
      // width: '50%',
    },
  },
  textField: {
    width: "100%",
    marginBottom: "30px",
    backgroundColor: "#E6E7E8",
    fontFamily: "Bai Jamjuree, sans-serif",
    borderRadius: 60,
  },
  textarea: {
    width: "97%!important",
    marginBottom: "30px",
    fontFamily: "Bai Jamjuree, sans-serif",
    backgroundColor: "#E6E7E8",
    borderRadius: 4,
    resize: "none",
    height: 80,
    paddingLeft: 15,
    paddingTop: 10,
    borderColor: "#E6E7E8",
  },
  radioButton: {
    marginBottom: "30px",
  },
  slider: {
    marginTop: "50px",
    marginBottom: "50px",
  },
  error: {
    color: "#f44336",
  },
  subTopBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: "90px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "40px",
    fontFamily: "Noventa812, sans-serif",
  },
}));

const RegisterSchoolProfile = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.authorize);
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [highlight_link, setHighlightLink] = useState("");
  const [about, setAbout] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState("");
  const libraries = ["places"];
  const googleMapsApiKey = Configs.GOOGLE_API_KEY;

  async function handleSaveChanges() {
    setLoading(true);
    const params = {
      name: name,
      highlight_link: highlight_link,
      location: location,
      latitude: latitude,
      longitude: longitude,
      about: about,
      image: image,
    };
    let response = await dispatch(addSchool(params));

    setLoading(false);
    if (response.status === 200) {
      window.location.href = HOME_ROUTES.PROFILE;
    }
  }

  const handelSkip = async () => {
    navigate(HOME_ROUTES.INDEX);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLocationSelect = async (selectedAddress) => {
    try {
      setLocation(selectedAddress);
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setLatitude(latLng.lat.toString());
      setLongitude(latLng.lng.toString());
    } catch (error) {
      setLocation(user.location);
      setLatitude(user.latitude);
      setLongitude(user.longitude);
    }
  };

  const handleOpen = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  const clearImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Page
        className={classes.root}
        title="Set School"
        backgroundColor="#ffffff"
      >
        {loading && <SimpleBackdrop />}
        <Container className={classes.container}>
          <Notify />
          <Box className={classes.subTopBox}>
            <Typography className={classes.title}>INFO</Typography>
          </Box>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              marginTop: 30,
              fontFamily: "Bai Jamjuree, sans-serif",
            }}
          >
            Welcome
          </Typography>
          <Typography
            style={{
              marginTop: 10,
              fontSize: 14,
              color: AppColor.textColor,
              fontFamily: "Bai Jamjuree, sans-serif",
            }}
          >
            Please enter your school name, about me, profile image and school
            video link to be added to your profile. Please make sure this information is accurate
          </Typography>
          <Box className={classes.fromBlock}>
            <div style={{ width: "100%", marginBottom: 30 }}>
              <TextField
                variant="outlined"
                placeholder="Enter your school's name"
                value={name}
                className={classes.textField}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                variant="outlined"
                placeholder="Add highlight link URL"
                value={highlight_link}
                className={classes.textField}
                onChange={(e) => setHighlightLink(e.target.value)}
              />

              <div style={{ marginBottom: "30px" }}>
                <LoadScript
                  googleMapsApiKey={googleMapsApiKey}
                  libraries={libraries}
                >
                  <PlacesAutocomplete
                    value={location}
                    onChange={setLocation}
                    onSelect={handleLocationSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter your location",
                          })}
                          className="MuiInputBase-input"
                          style={{
                            padding: "18.5px 0px 18.5px 0.5px",
                            paddingLeft: "15px",
                            borderColor: AppColor.appBlueSecondary,
                            width: "98%",
                            fontFamily: "Bai Jamjuree, sans-serif",
                            backgroundColor: "#E6E7E8",
                            borderRadius: 60,
                          }}
                        />
                        <div>
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion, index) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#41b6e6"
                                : "#fff",
                            };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                                style={{
                                  backgroundColor: "white",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  textAlign: "initial",
                                }}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </LoadScript>
              </div>

              <Box>
                <TextareaAutosize
                  minRows={5}
                  variant="outlined"
                  placeholder="About"
                  value={about}
                  className={classes.textarea}
                  onChange={(e) => setAbout(e.target.value)}
                />

                <Typography
                  style={{
                    marginTop: 10,
                    fontSize: 14,
                    color: AppColor.textColor,
                    fontFamily: "Bai Jamjuree, sans-serif",
                  }}
                >
                  Upload Profile Photo
                </Typography>

                {selectedImage ? (
                  <div
                    style={{ position: "relative", width: 80, marginTop: 10 }}
                  >
                    <img
                      src={selectedImage}
                      alt="Selected"
                      width="80px"
                      height="80px"
                      style={{ borderRadius: "20px" }}
                    />
                    <IconButton
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: "2px",
                        backgroundColor: "gray",
                        borderRadius: "0 20px 0 0px",
                      }}
                      onClick={clearImage}
                    >
                      <Close />
                    </IconButton>
                  </div>
                ) : (
                  <div
                    style={{ position: "relative", width: 80, marginTop: 10 }}
                  >
                    <IconButton
                      color="primary"
                      onClick={handleOpen}
                      style={{
                        color: "#76ebf4",
                        padding: "0px",
                        borderRadius: "10px",
                        border: "#76ebf4 solid 1px",
                        height: 80,
                        width: 80,
                        backgroundColor: "#e3fbfc",
                      }}
                    >
                      <img
                        src="/images/profile.png"
                        alt="Selected"
                        style={{ height: 30, width: 30 }}
                      />
                    </IconButton>
                  </div>
                )}

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none", paddingLeft: "10px" }}
                  id="imageInput"
                  ref={imageRef}
                />
              </Box>
            </div>

            <CustomPrimaryButton
              text="Save & Continue"
              handleClick={handleSaveChanges}
            />

            <div style={{ marginTop: 30 }}>
              <CustomSecondaryButton text="Skip" handleClick={handelSkip} />
            </div>
          </Box>
        </Container>
      </Page>
    </>
  );
};

export default RegisterSchoolProfile;
