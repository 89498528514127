/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import Navbar from "../../../layouts/Navigation";
import { Container, Box, Grid, Typography, Avatar } from "@material-ui/core";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { fetchRecord } from "../../../services/Axios";
import { useNavigate, useParams } from "react-router-dom";
import { PROFILE } from "../../../constants/ApiEndPoint";
import { makeStyles } from "@material-ui/styles";
import Achievements from "../../../components/Achievements";
import LookingFor from "../../../components/LookinFor";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";
import { HOME_ROUTES } from "../../../constants/site-routes";
import FavouriteItem from "../../../components/FavouriteItem";
import AppColor from "../../../utils/color";
import VerifiedPlayer from "../../../components/VerifiedPlayer";

const useStyles = makeStyles((theme) => ({
  detailBlock: {
    padding: "1rem",
    margin: "10px 10px 10px 0px",
    backgroundColor: "white",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: 4,
  },

  memberTitle: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  memberCurrentClub: {
    fontSize: "14px",
  },
  container: {
    marginBottom: 10,
    width: "50%",
  },

  avatar: {
    width: "100%",
    height: 300,
    objectFit: "cover",
    borderRadius: 10,
  },

  floatRight: {
    float: "right",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 22,
  },
}));

const CoachDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState(null);
  const navigate = useNavigate();

  const fetchPlayers = async () => {
    setLoading(true);
    const response = await fetchRecord(PROFILE.DETAIL, id);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setPlayer(response.data);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  const handleContactCoach = () => {
    navigate(HOME_ROUTES.MESSAGES + "/" + player.id);
  };

  const calculateAge = (dob) => {
    const [day, month, year] = dob.split('/').map(Number);
    const dobDate = new Date(year, month - 1, day);
    const today = new Date();
    let calculatedAge = today.getFullYear() - dobDate.getFullYear();

    console.log(calculatedAge);
    const monthDiff = today.getMonth() - dobDate.getMonth();
    const dayDiff = today.getDate() - dobDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      calculatedAge--;
    }
    return calculatedAge;
  };

  const lookingForImage = (contract) => {
    if (contract === "1") return "/icons/amature.png";
    if (contract === "2") return "/icons/overseas.png";
    if (contract === "3") return "/icons/professional.png";
    return "";
  };

  ///css
  const subTitleCSS = {
    textAlign: "start",
    fontFamily: "Medium",
    fontSize: 18,
  };

  const titleCSS = {
    fontFamily: "Medium",
    fontSize: 14,
    color: AppColor.newTextColor,
  };

  const titleCSSWithMargin = {
    fontFamily: "Medium",
    fontSize: 14,
    color: AppColor.newTextColor,
    marginTop: 8,
  };

  return (
    <>
      <Page title={player?.full_name} backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container}>
          <div>
            {player && (
              <div>
                <Box>
                  <Avatar
                    src={player.avatar_url ?? "/images/logo.png"}
                    className={classes.avatar}
                  />
                </Box>
                <Box style={{ marginTop: 15, marginBottom: 15 }}>
                  <Typography className={classes.memberTitle}>
                    {player.full_name || ""} { (player?.is_subscribe || player?.is_super_pro) && (<VerifiedPlayer player={player} />) }
                    <div className={classes.floatRight}>
                      <FavouriteItem player={player} />
                      <img src="/icons/coach.png" width={20} alt=""/>

                      {player.coach?.contracts &&
                        player.coach?.contracts.length > 0 && (
                          <img
                            src={`${lookingForImage(
                              player.coach?.contracts[0].contract
                            )}`}
                            width={20}
                            style={{ marginTop: 7 }}
                            alt=""
                          />
                        )}
                    </div>
                  </Typography>
                  <Typography className={classes.memberCurrentClub}>
                    {player.location}
                  </Typography>
                </Box>

                <Grid container className={classes.detailBlock}>
                  <Grid item xs={12}>
                    <Typography style={titleCSS}>Age</Typography>
                    <Typography style={subTitleCSS}>
                      {calculateAge(player.date_of_birth)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={titleCSSWithMargin}>
                      Previous Team
                    </Typography>
                    <Typography style={subTitleCSS}>
                      {player.coach.previous_team}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={titleCSSWithMargin}>
                      Current Team
                    </Typography>
                    <Typography style={subTitleCSS}>
                      {player.coach.current_team}
                    </Typography>
                  </Grid>
                </Grid>

                <Achievements achievements={player.coach.achievement} />

                <br />

                <LookingFor contracts={player.coach.contracts} />

                <Box style={{ marginTop: 20 }}>
                  <CustomPrimaryButton
                    text="Contact Coach"
                    handleClick={handleContactCoach}
                  />
                </Box>
              </div>
            )}
          </div>
        </Container>
      </Page>
    </>
  );
};

export default CoachDetail;
