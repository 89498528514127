/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { makeStyles } from "@material-ui/styles";
import Navbar from "../../../layouts/Navigation";
import { Container, Grid, List, Typography, Paper } from "@material-ui/core";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import { fetchRecord, postRecord } from "../../../services/Axios";
import { NOTIFICATION } from "../../../constants/ApiEndPoint";
import NotificationItem from "../../../components/NotificationItem";
import { useDispatch, useSelector } from "react-redux";
import { receiveCurrentUser } from "../../../stores/actions/Authorize";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  notificationSection: {
    marginTop: "20px",
    padding: "10px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
}));

const Notifications = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const user = useSelector((state) => state.authorize);
  const dispatch = useDispatch();

  const fetchNotifications = async (query) => {
    setLoading(true);
    const response = await fetchRecord(
      NOTIFICATION.INDEX,
      "",
      "",
      "page=" + query.page
    );
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setLastPage(response.paginator?.last_page);
      if (notifications && query.page > 1) {
        setNotifications([...notifications, ...response.data]);
      } else {
        setNotifications(response.data);
        let updatedUser = { ...user };
        updatedUser.notifications_count = 0;
        dispatch(receiveCurrentUser(updatedUser));
      }
    }
  };

  useEffect(() => {
    fetchNotifications({ page: page });
    markAllRead();
  }, [page]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 1 &&
      !loading &&
      page <= lastPage
    ) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  const markAllRead = () => {
    postRecord(NOTIFICATION.MARK_ALL_READ);
  };

  return (
    <>
      <Page
        className={classes.root}
        title="Notifications"
        backgroundColor="#ffffff"
        elevation={0}
      >
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container
          className={classes.container}
          style={{ marginTop: 10 }}
          elevation={0}
        >
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              fontFamily: "Noventa812, sans-serif",
            }}
          >
            Notifications
          </Typography>
          <Grid
            container
            component={Paper}
            className={classes.notificationSection}
            elevation={0}
          >
            <Grid item xs={12}>
              <List className={classes.messageArea} elevation={0}>
                {notifications &&
                  notifications.map((notification, index) => (
                    <div key={index}>
                      <NotificationItem
                        notification={notification}
                        key={index}
                      />
                    </div>
                  ))}
              </List>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default Notifications;
