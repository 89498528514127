/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Page from '../../../components/Page';
import { makeStyles } from '@material-ui/styles';
import Navbar from '../../../layouts/Navigation';
import { Container, Grid, Typography, ButtonBase } from '@material-ui/core';
import SimpleBackdrop from '../../../components/Common/SimpleBackdrop';
import { fetchRecord } from '../../../services/Axios';
import { useNavigate, useParams } from 'react-router-dom';
import { PLAYERS } from '../../../constants/ApiEndPoint';
import { HOME_ROUTES } from '../../../constants/site-routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  block: {
    padding: '1rem',
    margin: '10px 10px 10px 0px',
    backgroundColor: '#ffffff',
    border: '1px solid #d2d6de',
    boxShadow: '0 1px 1px rgba(0,0,0,0.1)',
  },
  img: {
    width: '100px',
    height: '100px',
  },
  blockTextContainer: {
    margin: '0rem 1rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
    },
  },
  heading: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  memberTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  memberLocation: {
    fontSize: '14px',
  },
  memberLabel: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: '#6e6e6e75',
    color: '#ffffff',
    padding: 5,
  },
}));

const PlayerList = () => {
  const navigate = useNavigate();
  const { category } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState(null);

  const fetchPlayers = async () => {
    setLoading(true);
    const response = await fetchRecord(
      PLAYERS.LIST,
      '',
      '',
      'sports=' + category + '&per_page=20'
    );
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setPlayers(response.data);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  const lookingForName = (contract) => {
    if (contract === '1') return 'Amateur';
    if (contract === '2') return 'Overseas';
    if (contract === '3') return 'Professional Contracts';
    return '';
  };

  const handelClick = (id) => {
    navigate(HOME_ROUTES.MEMBER + '/' + id);
  };

  return (
    <>
      <Page className={classes.root} title={category} backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            {category}
          </Typography>
          <Typography gutterBottom variant="h6">
            Members List
          </Typography>
          <Grid container spacing={0}>
            {players &&
              players.map((player, index) => {
                return (
                  <Grid key={index} container item xs={6}>
                    <Grid container className={classes.block}>
                      <Grid item>
                        <ButtonBase className={classes.image}>
                          <img
                            className={classes.img}
                            alt="complex"
                            src={player.media[0].media_url}
                            onClick={() => handelClick(player.id)}
                          />
                          <label className={classes.memberLabel}>
                            {player.user?.type === 1 && 'Agent'}
                            {player.user?.type === 2 && 'Player'}
                            {player.user?.type === 3 && 'Coach'}
                            {player.user?.type === 4 && 'Team'}
                            {!player.user?.type && 'No Type'}
                          </label>
                        </ButtonBase>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm
                        container
                        className={classes.blockTextContainer}
                      >
                        <Grid item xs container direction="column">
                          <Grid item xs>
                            <Typography className={classes.memberTitle}>
                              {player.name}
                            </Typography>
                            <Typography className={classes.memberLocation}>
                              {player.location}
                            </Typography>
                            <div style={{ marginTop: 25 }}>
                              {player.user?.type === 2 &&
                                player.positions &&
                                player.positions.map((position, index) => {
                                  return (
                                    index < 2 && (
                                      <label
                                        key={index}
                                        style={{
                                          padding: 5,
                                          marginRight: 5,
                                          backgroundColor: '#d2d6de',
                                        }}
                                      >
                                        {position.position_name}
                                      </label>
                                    )
                                  );
                                })}
                              {player.user?.type === 3 &&
                                player.coach?.contracts &&
                                player.coach?.contracts.map(
                                  (contract, index) => {
                                    return (
                                      index < 2 && (
                                        <label
                                          key={index}
                                          style={{
                                            padding: 5,
                                            marginRight: 5,
                                            backgroundColor: '#d2d6de',
                                          }}
                                        >
                                          {lookingForName(contract.contract)}
                                        </label>
                                      )
                                    );
                                  }
                                )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default PlayerList;
