/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Page from '../../../components/Page';
import Navbar from '../../../layouts/Navigation';
import { Container, Typography } from '@material-ui/core';
import SimpleBackdrop from '../../../components/Common/SimpleBackdrop';
import { fetchRecord } from '../../../services/Axios';
import { useParams } from 'react-router-dom';
import { PLAYERS } from '../../../constants/ApiEndPoint';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    marginBottom: 10,
    width: '50%',
  },
}));

const PlayerHighlights = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState(null);

  const fetchPlayers = async () => {
    setLoading(true);
    const response = await fetchRecord(PLAYERS.LIST, id);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setPlayer(response.data);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  return (
    <>
      <Page title={player?.name} backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container}>
          <div>
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              Player's Highlights
            </Typography>
          </div>

          {player?.media.map(
            (media, index) =>
              !media.is_photo && (
                <div key={index} style={{ marginTop: 30 }}>
                  <ReactPlayer
                    url={media.media_url}
                    width={'100%'}
                    controls={true}
                  />
                </div>
              )
          )}
        </Container>
      </Page>
    </>
  );
};

export default PlayerHighlights;
