import { Container, TextareaAutosize, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CustomPrimaryButton from "../../components/Common/CustomPrimaryButton";
import Page from "../../components/Page";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../../layouts/Navigation";
import { useDispatch } from "react-redux";
import { storeEnquiry } from "../../stores/actions/Authorize";
import Notify from "../../components/Common/Notify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  chatSection: {
    marginTop: 10,
  },
  textarea: {
    width: "97%!important",
    marginBottom: "30px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#e3fbfc",
    borderRadius: 4,
    border: "1px solid #7CD1DC",
    resize: "none",
    height: 80,
    paddingLeft: 15,
    paddingTop: 10,
  },
}));


const Enquiry = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [sport, setSport] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading(true);
    await dispatch(
      storeEnquiry({
        sport: sport,
        message: message,
      })
    );
    setLoading(false);
    setMessage("");
    setSport("");
  };
  
  return (
    <Page className={classes.root} title="Enquiry" backgroundColor="#ffffff">
      {loading && <SimpleBackdrop />}
      <Navbar />
      <Container className={classes.container}>
        <Typography
          id="modal-title"
          variant="h4"
          component="h2"
          style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
        >
          Enquiry
        </Typography>

        <Notify />

        <TextareaAutosize
          minRows={2}
          variant="outlined"
          placeholder="Sport(s) name"
          value={sport}
          className={classes.textarea}
          onChange={(e) => setSport(e.target.value)}
        />

        <TextareaAutosize
          minRows={5}
          variant="outlined"
          placeholder="Message"
          value={message}
          className={classes.textarea}
          onChange={(e) => setMessage(e.target.value)}
          style={{ marginBottom: 80 }}
        />

        <CustomPrimaryButton text="Submit" handleClick={handleSubmit} />
      </Container>
    </Page>
  );
};
export default Enquiry;
