import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import RenderRoutes from "./services/RenderRoutes";
import { messaging, onMessageListener } from "./services/Firebase";
import { getToken } from "firebase/messaging";
import Configs from "./configs/Configs";
import { setFirebaseToken } from "./services/Storage";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  if ("Notification" in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getToken(messaging, { vapidKey: Configs.FIREBASE_VAPID_KEY })
          .then((currentToken) => {
            if (currentToken) {
              setFirebaseToken(currentToken);
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      }
    });

    if ("serviceWorker" in navigator && messaging !== null) {
      onMessageListener().then((payload) => {
        console.log(payload);
      });
    }
  }

  return (
    <GoogleOAuthProvider clientId={Configs.GOOGLE_SSO_API_KEY}>
      <ThemeProvider theme={theme}>{RenderRoutes()}</ThemeProvider>
    </GoogleOAuthProvider>
  );
};
export default App;
